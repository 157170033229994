import React, {useEffect, useState} from "react";
import {loadAllGroup, loadSurveyType} from "../../API/parameterApi";
import Select from "react-select";
import ParameterTab from "./Parameter";
import ModalNewGroup from "../Modal/ModalNewGroup";
import {deleteGroup, saveGroup, updateGroup} from "../../API/groupApi";
import DeleteIco from "../Button/DeleteIco";
import EditIco from "../Button/EditIco";
import PlusDoc from "../Button/PlusDoc";
import AlertMessage from "../AlertMessage";
import {useSelector} from "react-redux";
import {infoUserAccessTN} from "../../API/accessAPI";


// работа с формой на добавление/редактирование названия группы для показателей
export default function SelectParameter () {

    const [spisGroup, setSpisGroup] = useState(null);
    const [userGroup, setUserGroup] = useState(null);
    const [selectGroup, setSelectGroup] = useState('');
    const [show, setShow] = useState(false);
    const [chapter, setChapter] = useState(null);
    const {user} = useSelector(state => state.auth);
    const [userAccess, setUserAccess] = useState([]);

    let dis = true;

    useEffect(() => {
        if(user) {
            infoUserAccessTN({user: '/api/users/'+user.tnn})
                .then(setUserAccess);
        }
        if (!spisGroup) {
            loadAllGroup().then(setSpisGroup);
        }
    }, [user]);

    useEffect(() => {
      if(spisGroup !== null){
         const group = spisGroup.filter((access)=>{
              for(const user of userAccess){
                  if(user.subject === access['@id']){
                      return true;
                  }
              }
          })
          setUserGroup(group);
      }
      else{
              return;
      }
    }, [spisGroup]);

    useEffect(() => {
        if(selectGroup){
            loadSurveyType({urlType: 'api/survey_types/'+ selectGroup.value})
                .then(setChapter);
        }
       else {
           return;
        }
    }, [selectGroup]);

    const spisGroupAll = userGroup?.map((g) => {
        return { value: g.id, label: g.title}
    });

    let content = null;

    if(selectGroup !== ""){
        dis  = false;
        content = (
            <ParameterTab
                idGroup = {selectGroup}
                chapter = {chapter}
            />
        );
    }
    let ModalForm = null;
    const [idGroup, setIdGroup] = useState(0);
    const [titleGroup, setTitleGroup] = useState('');
    const addNewGroup = () => {
        setShow(true);
        setIdGroup(0);
        setTitleGroup('Добавить новую группу');
    }

    const handleGloseGroup = () => {
        setShow(false)
    }
    const handleSaveGroup = (group) => {
        if(group.id !== 0){
            updateGroup({group})
                .then(r => {
                  setSpisGroup([...spisGroup.filter(item => item.id !== r.id), r])
                }
             );
            setShow(false);
        }

        else {
            saveGroup({group}).then(r => {
                setSpisGroup(prevState => [...prevState, r]);
                setShow(false);
          })
        }
    }
    const handleUpdateGroup = (group) => {
        setShow(true);
        setIdGroup(group.value);
        setTitleGroup('Редактировать выбранную группу');
    };
    const [showAlert,setShowAlert] = useState(false);
    const handleDeleteGroup = (groupDel) => {
        setShowAlert(true);
        setIdGroup(groupDel.value);
    };
    const closeMessage = () => {
       setShowAlert(false);
    }
    const confirmMessage = (data) => {
        setShowAlert(false);
        deleteGroup({idGroup: data}).then(() => {
            setSpisGroup([...spisGroup].filter(r => r.id !== data))
        })
        setSelectGroup('');
    }
    let alertDelete = null;
    if(showAlert){
        alertDelete = (
           <AlertMessage
           isOpen = {showAlert}
           id = {idGroup}
           titleHead = {'Предупреждение'}
           titleBody = {'Вы действительно хотите удалить выбранную группу показателей?'}
           closeAlert = {closeMessage}
           confirmAlert = {confirmMessage}
           />
        );
    }
    ModalForm = (<ModalNewGroup
        isOpen = {show}
        id = {idGroup}
        title={titleGroup}
        onCloseGroup = {handleGloseGroup}
        onSaveGroup = {handleSaveGroup}
    />);
    return (
        <div>
            {alertDelete}
            <div style={{'textAlign': 'right'}}>
                {ModalForm}
                <button className="btn" onClick={addNewGroup} title={' Добавить группу'}><PlusDoc/></button>
                <button className="btn" onClick={() => handleUpdateGroup(selectGroup)} title={'Изменить группу'} disabled={dis}>
                    <EditIco/>
                </button>
                <button className="btn" onClick={() => handleDeleteGroup(selectGroup)} title={'Удалить группу'} disabled={dis} >
                    <DeleteIco/>
                </button>
            </div>
             <div className="m-1">
                 <Select value={selectGroup} style={{width:200}} options={spisGroupAll} placeholder={'Название группы'}
                         onChange={setSelectGroup}
                 />
             </div>
            <div></div>
            {content}
        </div>
    )
}