import {createSlice} from "@reduxjs/toolkit";

export const parametersSliceCommission = createSlice({
	name: 'parametersCommission',

	initialState: {
		isLoad: false,
		list: [],
	},

	reducers: {
		putParametersCommission: (state, action) => {
			state.isLoad = true;
			state.list = action.payload;
		},

	}
})
export const {putParametersCommission} = parametersSliceCommission.actions
export default parametersSliceCommission.reducer