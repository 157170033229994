import React, {useState} from "react";
import {Form} from "react-bootstrap";

export default function SelectNew({selectChanger, list}) {

	const listContentItems = list
		?.map(l => {
			if (l?.full!==undefined) {
				return <option
					style={l?.full === true ? {color: "red"} : {color: "green"}}
					key={l.id}
					value={l.id}
					className="list-group-item list-group-item-action"> {l.name}
				</option>
			}
			else {
				return <option
					key={l.id}
					value={l.id}
					className="list-group-item list-group-item-action"> {l.name}
				</option>
			}})
// 		<option {...(l?.full=== true && {style: {color: "red"}})} key={l.id} value={l.id}
// 				className="list-group-item list-group-item-action">{l.name}</option>
// )})

const onSelect = id => {
	selectChanger(
		list
			?.filter(item => item.id === Number(id))
			.pop()
	);
}

return (
	<Form.Select onChange={e => onSelect(e.target.value)}>
		<option>Выберите...</option>
		{listContentItems}
	</Form.Select>
)
}