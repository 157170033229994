import TokenStorage from "./TokenStorage";

const isExpired = (token) => {
  if (!token) return true;
  const payload = extractToken(token);
  return payload.exp < Date.now();
}

const extractToken = (token) => {
  const [_, payload] = token.split(".");

  const objStr = atob(payload);
  const obj = JSON.parse(objStr);

  obj.iat = new Date(obj.iat * 1000);
  obj.exp = new Date(obj.exp * 1000);

  return obj;
}

// todo redirect to saml
async function update(refresh) {
  if (isExpired(refresh)) {
    throw {"message": "refresh expired"}
  }

  const response = await fetch("/auth/refresh", {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({token: refresh})
  });

  return await response.json();
}

async function loadUser() {
  const headers = await security.getAuthHeaders();
  const apiData = await fetch("/api/users/me", {
    method: "GET",
    headers: Object.assign(headers, {"Content-Type": "application/json"})
  });
  const user = await (apiData).json();
  if (user) {
    user.isAdmin = user.roles.findIndex(access => access === 'ROLE_ADMIN') !== -1;
  }
  return user;
}


class ApiSecurity {
  constructor() {
    this._access = new TokenStorage('access');
  }

  _update = async () => {
    const pair = await update();
    this._access.set(pair.access);
  }

  _updateAndReturnActual = async () => {
    await this.signin();
    await this.clearCache();
    return this._access.get();
  }

  _getActualToken = async () => {
    const accessCandidate = this._access.get();
    // console.log(!!accessCandidate, isExpired(accessCandidate));
    if (isExpired(accessCandidate)) {
      return await this._updateAndReturnActual();
    }
    return accessCandidate;
  }

  signin = async () => {
    const apiData = await fetch("/api/saml/jwt", {redirect: "error"});
    const response = await (apiData).json();
    const {access} = response;
    this._access.set(access);
    return await this.getActualUser();
  }

  ifExists = () => {
    try {
      const access = this._access.get();
      console.log(!!access)
      return !access && !isExpired(access);
    } catch (e) {
      return false;
    }
  }

  getActualUser = async () => {
    return await loadUser();
  }

  getAuthHeaders = async () => {
    try {
      const actualAccess = await this._getActualToken();
      return {"Authorization": `Bearer ${actualAccess}`}
    } catch (e) {
      console.dir(e?.message)
      await this.logout();
      window.open("/api/saml/login", "_self");
      // window.location.href("");
    }
  }

  logout = async () => {
    this._access.clear();
  }

  clearCache = async () => {
    try {
      const refreshCacheAndReload = () => {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) {
              caches.delete(name);
            }
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      };
      const data = fetch("/meta.json", {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      });
      const meta = await (await (data)).json();
      const latestVersionDate = meta.buildDate.toString();
      const currentVersionDate = localStorage?.buildDate;
      const shouldForceRefresh = latestVersionDate !== currentVersionDate
      // console.log(`shouldForceRefresh: ${shouldForceRefresh}, latestVersionDate: ${typeof latestVersionDate}, currentVersionDate: ${typeof currentVersionDate},`);
      if (shouldForceRefresh) {
        localStorage.setItem('buildDate', meta.buildDate);
        refreshCacheAndReload();
      }
    } catch (error) {
      console.error('☠️error clearCache actions System: ', error.response ? error.response : error);
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

const security = new ApiSecurity();

export default security;