import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider} from "react-router-dom";

import {useSelector, useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {setUser} from "./Store/AuthSlice";
import ApiSecurity from "./API/ApiSecurity";
import Loader from "./Components/Common/Loader";
import {Stack} from "react-bootstrap";
import MainPage from "./Pages/MainPage";
import InterviewPage from "./Pages/InterviewPage";
import AdminPage from "./Pages/AdminPage";
import Layout from "./Components/Layout";
import ViewDepartment from "./Pages/ViewDepartment";

const ProtectedRoute = ({isAllowed, redirectPath = '/', children}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace/>;
  }
  return children ? children : <Outlet/>;
}

function App() {
  const {user} = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    ApiSecurity.getActualUser()
      .then(data => {
        dispatch(setUser(data));
      })
      .catch(() => {
        ApiSecurity.logout();
      })
  }, []);

  const router = createBrowserRouter(createRoutesFromElements(
      <Route path="/" element={<Layout/>}>
        <Route path="/" element={
          !!user && user?.roles.includes('ROLE_ADMIN') ? <AdminPage/> :
          !!user && user?.roles.includes('ROLE_EXPERT') ?  <InterviewPage/> : <MainPage/>
        }/>
        <Route path="/interview" element={
          <ProtectedRoute isAllowed={!!user && (user?.roles.includes('ROLE_EXPERT') || user?.roles.includes('ROLE_ADMIN'))}>
            <InterviewPage/>
          </ProtectedRoute>}
        />
        <Route path="/admin" element={
          <ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_ADMIN')}>
            <AdminPage/>
          </ProtectedRoute>}
        />
          <Route path="/view" element={
              <ProtectedRoute redirectPath="/home" isAllowed={!!user && (user?.roles.includes('ROLE_DEPARTMENT') || user?.roles.includes('ROLE_DEAN'))}>
                  <ViewDepartment/>
              </ProtectedRoute>}
          />
        <Route path="/commission" element={
          <ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_COMMISSION')}>
            <InterviewPage/>
          </ProtectedRoute>}
        />
        <Route path="/*" element={<Navigate to="/" replace/>}/>
      </Route>
    )
  );

  return (
    <>
      {!user?.roles && <Stack gap={3} style={{
        height: '50vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Loader className="p-2"/>
      </Stack>}
      {user?.roles && <RouterProvider router={router}/>}
    </>

  );
}

export default App;
