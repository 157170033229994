import {createSlice} from "@reduxjs/toolkit";

export const descriptionSlice = createSlice({
	name: 'description',

	initialState: {
		list: [],

	},

	reducers: {
		putDescriptions: (state, action) => {
			state.list = action.payload;

		},
	}
})
export const {putDescriptions} = descriptionSlice.actions
export default descriptionSlice.reducer