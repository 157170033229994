import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import Button from "react-bootstrap/Button";
import {loadAllDepartment, loadAllFaculty, loadUnitForAccess} from "../../API/listUnitAPI";
import {infoUserAccessID, loadAllPosition} from "../../API/accessAPI";
import {get} from "../../API/api";
import SearchUser from "../ProvidingAccess/SearchUser";
import {ListChapter, loadAllGroup} from "../../API/parameterApi";

const loadUnit = async unitIrls => {
    const unit = await get({irl: unitIrls});
    return unit;
}
const loadUser = async userIrls => {
    const user = await get({irl: userIrls});
    return user;
}
const loadPosition = async positionIrls => {
    const position = await get({irl: positionIrls});
    return position;
}
export default function ModalAddUserAccess({isOpen, id, title, onAccessUserSave, onAccessUserClose}) {

    const [unit, setUnit] = useState(null);
    const [typeSurveys, setTypeSurveys] = useState(null);
    const [arrSelected, setArrSelected] = useState([]);
    const [position, setPosition] = useState(null)
    const [selectRole, setSelectRole] = useState(null);
    const [selectUser, setSelectUser] = useState('');
    const [fioUser,setFioUser] = useState('');
    const [chapterSurveys, setChapterSurveys] = useState(null);
    const [ofdisable,setOfDisable] = useState(false);
    const [department, setDepartment] = useState(null);
    const [faculty, setFaculty] = useState(null);
    useEffect(()=>{
        if(userUnit){
            setArrSelected([userUnit]);
        }
    },[selectUser]);

    useEffect(()=>{
        if(selectRole){
            if(selectRole.value === "ADMIN"){
                loadAllGroup()
                    .then(setTypeSurveys);
            }
            else if (selectRole.value === "EXPERT"){
                loadUnitForAccess()
                    .then(setUnit);
                setOfDisable (false);
            }
            else if(selectRole.value === "COMMISSION"){
                ListChapter().then(setChapterSurveys);
            }
            else if(selectRole.value === "DEPARTMENT"){
                loadAllDepartment().then(setDepartment);
            }
            else if(selectRole.value === "DEAN"){
                loadAllFaculty().then(setFaculty);
            }
        }
        else{
            return;
        }

    },[selectRole])

    useEffect(() => {
        if (isOpen === false) {
            return;
        }
        if (unit === null) {
            loadUnitForAccess()
                .then(setUnit);
        }
    }, [isOpen]);

    let listUnit = [];
    const unitSurvey = unit?.map((unitAll, keys) => {
        return {
            id: unitAll.tnn,
            name: unitAll.title
        }
    });
    const typeSurvey = typeSurveys?.map((typeAll)=>{
        return {
            id: typeAll.id,
            name: typeAll.title
        }
    })
    const chapterSurvey = chapterSurveys?.map((chapter)=>{
        return {
            id:chapter.id,
            name: chapter.title
        }
    })
    const departmentSurvey = department?.map((dep)=>{
        return {
            id:dep.tnn,
            name: dep.title
        }
    })

    const facultySurvey = faculty?.map((dep)=>{
        return {
            id:dep.tnn,
            name: dep.title
        }
    })

    if(selectRole !== null){
        if(selectRole.value === 'EXPERT'){
            listUnit = unitSurvey;
        }
        else if(selectRole.value === 'ADMIN'){
            listUnit = typeSurvey;
        }
        else if(selectRole.value === 'COMMISSION'){
            listUnit = chapterSurvey;
        }
        else if(selectRole.value === 'DEPARTMENT'){
                listUnit = departmentSurvey;
            }
        else if(selectRole.value === 'DEAN'){
                listUnit = facultySurvey;
            }
    }

    const listRole = [
        {value: "ADMIN", label: "Администратор"},
        {value: "EXPERT", label:"Эксперт"},
        {value: "COMMISSION", label: "Комиссия"},
        {value: "DEPARTMENT", label: "Заведующий кафедры"},
        {value: "DEAN", label: "Декан"}
   ];

    const listAccesses = [
        {value:'write', label: 'Заполнение'},
        {value:'read', label:'Чтение'}
    ]

    const accessUserSave = () => {

        const dataAccess = arrSelected?.map((k) => {
            let textSubject = '';
            if(selectRole.value === "EXPERT" || selectRole.value === "DEPARTMENT" || selectRole.value === "DEAN" ){
                textSubject = '/api/units/';
            }
            else if(selectRole.value ==='ADMIN'){
                textSubject = '/api/survey_types/';
            }
            else if(selectRole.value === "COMMISSION"){
                textSubject = '/api/chapters/';
            }
             return {
                 user: '/api/users/'+ selectUser,
                 subject: textSubject + k.id,
                 role: selectRole.value,
                 accessRight: position.value
             }
        })
        onAccessUserSave(dataAccess);

        setUnit(null);
        setArrSelected([]);
        setUserUnit(null);

    }

    const accessUserClose = () => {
        setSelectUser('');
        setUnit(null);
        setArrSelected([]);
        setUserUnit(null);
        setSelectRole(null)
        onAccessUserClose();
    }

    const onSelectUnit = (selectedList, selectedItem) => {
        setArrSelected([...arrSelected, selectedItem]);
    }
    const onRemoveUnit = (selectedList, removedItem) => {
        setArrSelected(arrSelected.filter(v => v.id !== removedItem.id));
    }
    const [userUnit,setUserUnit] = useState(null);
    const onSelectUser = (value, unit)=> {setSelectUser(value); setUserUnit({id:unit.id, name:unit.name}); };


    return (
        <Modal size={"xl"} style={{height: 600}} aria-labelledby="contained-modal-title-vcenter"
               centered show={isOpen}>
            <Modal.Header><HeadModal title={title}/></Modal.Header>
            <Modal.Body>
                <div><i>ФИО сотрудника:</i></div>
                <div><SearchUser fio={selectUser} onSave={onSelectUser} /></div>
                <div className="mt-2"><i>Роль</i></div>
                <div className="mt-2"> <Select options={listRole} value={selectRole} placeholder="Роль доступа"
                              onChange={setSelectRole}/></div>
                <div className="mt-2"><i>Структурное подразделение/ Группа показателей</i></div>
                <div><Multiselect disable={ofdisable} options={listUnit} displayValue="name" placeholder="Наименование подразделения"
                                  onSelect={onSelectUnit} onRemove={onRemoveUnit}
                                  selectedValues={arrSelected} hidePlaceholder={true}
                                   /></div>

                <div className="mt-2"><i>Права доступа</i></div>
                <div className="mt-2">
                    <Select onChange={setPosition} options={listAccesses} placeholder={'Выберите права доступа'}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={accessUserClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={accessUserSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}