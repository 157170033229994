import React, {useEffect, useState} from "react";
import {loadAllDepartment} from "../../API/listUnitAPI";
import FacultySpisCheck from "./FacultySpisCheck";

export default function DepartmentList({id, onSaveListUnit}) {
    const [department, setDepartment] = useState(null);
    const [listCheck,setListCheck] = useState([]);

    useEffect(() => {
        if (department) {
            return;
        }
        loadAllDepartment()
            .then(setDepartment);

    }, [department]);
    const changeListCheck = (value, id) => {
        if (value) {
            setListCheck([...listCheck, id]);

        } else {
            setListCheck(listCheck.filter(v => v !== id));
        }
    }

    useEffect(() => {
        onSaveListUnit(listCheck);
    }, [listCheck]);

    const departmentAllList = department?.map((f,key) => {

        return <FacultySpisCheck key={f.tnn}
                                 isChecked={listCheck.indexOf(f.tnn) !== -1}
                                 onCheckChange={value => changeListCheck(value, f.tnn)}
                                 faculty={f}/>
        }
    )

    return (
        <div className="mt-2"><i>Выберите кафедры, по которым необходимо провести опрос:</i>
            <table className='table table-bordered'>
                <thead>
                <tr>
                    <th scope="col" style={{width: 10}}><input type="checkbox" id="allFaculty" value={-1}/>
                    </th>
                    <th scope="col" style={{textAlign: "center"}}>Кафедра</th>
                </tr>
                </thead>
                <tbody>
                {departmentAllList}
                </tbody>
            </table>
        </div>
    );


}