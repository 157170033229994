import React, {useState} from "react";
import logo from './logo.png';
import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import {Navbar} from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useSelector} from "react-redux";
import UserIco from "./Button/UserIco";
import {NavLink} from "react-router-dom";
import ApiSecurity from "../API/ApiSecurity";

export default function Header() {

  const {user} = useSelector(state => state.auth);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar bg="light" expand={false}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height='55'
              width='70'
              className='d-inline-block align-text-top'
              alt='Logo'
            />Рейтинг кафедр 2023
          </Navbar.Brand>
          <Navbar.Toggle onClick={handleShow} aria-controls='offcanvasNavbar-expand-xxxl'/>
          <Navbar.Offcanvas
            id='offcanvasNavbar-expand-xxl'
            aria-labelledby='offcanvasNavbarLabel-expand-xxl'
            placement="end"
            show={show} onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id='offcanvasNavbarLabel-expand-xxxl'>
                Сервис "Верификация показателей деятельности"
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {!user &&
                <div>
                  <a href="/api/saml/login" className='btn btn-outline-primary'>Войти</a>
                </div>}
              {!!user &&
                <div>
                  <UserIco/>
                  <b> {user?.fullName.toLowerCase().replace(/(^|\s)\S/g, function (a) {
                    return a.toUpperCase()
                  })}</b>
                  <a onClick={() => ApiSecurity.logout()} href="/api/saml/logout"
                     className='btn btn-outline-primary'>Выйти</a>
                </div>}
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <NavLink to="/" onClick={handleClose} className="nav-link"> Главная </NavLink>
                {!!user && (user?.roles.includes('ROLE_EXPERT') || user?.roles.includes('ROLE_ADMIN')) &&
                  <NavLink to="/interview" onClick={handleClose} className="nav-link"> Рейтинг кафедр </NavLink>}
                {!!user && (user?.roles.includes('ROLE_ADMIN')) &&
                  <NavLink to="/admin" onClick={handleClose} className="nav-link"> Администрирование </NavLink>}
                {!!user && (user?.roles.includes('ROLE_DEPARTMENT') || user?.roles.includes('ROLE_DEAN')) &&
                  <NavLink to="/view" onClick={handleClose} className="nav-link"> Просмотр рейтинга </NavLink>}
                 {!!user && (user?.roles.includes('ROLE_COMMISSION')) &&
				          <NavLink to="/commission" onClick={handleClose} className="nav-link"> Комиссия </NavLink>}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )

}