import React, {useEffect,  useRef, useState} from "react";

import {
	loadActiveSurveyAll,
	updateSurvey
} from "../../API/surveyApi";


import SurveyTitle from "../SurveyPage/SurveyTitle";
import dateFormat from "dateformat";
import PlusIco from "../Button/PlusIco";
import {useDispatch, useSelector} from "react-redux";
import UnitAdd from "./UnitAdd";

import {
	activeChapter,
	loadIDSurveyParameterValue,
	saveParametersValues, updateParametersValues
} from "../../API/parameterApi";


import {putParameters} from "../../Store/ParametrsSlicer";
import InterviewADDForRaiting from "../InterviewRaiting/ADDInterviewForRaiting";
import {loadUnitsForSurvey} from "../../API/listUnitAPI";
import {putUnit} from "../../Store/UnitSlicer";
import ModalDialogBig from "../Common/ModalBig";
import {putReloadParametr} from "../../Store/Reload";


export default function ListOfInterview() {
	const [isLoad, isLoadChanger] = useState(false);
	const [show, showChanger] = useState(false);
	const [listSurvey, listSurveyChanger] = useState([]);
	const [idSurvey, idSurveyChanger] = useState();
	const [typeOfSurvey, changerTypeOfSurvey] = useState(0)
	const [page, handlerChangerPage] = useState(1);
	const [total, totalChanger] = useState(0);
	const unitID = useSelector(state => state.units);
	const dispatch = useDispatch();
	const [titleHead, setTitleHead] = useState('');
	const itemUnitID = useSelector(state => state.units);
	const [activeChapterSt, activeChapterStChanger] = useState('');
	//const [itemUnitID,itemUnitIDChanger] = useState([]);

	const listParams = useSelector(state => state.parameters.list);
	const listParamsCommission = useSelector(state => state.parametersCommission.list);

	const [perIDParameter, perIDParameterChanger] = useState(0);

	const [showButtonConfirm, showButtonConfirmChanger] = useState(false);
	const [showButtonUnConfirm, showButtonUnConfirmChanger] = useState(false);
	const {user} = useSelector(state => state.auth);
	const userTN = Number(user?.tnn)
	const refModal = useRef(null);

	const currentUrl = window.location.href;

	const compareName = (a, b) => a.name < b.name ? -1 : 1;
	const newListUnit = (list) => {
		const result = list?.map((el) =>
			({id: Number(el?.tnn), name: el?.title}));
		const resutByName = result?.sort(compareName);
		return resutByName;
	}

	useEffect(() => {
		const page = 1;
		if (!isLoad) {
			loadActiveSurveyAll()
				.then(data => {
					listSurveyChanger(data);
					if (data?.length === 1) {
						totalChanger(1)
					} else {
						totalChanger(Math.round(data?.length / 4))
					}
					isLoadChanger(true);
				})
				.catch(isLoadChanger(false))
		}

		loadUnitsForSurvey()
			.then((data) => {
				const rez = newListUnit(data)
				//itemUnitIDChanger(rez);
				dispatch(putUnit(rez[0]?.id));
			})
			.catch(
			)
		activeChapter({userTN}).then(data => {
			const newArr = data.map((i) => {
				return i.subject.split('/')[3]
			})
			activeChapterStChanger(newArr)
		}).catch()
	}, [])


	const handlerChanger = (id, subjectType, title) => {
		showChanger(true);
		idSurveyChanger(id);
		changerTypeOfSurvey(subjectType)
		setTitleHead(title);
		dispatch(putParameters([]));
	}



	//const list = listSurvey?.filter((item, index) => index < 4 * page && index >= 4 * (page - 1)).map((l) => {
	const list = listSurvey?.map((l) => {
		const group = {value: l.subjectType.split('/')[3]};
		const title = <SurveyTitle typeSurvey={l.period}
								   datePeriod={l.periodSize}
								   department={group}
								   ndate={dateFormat(l.bonusPeriodStartAt, "yyyy-mm-dd")}
								   kdate={dateFormat(l.bonusPeriodEndAt, "yyyy-mm-dd")}/>;

		let typeSurvey = '';
		let type = (l.period).split('')[0];
		if (type === 'q') {
			typeSurvey = "Квартальный";
		} else if (type === 'm') {
			typeSurvey = "Ежемесячный";
		} else if (type === 'a') {
			typeSurvey = "Полугодовой";
		} else if (type === 'y') {
			typeSurvey = "Годовой";
		}

		return (
			<tr key={l.id}>
				<td>
					<div style={{"cursor": "pointer"}}
						 onClick={() => handlerChanger(l.id, l.subjectType.split('/')[3], title)}>
						<u>{title}</u>
					</div>
				</td>
				<td>{typeSurvey}</td>
				<td>
					<div className="d-inline-flex">
						<button className='btn' title={'Заполнить'}
								onClick={() => handlerChanger(l.id, l.subjectType.split('/')[3], title)}>< PlusIco/>
						</button>
						{/*<button className='btn' title={'Отправить'} onClick={() => sendSurveys(l.id)}><SendIco/>*/}
						{/*</button>*/}
					</div>
				</td>
			</tr>
		)
	});

	const onClose = () => {
		if (window.confirm("Вы хотите закрыть окно?") === true) {
			showChanger(false)
		}
	}

	const newList = (list) => {
		const result = list.map((el) => ({id: Number(el.tnn), name: el.title}));
		const resutByName = result.sort(compareName);
		return resutByName;
	}

	const loadParameters = () => {
		//подтверждение параметров
		let perAccess=null;
		listParams.map((l) => {
			const idEl = l.id
			if (l?.accesses) {
				 perAccess = l?.accesses?.find((item) => Number(item.responsibleUser?.tnn) === userTN)?.id
			}else {
				 perAccess = l?.idAccess
			}

				const perSave = {
					data: "0.00",
					author: "EXPERT",
					reason: "/api/survey_parameter_accesses/" + perAccess,
					surveyParameter: "/api/survey_parameters/" + idEl,
					description: "",
					status: "VALID",
				}
				const el2 = {
					author: "EXPERT",
					status: "VALID"
				}
				loadIDSurveyParameterValue({parameter: l.id}).then(r => {

					const p = r?.find((item) => Number(item?.reason?.responsibleUser?.tnn) === userTN)?.id;
					if (p === undefined) {

						saveParametersValues({parameter: perSave}).then(data => {
								const result = data;
							}
						)
					} else {
						updateParametersValues({parameter: el2, id: p}).then(data => {
						})
					}
					//perIDParameterChanger(p)
				}).catch()
			}
		)

	}
	const unloadParameters = () => {
		//отмена подтверждение параметров
		listParams.map((l) => {
			//const perAccess = l?.accesses?.find((item) => Number(item.responsibleUser?.tnn) === userTN)?.id
			const el2 = {

				status: "FOR_CHECK"
			}
			loadIDSurveyParameterValue({parameter: l.id}).then(r => {
				const p = r?.find((item) => Number(item?.reason?.responsibleUser?.tnn) === userTN)?.id;
				updateParametersValues({parameter: el2, id: p}).then(data => {
				})
			}).catch()
		})
	}

	const confirmParameters = () => {
		//подтверждение параметров для комиссии
		//обновляем статус на valid и для несуществующих создаем новый
		console.log(listParams)
		let perAccess=null;

		const listParamsForConfirm = listParams.filter((el)=>activeChapterSt.includes(el.chapter.split('/')[3]))
	console.log(listParamsForConfirm)
		listParamsForConfirm.map((l) => {
				const idEl = l.id
				// if (l?.accesses) {
				// 	perAccess = l?.accesses?.find((item) => Number(item.responsibleUser?.tnn) === userTN)?.id
				// }else {
				// 	perAccess = l?.idAccess
				// }

				const perSave = {
					data: "0.00",
					author: "COMMISSION",
				//	reason: "/api/survey_parameter_accesses/" + perAccess,
					surveyParameter: "/api/survey_parameters/" + idEl,
					description: "",
					status: "VALID",
				}
				const el2 = {
				//	author: "COMMISSION",
					status: "VALID"
				}

				loadIDSurveyParameterValue({parameter: l.id}).then(r => {

					const p = r?.find((item) => item.author.includes("COMMISSION"));

					if (p === undefined) {

						saveParametersValues({parameter: perSave}).then(data => {
								const result = data;
							}
						)
					} else {
						updateParametersValues({parameter: el2, id: p.id}).then(data => {
						})
					}
					//perIDParameterChanger(p)
				}).catch()
			}
		)

	}

	const unConfirmParameters = () => {
		//отмена подтверждение параметров для комиссии
		const listParamsForConfirm = listParams.filter((el)=>activeChapterSt.includes(el.chapter.split('/')[3]))
		console.log(listParamsForConfirm)
		listParamsForConfirm.map((l) => {
			//const perAccess = l?.accesses?.find((item) => Number(item.responsibleUser?.tnn) === userTN)?.id
			const el2 = {
				status: "FOR_CHECK"
			}

			loadIDSurveyParameterValue({parameter: l.id}).then(r => {
				const  p= r?.find((item) => item.author.includes("COMMISSION"));
				//console.log(p)
				updateParametersValues({parameter: el2, id: p.id}).then(data => {
				})
			}).catch()
		})
	}



	const handleConfirm = () => {
		if (window.confirm("Хотите подтвердить параметры?") === true) {
			loadParameters()
			showButtonConfirmChanger(true)
			showButtonUnConfirmChanger(false)
			dispatch(putReloadParametr(true))
		}
	}

	const handleCancelConfirm = () => {
		if (window.confirm("Хотите отменить подтверждение параметров?") === true) {
			unloadParameters();
			showButtonConfirmChanger(false)
			showButtonUnConfirmChanger(true)
			dispatch(putReloadParametr(true))
		}
	}

	const handleConfirmCommission = () => {
		if (window.confirm("Хотите подтвердить параметры?") === true) {
			confirmParameters()

			showButtonConfirmChanger(true)
			showButtonUnConfirmChanger(false)
			dispatch(putReloadParametr(true))
		}
	}
	const handleCancelConfirmCommission = () => {
		if (window.confirm("Хотите отменить подтверждение параметров?") === true) {
			unConfirmParameters();
			showButtonConfirmChanger(false)
			showButtonUnConfirmChanger(true)
			dispatch(putReloadParametr(true))
		}
	}

	return (
		<div className="table-responsive-sm">
			{typeOfSurvey === 1
				?
				<ModalDialogBig isOpen={show}
								title={titleHead}
								handleClose={onClose}
								handleConfirm={handleConfirm}
								handleCancelConfirm={handleCancelConfirm}
								handleConfirmProps={showButtonConfirm}
								handleCancelConfirmProps={showButtonUnConfirm}
								refModal={refModal}
					// changeButton = {changeButton}
								size="xl">
					<UnitAdd idSurvey={idSurvey} typeOfSurvey={typeOfSurvey}/>
				</ModalDialogBig>
				:
				<ModalDialogBig isOpen={show}
								title={titleHead}
								handleClose={onClose}
								handleConfirm={currentUrl.includes("/commission") ? handleConfirmCommission : handleConfirm}
								handleCancelConfirm={currentUrl.includes("/commission") ? handleCancelConfirmCommission:handleCancelConfirm}
								handleConfirmProps={showButtonConfirm}
								handleCancelConfirmProps={showButtonUnConfirm}
								refModal={refModal}
								size="xl">
					<InterviewADDForRaiting idSurvey={idSurvey} unit={itemUnitID.itemUnit}/>
				</ModalDialogBig>
			}
			{currentUrl.includes("/commission") ? <h1>Комиссия</h1> : null}
			<table className="table table-hover ">
				<thead className=" table-secondary">
				<tr>
					<th scope="col">Наименование документа</th>
					<th scope="col">Период</th>
					<th scope="col">Действия</th>
				</tr>
				</thead>
				<tbody>
				{list}
				</tbody>
			</table>

			{/*{listSurvey.length>0 ?*/}
			{/*	<div className='d-flex justify-content-center'>*/}
			{/*		<Pagination current={page} pages={total} handler={handlerChangerPage}/>*/}
			{/*	</div>*/}
			{/*	:null*/}
			{/*}*/}

		</div>
	)
}
