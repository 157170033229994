import React, {useEffect, useState} from "react";
import EditIco from "../Button/EditIco";
import DeleteIco from "../Button/DeleteIco";
import SendIco from "../Button/SendIco";
import ModalSurvey from "../Modal/ModalAddSurvey";
import SurveyTitle from "./SurveyTitle";
import {loadActiveSurvey, loadSurveyByID, saveSurvey, updateSurvey} from "../../API/surveyApi";
import dateFormat from "dateformat";
import ModalSendForUnit from "../Modal/ModalSendForUnit";
import ReadingIco from "../Button/ReadingIco";
import ModalReadFile from "../Modal/ModalReadFile";
import spinerLoading from "../spiner";


export default function ListSurvey({survey, group, onDeleteSurvey}) {
    const [spisSurvey, setSpisSurvey] = useState(survey);
    const [shows, setShows] = useState(false);
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showRead, setShowRead] = useState(false);
    const [statusSurvey, setStatusSurvey] = useState('hidden');
    const [loading, setLoading] = useState(false);
    const [department, setDepartment] = useState(null);

    let surveyID = survey.id;

    useEffect(() => {
        if(survey !== null){
            loadSurveyByID({survey: surveyID})
                .then(dataSurvey => {
                    if (dataSurvey.surveyParameters && dataSurvey.surveyParameters.length > 0) {
                        const k = dataSurvey.surveyParameters.some((p) => {
                            return p.accesses.length > 0;
                        });
                        setShowButton(k);
                    }
                });
            setDepartment((survey.subjectType).split('/')[3]);
            setLoading(true);
        }
    }, []);

    useEffect(() => {
        loadActiveSurvey({idSurvey: surveyID}).then((activeSurvey) => {
            if (activeSurvey.length > 0) {
                setStatusSurvey('active')
            }
            else {
                setStatusSurvey('hidden')
            }
            setLoading(false);
        })
    }, [survey]);

    const handleOpenEditForm = () => {
        setShows(true);
    }
    const handleOpenSend = () => {
        setShow(true);
    }
    const handleSaveEditSurvey = (idSurvey) => {
        updateSurvey ({survey: idSurvey}).then(r => {
                setSpisSurvey(r)
                setShows(false)
            }
        )
    };
    const handleCloseForm = () => {
        setShows(false);
    }
    const closeFormSend = () => {
        setShow(false);
    }
    const closeReadForm = () => {
        setIdRead(0);
        setShowRead(false);
    }
    const [idRead, setIdRead] = useState(0);
    const openReadForm = (idData) => {
        setIdRead(idData);
        setShowRead(true);
    }
    const saveFormSend = (unitVal,idSurvey) => {
        const saveNewSurvey = unitVal?.map((survey) => {
            saveSurvey({survey}).then(r => {
                setShow(false);
            })
        })
        const dataInfo = {
            id: idSurvey,
            status: 'active'
        }
        updateSurvey({survey: dataInfo}).then(r => {
                setSpisSurvey(r)
            }
        )
    }
    const handleDeleteSurvey = (survey) => {
        onDeleteSurvey(survey);
    }

    let title = <SurveyTitle typeSurvey={survey.period}
                             datePeriod={survey.periodSize}
                             department={group}
                             ndate={dateFormat(survey.bonusPeriodStartAt, "yyyy-mm-dd")}
                             kdate={dateFormat(survey.bonusPeriodEndAt, "yyyy-mm-dd")}/>;
    let typeSurvey = '';
    let type = (survey.period).split('')[0];

    if (type === 'q') {
        typeSurvey = "Квартальный";
    } else if (type === 'm') {
        typeSurvey = "Ежемесячный";
    } else if (type === 'a') {
        typeSurvey = "Полугодовой";
    } else if (type === 'y') {
        typeSurvey = "Годовой";
    }
   return (
        <tr key={survey.id}>
            {show === true ?
                <ModalSendForUnit
                    isOpen={show}
                    title={<p>Выберите структурные подразделения по которым необходимо провести опрос "{group.label}"</p>}
                    idSurvey={survey.id}
                    department={department}
                    onCloseSend={closeFormSend}
                    onSaveSend={saveFormSend}
                />
                : null}
            <td>{title}</td>
            <td>{typeSurvey}</td>
            <td>
                {
                    (loading === true)
                        ? spinerLoading()
                        : statusSurvey === 'hidden'
                            ? <div>
                                <button className="btn" onClick={() => handleOpenEditForm(spisSurvey.id)}
                                        title={'Изменить опрос'}>
                                    <EditIco/>
                                </button>
                                <ModalSurvey
                                    isOpen={shows}
                                    title={<p> Изменить опрос: {group.label}</p>}
                                    id={survey.id}
                                    type={survey.period}
                                    idPeriod={survey.periodSize}
                                    idDepartment={group}
                                    ndate={dateFormat(spisSurvey.bonusPeriodStartAt, "yyyy-mm-dd")}
                                    kdate={dateFormat(spisSurvey.bonusPeriodEndAt, "yyyy-mm-dd")}
                                    onSaveShow={handleSaveEditSurvey}
                                    onCloseShow={handleCloseForm}
                                />
                                <button className="btn" style={showButton ? {display: 'inline'} : {display: 'none'}} onClick={() => handleOpenSend(spisSurvey.id)}
                                        title={'Выбрать отделы и отправить экспертам'}>
                                    <SendIco/>
                                </button>
                                <button className={"btn"} onClick={() => handleDeleteSurvey(spisSurvey)}
                                        title={'Удалить опрос'}>
                                    <DeleteIco/>
                                </button>
                            </div>
                            :
                            <div>
                                <button className="btn" title={'Просмотр опросника'} onClick={() => openReadForm(survey.id)}>
                                    <ReadingIco/>
                                </button>
                                {
                                    showRead === true ?
                                        <ModalReadFile
                                            isOpen={showRead}
                                            title={title}
                                            id={idRead}
                                            onCloseFile={closeReadForm}/>
                                        : null
                                }
                            </div>

                }
            </td>
        </tr>
    )

}