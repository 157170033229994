import {Col, Nav, Row, Tab, TabContent, TabPane, Container} from "react-bootstrap";
import React, {useState} from "react";
import ArchiveOfInterview from "./ArchiveInterview";
import ListOfInterview from "./ListOfInterview";
import SendedOfInterview from "./SendedInterview";
import ViewSurveysForCheck from "./ViewSurveysForCheck";

export default function MenuInterview() {
	const [key, setKey] = useState('Surveys');

	const listPage = (key) => {
		switch (key) {
			case 'Surveys':
				return <ListOfInterview/>;
			case 'Sended':
				return <SendedOfInterview/>;
			case 'Archive':
				return <ArchiveOfInterview/>;
			case 'View':
				return <ViewSurveysForCheck/>;
			// default:
			// 	return <ListOfInterview/>;
		}
	};
	return (
		<Container className="content container-fluid ">
			<Tab.Container id="controlled-tab-example"
						   activeKey={key}
						   onSelect={(k) => setKey(k)}>
				<Row>
					<Col sm={2}>
						{/*<Nav variant="pills" className="flex-column mt-10 ">*/}
						{/*	<Nav.Item>*/}
						{/*		<Nav.Link eventKey='Surveys'>Список доступных к заполнению документов</Nav.Link>*/}
						{/*	</Nav.Item>*/}
							{/*<Nav.Item>*/}
							{/*	<Nav.Link eventKey='Sended'>Отправленные данные для Комиссии</Nav.Link>*/}
							{/*</Nav.Item>*/}
							{/*<Nav.Item>*/}
							{/*	<Nav.Link eventKey='Archive'>Архив документов</Nav.Link>*/}
							{/*</Nav.Item>*/}
							{/*<Nav.Item>*/}
							{/*	<Nav.Link eventKey='View'>Просмотр данных руководителем</Nav.Link>*/}
							{/*</Nav.Item>*/}
						{/*</Nav>*/}
					</Col>

					<Col sm={9}>
						<TabContent className="mt-1">
							<TabPane eventKey={key}>
								{listPage(key)}
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Tab.Container>
		</Container>
	)


}