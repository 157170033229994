import React, {useState, useEffect} from "react";
import ModalSurvey from "../Modal/ModalAddSurvey";
import ListSurvey from "./ListSurvey";
import {deleteSurvey, loadListSurvey, saveSurvey} from "../../API/surveyApi";
import spinerLoading from "../spiner";
import Select from "react-select";
import {loadAllGroup, loadSurveyType} from "../../API/parameterApi";
import {get} from "../../API/api";
import {useSelector} from "react-redux";
import {infoUserAccessTN} from "../../API/accessAPI";

const addSurvey = {
    cursor: "pointer",
}

export default function NewSurvey() {
    const [shows, setIsShows] = useState(false);
    const [spisSurvey, setSpisSurvey] = useState(null);
    const [group, setGroup] = useState(null);
    const [selectGroup, setSelectGroup] = useState(null);
    const {user} = useSelector(state => state.auth);
    const [userAccess, setUserAccess] = useState([]);
    const [userGroup, setUserGroup] = useState(null);

    const openShow = () => {
        setIsShows(true);
    }
    useEffect(() => {
        if(user) {
            infoUserAccessTN({user: '/api/users/'+user.tnn})
                .then(setUserAccess);
        }
        if (group === null) {
            loadAllGroup().then(setGroup);
        }
    }, []);

    useEffect(() => {
        if(group !== null){
            const groupInfo = group.filter((access)=>{
                for(const user of userAccess){
                    if(user.subject === access['@id']){
                        return true;
                    }
                }
            })
            setUserGroup(groupInfo);
        }
        else{
            return;
        }
    }, [group]);

    useEffect(() => {
        if (selectGroup === null) {
            return;
        } else {
            loadListSurvey({group: '/api/survey_types/' + selectGroup.value})
                .then(setSpisSurvey);
        }
    }, [selectGroup]);

    const listGroup = userGroup?.map((r) => {
        return {
            value: r.id,
            label: r.title
        }
    });

    const saveShowModal = (survey) => {
        saveSurvey({survey}).then(r => {
                setSpisSurvey(prevState => [...prevState, r])
                setIsShows(false);
            }
        )
    }
    const closeShowModal = () => {
        setIsShows(false);
    }
    const deleteSurveys = (idSurvey) => {
        deleteSurvey({idSurvey: idSurvey.id})
            .then(() => {
                setSpisSurvey([...spisSurvey].filter(r => r.id !== idSurvey.id))
            })
    }

    return (
        <div>
            <div>
                <Select options={listGroup} value={selectGroup} placeholder="Выберите тип опроса"
                        onChange={setSelectGroup}/>
            </div>
            <div>
                {
                    selectGroup !== null ?
                        <div>
                            <div style={{margin: 15, textAlign: "right"}}>
                                <a href='#' onClick={openShow}>Создать новый опрос</a>
                                {shows === true ?
                                    <ModalSurvey
                                        isOpen={shows}
                                        title={<p> Создать опрос: {selectGroup.label} </p>}
                                        id="0"
                                        type={''}
                                        idPeriod={''}
                                        idDepartment={selectGroup}
                                        ndate={'11.11.2222'}
                                        kdate={'11.11.2222'}
                                        onSaveShow={saveShowModal}
                                        onCloseShow={closeShowModal}
                                    />
                                    : null}
                            </div>
                            <div className="table-responsive rounded-3">
                                <table className="table table-hover table-bordered border-light">
                                    <thead style={{background: "#eaecf59e", textAlign: "center"}}>
                                    <tr>
                                        <th scope="col">Название опроса</th>
                                        <th scope="col">Тип опроса</th>
                                        <th scope="col" style={{width: 160}}>Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        spisSurvey === null ?
                                            <tr>
                                                <td colSpan='3' align='center'>{spinerLoading()}</td>
                                            </tr>
                                            :
                                            spisSurvey.length === 0 ?
                                                <tr>
                                                    <td colSpan='3' align='center'>{'Нет данных для отображения'}</td>
                                                </tr>
                                               :  spisSurvey.length > 0 ?
                                                spisSurvey?.map((surveys, key) => (
                                                    <ListSurvey survey={surveys} group={selectGroup}
                                                                onDeleteSurvey={deleteSurveys}/>
                                                ))
                                        : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : null}
            </div>
        </div>
    )
}