import React from "react";

export default function FacultySpisCheck({faculty, isChecked, onCheckChange}) {

    return(
        <tr>
            <td><input type={"checkbox"} value={faculty.tnn} checked={isChecked} onChange={() => onCheckChange(!isChecked)}/></td>
            <td>{faculty.title}</td>
        </tr>
    );
}