import Header from "./Header";
import {Outlet} from "react-router-dom";
import React from "react";
import Footer from "./Footer";
import {Stack} from "react-bootstrap";
export default function Layout() {
  return (
    <Stack className="container" style={{minHeight:'100vh'}} gap={3}>
      <Header className="p-2"/>
      <Outlet className="p-2 mx-auto"/>
      <Footer  className="p-2"/>
    </Stack>
  )
}