import React, {useEffect, useState} from "react";
import ModalDialog from "../Common/Modal";
import Interview from "./Interview";
import {loadArhiveSurvey} from "../../API/surveyApi";
import PreviewIco from "../Button/PreviewIco";
import SurveyTitle from "../SurveyPage/SurveyTitle";
import dateFormat from "dateformat";

export default function SendedOfInterview() {
	const [show, showChanger] = useState(false);
	const [page, pageChanger] = useState(1);
	const [isLoad, isLoadChanger] = useState(false);
	const [listArchive, listArchiveChanger] = useState([]);


	useEffect(() => {
			if (!isLoad) {
				loadArhiveSurvey(page)
					.then(data => {
						listArchiveChanger(data);
						isLoadChanger(true);
					})
					.catch(console.log('no result in archive'))
			}
		}, [isLoad]
	)




    const handleShow = () => {
        showChanger(true);
    }

    const list = listArchive?.map((l) => {
        const group = {value:l.subjectType.split('/')[3]};
        const title = <SurveyTitle typeSurvey={l.period}
                                   datePeriod={l.periodSize}
                                   department={group}
                                   ndate={dateFormat(l.startAt, "yyyy-mm-dd")}
                                   kdate={dateFormat(l.endAt, "yyyy-mm-dd")}/>;
        let typeSurvey = '';
        if (l.type === 'quarter') {
            typeSurvey = "Квартальный";
        } else if (l.type === 'monthly') {
            typeSurvey = "Ежемесячный";
        } else if (l.type === 'annual') {
            typeSurvey = "Полугодовой";
        }

        return <tr key={l.id}>
            <td>{title}</td>
            <td>{typeSurvey}</td>
            <td>
                <button className='btn' title={'Просмотреть'}><PreviewIco/></button>
            </td>
        </tr>
    });

    return (
        <div className="table-responsive-sm">

            <ModalDialog isOpen={show} title="Редактировать опрос">

            </ModalDialog>

            <table className="table table-hover ">
                <thead className=" table-secondary">
                <tr>
                    <th scope="col">№</th>
                    <th scope="col">Опрос</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {list}
                </tbody>
            </table>
        </div>
    )
}