import {useSelector} from "react-redux";
import ApiSecurity from "./ApiSecurity";

async function loadParameterByID({page}) {
	return await (await fetch(`/api/parameters`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllParameter() {
	let page = 1;
	const firstPage = await loadParameterByID({page: page++});
	const parameterCount = firstPage['hydra:totalItems'];
	let parameter = firstPage['hydra:member'];

	while (parameter.count < parameterCount) {
		parameter = [...parameter, (await loadParameterByID({page: page++}))['hydra:member']]
	}
	return parameter;
}
// наименование группы для показателей
async function loadSurveyType({urlType}) {
	return await (await fetch(`${urlType}`, {headers: await ApiSecurity.getAuthHeaders()})).json();

}
async function loadIDSurveyParameterValue({parameter}) {

	const result=await (await fetch (`api/survey_parameters/${parameter}`,{headers: await ApiSecurity.getAuthHeaders()})).json();
	return result["surveyParameterValues"]
}

// список параметров с разбивкой по группам
async function loadAllParameterGroup({group}) {
	let url = group;
	let idGroup = (group).split('/')[2];
	if(idGroup === '2'){
		url = group + '&order[chapter]=asc';
	}
    const firstPage = await (await fetch(`/api/parameters?surveyType=${url}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    const parameterCount = firstPage['hydra:totalItems'];
    let parameter = firstPage['hydra:member'];

    while(parameter.count < parameterCount){
        parameter = [...parameter, (await (await fetch(`/api/parameters?surveyType=${url}`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member']]
    }
    return parameter;
}

async function saveParametersValues({parameter}) {

	const result = await (await fetch(
		`api/survey_parameter_values `,
		{
			method: 'POST',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
			body: JSON.stringify(parameter)
		}
	)).json()

	return result.id;

}

async function updateParametersValues({parameter,id}) {

	const result = await (await fetch(
		`api/survey_parameter_values/${id}`,
		{
			method: 'PATCH',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/merge-patch+json"}),
			body: JSON.stringify(parameter)
		}
	)).json()

	return result.id;

}

async function saveParameter({parameter}) {
	return await (await fetch(
		`/api/parameters.json`,
		{
			method: 'POST',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
			body: JSON.stringify(parameter)
		})).json()
}
async function saveParametersToSurvey({parameters}) {
	return await (await fetch(
		`/api/survey_parameter_values`,
		{
			method: 'POST',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
			body: JSON.stringify(parameters)
		}
	)).json()
}

async function updateParameter({parameter}) {

	return await (await fetch(
		`/api/parameters/${parameter.id}.json`,
		{
			method: 'PUT',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
			body: JSON.stringify(parameter)
		})).json()
}

async function deleteParameter({parameter}) {
	await fetch(
		`/api/parameters/${parameter.id}`,
		{
			method: 'DELETE',
			headers:  Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
		});
}

async function deleteParameterValue(idItem){

	await fetch(
		`api/survey_parameter_values/${idItem}`,
		{
			method:'DELETE',
			headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
		}
	)
}

async function loadSurveysParamets({idChildrenSurvey, userTN}) {

	const result = await (await fetch(`/api/surveys/${idChildrenSurvey}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	//меняла для поиска доступа к подразделению

	const nResult = result['surveyParameters']?.map(item => {
		if (item.accesses?.find((item) => Number(item.responsibleUser?.tnn) === Number(userTN))) {
			return item
		} else return 0
	})
	const result2 = nResult?.filter(item => item !== 0)

	return result2
}

async function loadParametersBySurveyID({parameter,userTN,idSurvey}) {
//parametr its faculty's id
	const result = await (await fetch(`api/surveys?page=1&parent=${idSurvey}&subjectUnit.tnn=${parameter}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	if (result["hydra:totalItems"]==0) {
		return 0
	}
	else {
		const idChildrenSurvey = result['hydra:member']?.[0]?.['id'];

		if (result !== undefined) {
			return loadSurveysParamets({idChildrenSurvey, userTN});
		} else return null;
		return result
	}
}
async function loadSurveysParametsAll({idChildrenSurvey}) {

	const result = await (await fetch(`/api/surveys/${idChildrenSurvey}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	//меняла для поиска доступа к подразделению

	const nResult = result['surveyParameters']?.map(item => {
			return item
	})
	const result2 = nResult?.filter(item => item !== 0)

	return result2
}
async function loadParametersBySurveyIDForCommisssion({parameter,idSurvey}) {
//parametr its faculty's id
	const result = await (await fetch(`api/surveys?page=1&parent=${idSurvey}&subjectUnit.tnn=${parameter}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	if (result["hydra:totalItems"]==0) {
		return 0
	}
	else {
		const idChildrenSurvey = result['hydra:member']?.[0]?.['id'];

		if (result !== undefined) {
			return loadSurveysParametsAll({idChildrenSurvey});
		} else return null;
		return result
	}
}

async function loadAllGroup() {
	const firstPage = await (await fetch(`/api/survey_types`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	const groupCount = firstPage['hydra:totalItems'];
	let group = firstPage['hydra:member'];
	while (group.count < groupCount) {
		group = [...group, (await (await fetch(`/api/survey_types`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member']]
	}
	return group;
}

//сохранение новых разделов
async function saveChapters({title}){
    return await (await fetch(
        `/api/chapters`,
        {
            method:'POST',
            headers:Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body:JSON.stringify({title})
        }

    )).json()
}

async function loadGroupInfo({idGroup}){
    const result = await (await fetch(`/api/survey_types/${idGroup}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return result;
}
async function ListChapter(){
    const chapter = (await (await fetch(`api/chapters?page=1`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member'];
    return chapter;
}
async function infoChapter({url}){
	const info = await (await fetch(`${url}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return info;
}

async function selectChapters({chapter}){
	const listChapter = (await (await fetch(`/api/parameters?chapter=/api/chapters/${chapter}`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member'];
	return listChapter;
}

async function activeChapter({userTN}) {
	const result = await (await fetch(`/api/user_accesses?page=1&user=/api/users/${userTN}&role=COMMISSION`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return result['hydra:member'];
}
export {
	loadAllParameter,
	saveParameter,
	updateParameter,
	deleteParameter,
	deleteParameterValue,
	loadParametersBySurveyID,
	loadSurveysParamets,
	loadSurveysParametsAll,
	loadParametersBySurveyIDForCommisssion,
	saveParametersValues,
	updateParametersValues,
    loadAllGroup,
    loadAllParameterGroup,
	saveParametersToSurvey,
    saveChapters,
    loadGroupInfo,
	loadSurveyType,
	ListChapter,
	infoChapter,
	selectChapters,loadIDSurveyParameterValue,
	activeChapter,
}