import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import Button from 'react-bootstrap/Button';


export default function ModalDialog({
										title,
										children,
										isOpen,
										handleClose,
										handleSave,
										size,flag
									}) {
	return (
		<Modal scrollable={true} show={isOpen} onHide={handleClose} animation={true} size={size}
			   aria-labelledby="contained-modal-title-vcenter"
			   centered>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>

			<Modal.Body>{children}</Modal.Body>

			<Modal.Footer>

				<Button style={flag===1? {display:"none"} : {display:"block"}} variant="secondary" onClick={handleClose}>
					Закрыть
				</Button>

				<Button style={flag===1? {display:"none"} : {display:"block"}} variant="primary" onClick={handleSave}>
					Сохранить
				</Button>

			</Modal.Footer>

		</Modal>
	);
}