import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {infoUserAccessTN} from "../../API/accessAPI";
import {loadAllGroup} from "../../API/parameterApi";
import {listSurveyForCommission, loadActiveSurvey, loadActiveSurveyAll, loadSurveyByID} from "../../API/surveyApi";
import {get} from "../../API/api";
import {getNameUnit, infoUnit, loadNameUnit} from "../../API/listUnitAPI";
import Loader from "../Common/Loader";
import Select from "react-select";
import {loadCafedry} from "../../API/facultyAPI";
import Info from "../Button/Info";
import ModalDescription from "../Modal/ModalDescription";

//api/units?page=1&owner%5B%5D=%2Fapi%2Funits%2F3&owner%5B%5D=%2Fapi%2Funits%2F4673&type=department
const loadUnits = async unitsIrls => {
    const ids = [];
    for (const irl of unitsIrls) {
        const id = irl.replace('', 'owner[]=');
        ids.push(id);
    }
    const url = '/api/units?page=1&type=department&' + ids.join('&');
    return (await get({irl: url}))['hydra:member'];
}
export default function ListViewForDepartment() {
    const [userAccess, setUserAccess] = useState(null);
    const {user} = useSelector(state => state.auth);
    const [unitAccess, setUnitAccess] = useState([]);
    const [survey, setSurvey] = useState(null);
    const [role, setRole] = useState('');
    const [listDepartment, setListDepartment] = useState([]);
    const [selectDepartment, setSelectDepartment] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [flagAccess, setFlagAccess] = useState(false);
    const [idUnit, setIdUnit] = useState([]);
    const [titleUnit, setTitleUnit] = useState('');

    useEffect(() => {
        if (!!user) {
            if (user?.accessChunks["/api/units/*"].includes("DEPARTMENT")) {
                setRole('ROLE_DEPARTMENT');
                setFlagAccess(true);
            } else if (user?.accessChunks["/api/units/*"].includes("DEAN")) {
                setRole('ROLE_DEAN');
                setFlagAccess(true);
            }
            else if (user?.roles.includes('ROLE_DEPARTMENT')) {
                setRole('ROLE_DEPARTMENT');
                setUnitAccess([{unit: user?.userUnitPositions[0].unit['@id']}]);
            } else if (user?.roles.includes('ROLE_DEAN')) {
                setRole('ROLE_DEAN');
                setUnitAccess([{unit: user?.userUnitPositions[0].unit['@id']}]);
            }
               else {
                return;
            }
        }
    }, []);
    useEffect(() => {
        if(flagAccess === true){
            infoUserAccessTN({user: '/api/users/' + user.tnn})
                .then(setUserAccess);
        }
        else{return;}
        setFlagAccess(false);
    }, [flagAccess]);

    useEffect(() => {
        if (userAccess !== null) {
            const arrUnit = userAccess?.reduce((arr, prop) => {
                if (prop.role === "DEAN") {
                    return [
                        ...arr,
                        {unit: prop.subject}
                    ]
                }
                else if (prop.role === "DEPARTMENT") {
                    return [
                        ...arr,
                        {unit: prop.subject}
                    ]
                }
                return arr;
            }, [])
            setUnitAccess(arrUnit);
        }
    }, [userAccess]);

    useEffect(() => {
        if (unitAccess !== []) {
           const arr = [];
           let fac = [];
           let unitsIrls = [];
            unitAccess?.map((un) => {
              arr.push({id:un.unit, role: role})
            });

            if (role === "ROLE_DEAN")
            {
                arr?.map((f)=>{
                    infoUnit({idUnit: f.id}).then((uns) => {
                        fac.push(uns.title);
                        unitsIrls.push(uns['@id']);
                        loadUnits(unitsIrls)
                         .then(setListDepartment);
                })
                    setTitleUnit(fac);
                })
            }
            else if(role === "ROLE_DEPARTMENT"){
                unitAccess?.map((un) => {
                    infoUnit({idUnit: un.unit}).then((uns) => {
                        setTitleUnit(uns.title);
                        setIdUnit('/api/units/'+ uns.tnn);
                    })
                });
            }
        }
    }, [unitAccess]);

    const department = listDepartment?.map((param) => {
        return {
            value: '/api/units/' + param.tnn,
            label: param.title
        }
    })

    useEffect(() => {
        if (selectDepartment !== null) {
            setIdUnit(selectDepartment.value);
            setSurvey(null);
        } else {
            return;
        }
    }, [selectDepartment]);

    useEffect(() => {
        if (idUnit.length > 0) {
            listSurveyForCommission({unit: idUnit}).then((infoSurvey) => {
                   infoSurvey?.map((i) => {
                         loadSurveyByID({survey: i.id})
                            .then(setSurvey);
                    })
               }
            );
        }
        else{
            return;
        }
    }, [idUnit]);

    const [infoParameter, setInfoParameter] = useState(null);
    const openModal = (data) =>{
        setInfoParameter(data);
        setIsOpen(true);
    }
    const closeModal = () =>{
        setIsOpen(false);
    }
    return (
        <div>
            <ModalDescription
                show={isOpen}
                parameter={infoParameter}
                onCloseModal={closeModal}
            />
            <div>Просмотр результатов по Рейтингу кафедр 2023</div>

            {
                role === 'ROLE_DEPARTMENT' ?
                    <div>
                        <div className='m-3' style={{textAlign: "center"}}><u><b>{titleUnit}</b></u></div>
                        <table className='table table-bordered table-hover'>
                            <thead>
                            <tr>
                                <th scope="col" style={{width: 30}}>№</th>
                                <th scope="col">Наименование показателя</th>
                                <th scope="col">Эксперт</th>
                                <th scope="col" style={{width: 50}}>Оценка Эксперта</th>
                                <th scope="col" style={{width: 50}}>Оценка Комиссии</th>
                                <th scope="col" style={{width: 50}}>Ед.изм</th>
                            </tr>
                            </thead>
                            <tbody>
                            {survey !== null ?
                                survey.surveyParameters?.sort((x, y) => {
                                    x = String(x.order).split(".")
                                    y = String(y.order).split(".")
                                    return x[0] - y[0] || x[1] - y[1] || x[2] - y[2]
                                })?.map((parameter) => {

                                    let unitsTitle = '';
                                    if (parameter.units === 'percent') {
                                        unitsTitle = '%';
                                    } else if (parameter.units === 'point') {
                                        unitsTitle = 'балл';
                                    } else if (parameter.units === 'units') {
                                        unitsTitle = 'ед';
                                    } else if (parameter.units === 'rub') {
                                        unitsTitle = 'руб';
                                    } else if (parameter.units === 'usd') {
                                        unitsTitle = 'долл.США';
                                    }
                                    const calculation = parameter.resultCalculationType;

                                    const arrExpert = parameter?.surveyParameterValues?.filter((el) => el?.author === "EXPERT" || el?.author === "API");
                                    const arrCommision = parameter?.surveyParameterValues?.filter((el) => el?.author === "COMMISSION" || el?.author === "API");
                                    const val = arrCommision?.map(v => {
                                        return v.data
                                    });
                                    const vals = arrExpert?.map(v => {
                                        return v.data;
                                    });

                                    let valueUnits = '0';
                                    let valueUnitsCom = '0';
                                    if (calculation === "sum") {
                                        valueUnits = vals.reduce((a, b) => Number(a) + Number(b), 0);
                                        valueUnitsCom = val.reduce((a, b) => Number(a) + Number(b), 0);
                                    } else if (calculation === "max") {
                                        valueUnits = Math.max(...vals);
                                        valueUnitsCom = Math.max(...val);
                                    }
                                    let unit = '';
                                    const valueExpert = vals.length > 0 ? valueUnits : '0';
                                    const valueCommission = val.length > 0 ? valueUnitsCom : '';
                                    const unitName = [parameter.accesses[0].fillingUnit.title];
                                    //     arrExpert?.map(v => {
                                    //     if (v?.reason) {
                                    //         return v.reason.fillingUnit.title;
                                    //     } else {
                                    //         return '';
                                    //     }
                                    // });

                                    function onlyUnique(value, index, array) {
                                        return array.indexOf(value) === index;
                                    }
                                    unit = unitName.filter(onlyUnique).join(', ');

                                    return (
                                        <tr key={parameter.id}>
                                            <td>{parameter.order}</td>
                                            <td>{parameter.title}</td>
                                            <td>{unit}</td>
                                            <td onClick={() => {openModal(parameter)}} style={{cursor:"pointer"}}>{valueExpert}</td>
                                            <td>{valueCommission}</td>
                                            <td>{unitsTitle}</td>
                                        </tr>)
                                })
                                : <tr key='1'>
                                    <td colSpan={6}>Загрузка данных ...</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    : role === 'ROLE_DEAN' ?
                        <div>
                        <div className='mt-3'>
                                <Select options={department} placeholder={'Выберите кафедру'}
                                        value={selectDepartment} onChange={setSelectDepartment}></Select>
                            </div>
                            {selectDepartment !== null ?
                                <div className='mt-2'>
                                    <table className='table table-bordered table-hover'>
                                        <thead>
                                        <tr>
                                            <th scope="col" style={{width: 30}}>№</th>
                                            <th scope="col">Наименование показателя</th>
                                            <th scope="col">Эксперт</th>
                                            <th scope="col" style={{width: 50}}>Оценка Эксперта</th>
                                            <th scope="col" style={{width: 50}}>Оценка Комиссии</th>
                                            <th scope="col" style={{width: 50}}>Ед.изм</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { survey !== null ?
                                                survey.surveyParameters?.sort((x, y) => {
                                                    x = String(x.order).split(".")
                                                    y = String(y.order).split(".")
                                                    return x[0] - y[0] || x[1] - y[1] || x[2] - y[2]
                                                })?.map((parameter) => {
                                                    let unitsTitle = '';
                                                    if (parameter.units === 'percent') {
                                                        unitsTitle = '%';
                                                    } else if (parameter.units === 'point') {
                                                        unitsTitle = 'балл';
                                                    } else if (parameter.units === 'units') {
                                                        unitsTitle = 'ед';
                                                    } else if (parameter.units === 'rub') {
                                                        unitsTitle = 'руб';
                                                    } else if (parameter.units === 'usd') {
                                                        unitsTitle = 'долл.США';
                                                    }

                                                    const calculation = parameter.resultCalculationType;

                                                    const arrExpert = parameter?.surveyParameterValues?.filter((el) => el?.author === "EXPERT" || el?.author === "API" );
                                                    const arrCommision = parameter?.surveyParameterValues?.filter((el) => el?.author === "COMMISSION" || el?.author === "API" );
                                                    const val = arrCommision?.map(v => {
                                                         return v.data
                                                    });
                                                    const vals = arrExpert?.map(v=>{
                                                        return v.data;
                                                    });

                                                    let valueUnits = '0';
                                                    let valueUnitsCom = '0';
                                                    if (calculation === "sum"){
                                                        valueUnits = vals.reduce((a,b)=> Number(a) + Number(b), 0);
                                                        valueUnitsCom = val.reduce((a,b)=> Number(a) + Number(b), 0);
                                                    }
                                                    else if(calculation === "max"){
                                                        valueUnits = Math.max(...vals);
                                                        valueUnitsCom = Math.max(...val);
                                                    }
                                                    let unit = '';
                                                    const valueExpert = vals.length > 0 ? valueUnits :  '0';
                                                    const valueCommission = val.length > 0 ? valueUnitsCom : '';
                                                    const unitName = [parameter.accesses[0].fillingUnit.title];
                                                    //     arrExpert?.map(v => {
                                                    //     if(v?.reason){
                                                    //         return v.reason.fillingUnit.title;
                                                    //     }
                                                    //     else if (v?.reason === undefined){
                                                    //         return '';
                                                    //     }
                                                    // });
                                                    function onlyUnique(value, index, array) {
                                                        return array.indexOf(value) === index;
                                                    }
                                                    unit = unitName.filter(onlyUnique).join(', ');

                                                    return (<tr key={parameter.id} >
                                                        <td>{parameter.order}</td>
                                                        <td>{parameter.title}</td>
                                                        <td>{unit}</td>
                                                        <td onClick={()=>{openModal(parameter)}} style={{cursor:"pointer"}}>{valueExpert}</td>
                                                        <td>{valueCommission}</td>
                                                        <td>{unitsTitle}</td>
                                                    </tr>)
                                                })
                                                : <tr key='1'>
                                                    <td colSpan='6'>Загрузка данных...</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : null
                            }
                        </div>
                        : null
            }
        </div>
    )
}