import React, {useEffect, useState} from "react";
import EditIco from "../Button/EditIco";
import ModalPage from "../Modal/ModalPage";
import DeleteIco from "../Button/DeleteIco";
import {updateParameter} from "../../API/parameterApi";

export default function CatalogParameter({parameter, number, onDeleteHandler}) {
    const [spisParam, setSpisParam] = useState(parameter);
    const [shows, setShows] = useState(false);

    const handleCloseModal = () => {
        setShows(false)
    }
    const handleUpdateShow = () => {
        setShows(true);
    }
    const handleUpdateModal = (data) => {
          updateParameter({parameter: data}).then(r => {
             setSpisParam(r)
            }
        );
          setShows(false)
    };
    const deleteThisParameter = (data) => {
        onDeleteHandler(data);
    }
    let groupA = spisParam.surveyType;
    let ModalEditPage = null;

if (shows) {
    ModalEditPage = (
        <ModalPage
            isOpen={shows}
            title={'Изменить показатель'}
            idParam={spisParam.id}
            nameText={spisParam.title}
            metodText={spisParam.description}
            maxVal={parseInt(spisParam.maxDataValue)}
            group={groupA}
            chapterUrl = {spisParam.chapter}
            units = {spisParam.units}
            selFunction = {spisParam.resultCalculationType}
            onOkHandler={handleUpdateModal}
            onCancelHandler={handleCloseModal}/>
    );
}
    return (
        <tr>
            {ModalEditPage}
            <td scope="row">{number}</td>
            <td>{spisParam.title}</td>
            <td>{spisParam.description}</td>
            <td>
                <button className="btn" onClick={() => handleUpdateShow(spisParam.id)} title={'Изменить показатель'}>
                    <EditIco/></button>

                <button className="btn" onClick={() => deleteThisParameter(spisParam)} title={'Удалить показатель'}>
                    <DeleteIco/></button>
            </td>
        </tr>
    )
}