import React, {useEffect, useId, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ModalDialog from "../Common/Modal";
import {
	deleteParameterValue,
	ListChapter,
	activeChapter,
	loadIDSurveyParameterValue,
	saveParametersValues,
	updateParametersValues
} from "../../API/parameterApi";

import ParamCommission from "./ParamCommission";
import ParamsValueCommission from "./ParamsValueCommission";
import {putParametersCommission} from "../../Store/ParametrsSlicerCommission";
import {putParameters} from "../../Store/ParametrsSlicer";


export default function InterviewForCommission({
												   itemFaculty,
												   nameFaculty,
												   listParamets,
												   page,
												   total,
												   handler,
												   loadParameters
											   }) {
	const [paramListValue, paramListValueChanger] = useState({data: [], update: false});
	const dispatch = useDispatch()

	useEffect(() => {
		//	dispatch(putParametersCommission(listParamets));
		dispatch(putParameters(listParamets));
		doListOfSum()
	}, [listParamets])

	const [show, showChanger] = useState(null);

	const itemUnitID = useSelector(state => state.units);
	const {user}     = useSelector(state => state.auth);
	const [flag,flagChanger] = useState();
	const userTN = Number(user?.tnn);

	const [description, changeDescription] = useState({id: 1, val: '', name: ''});
	const [description2, changeDescription2] = useState({id: 1, val: '', name: ''});
	const [activeChapterSt, activeChapterStChanger] = useState('');
	const [sumArr, sumArrChanger] = useState([])
	let sumARR = [{id: 0, sumComis: 0, sumExpert: 0}]
	let perStatus = false;
	let result = 0;
	let elFind;

	const paramsRef = useRef();

	useEffect(() => {
		//newArr()
		if (paramListValue.update) {
			paramListValueChanger(prev => ({
				...prev, update: false
			}))
			//	saveParam();

			//	dispatch(putParametersCommission(paramListValue.data));
			dispatch(putParameters(paramListValue.data));
		}

	}, [paramListValue])

	const doListOfSum = () => {
		listParamets?.map((l, index) => {
			let sum = 0
			let sum2 = 0
			let flag = 0
			if (l.surveyParameterValues.length > 0) {
				l.surveyParameterValues.forEach((el) => {
					if (el.author.includes("COMMISSION")) {
						sum2 = sum2 + Number(el.data)
						flag = 1
						//dispatch(putSum(sum2))
					} else {
						sum = sum + Number(el.data)
					}
				})

			}

			if (flag === 1) {
				sumARR.push({id: l.id, sumComis: sum2, sumExpert: sum})
			} else {
				sumARR.push({id: l.id, sumComis: sum, sumExpert: sum})
			}
		})
		sumArrChanger(sumARR)
	}

	const newArr = (arr) => {
		let descr1 = [];
		const newListParameters = listParamets.map((item) => {
			descr1 = [];
			let idAccess = '';

			const titleChapter = arr?.find(el => el.id === Number(item?.chapter.split('/')[3]))?.title;
			return {
				id: item?.id,
				title: item?.title,
				units: item?.units,
				maxDataValue: item?.maxDataValue,
				minDataValue: item?.minDataValue,

				surveyParameterValues: item?.surveyParameterValues,
				metodVerif: item?.description,
				chapterID: item?.chapter.split('/')[3],
				chapterTitle: titleChapter,
				order: item?.order
			}
		})
		const result = newListParameters.sort((x, y) => {
			x = String(x.order).split(".")
			y = String(y.order).split(".")
			return x[0] - y[0] || x[1] - y[1] || x[2] - y[2]
		})
		//const result = newListParameters.sort(compareOrder)

		paramListValueChanger(prev => ({...prev, data: result}))


	}


	useEffect(() => {

		ListChapter().then(data => {
			const arrNew = data;
			newArr(arrNew);

		}).catch()


		activeChapter({userTN}).then(data => {
			const newArr = data.map((i) => {
				return i.subject.split('/')[3]
			})
			activeChapterStChanger(newArr)
		}).catch()


	}, [])


	const addId = (result, id) => {
		const editlement = paramListValue.data.find(i => i.id === Number(id));

		if (editlement)

			paramListValueChanger(prev => {
				return ({
					update: false, data:
						prev.data.map((item) => {
							if (item.id === Number(id)) {

								const itemNew = {...editlement}
								itemNew.idForEditSave = result;
								return itemNew
							}
							return item
						})
				})
			})

	}


	const handlerChangerEdit = (l, flag) => {
		const arr = []
		flagChanger(flag);
		let idSurveyParameterValue = '';
		const parameter = l.id;
		const title = l.title;
		loadIDSurveyParameterValue({parameter})
			.then(data => {
				let nameUnit = []
				let nameUnitFull = ""
				const newARR = data?.map((i, index) => {
					try {
						if (i?.description) {
							nameUnit = JSON.parse(i?.description)
							if (typeof (nameUnit) === NaN) {
								nameUnitFull = i?.description
							}
						}
						for (let i = 0; i < nameUnit?.length; i++) {
							if (nameUnit[i].name !== "") {
								nameUnitFull = nameUnit[i].val + nameUnit[i].name
							}
						}
					} catch {
						if (i?.description) {
							nameUnitFull = i?.description
						}
					}
					return {
						id: (new Date()).getTime() + index,
						val: i?.data,
						name: nameUnitFull,
						idParamValue: i?.author === "COMMISSION" ? i?.id : 0,
						author: i?.author
					}
				})
				// console.log(parameter)
				// console.log(newARR)
				const newARRCommis = newARR.filter((el) => el?.author === "COMMISSION")
				const newARRExpert = newARR.filter((el) => el?.author === "EXPERT")

				if (flag === 1) {
					changeDescription(newARRExpert)
				} else {

					if (newARRCommis.length > 0) {
						changeDescription(newARRCommis)
					} else if (newARRExpert.length > 0) {
						changeDescription(newARRExpert)
					} else {
						changeDescription(newARR)
					}
				}
					showChanger({id: parameter, title: title, idEl: l})

			})
			.catch()

	}

	const handlerChangerDelete = (idItem) => {
		const result = deleteParameterValue(idItem);
		if (result) {
			loadParameters();
		}
	}

	const onClose = () => {
		if (window.confirm("Вы хотите закрыть окно?") === true) {
			showChanger(null)
		}
	}
	const onSave = (params, id, sum, idEl, idForDelete, paramsDelOne) => {

		const newParams = params?.map((item, index) => {
			item.id = index + 1
			return item
		})

		if (newParams?.length > 0) {
			newParams?.map((l) => {

				const el = {
					data: l.val,
					author: "COMMISSION",
					// reason: "/api/survey_parameter_accesses/" + l.idAccess,
					surveyParameter: "/api/survey_parameters/" + id,
					description: l?.name,
					status: "FOR_CHECK",

				}
				const el2 = {
					data: l?.val,
					description: l?.name,

				}

				if (l?.idParamValue && l?.author === "COMMISSION") {
					const idItem = l?.idForEditSave;
					updateParametersValues({parameter: el2, id: l?.idParamValue}).then(data => {
					}).catch()
				} else if (l?.idParamValue === 0 || l?.author === "EXPERT") {
					saveParametersValues({parameter: el}).then(data => {
						const result = data;
					}).catch()
				}

			})
		}
		//удаляем ненужные элементы
		if (idForDelete?.length > 0 && idForDelete[0] !== 0) {
			idForDelete?.map((el) => {
				deleteParameterValue(el).then(data => {
					const result = data
				}).catch()
			})
		}
		//если удалили все значения, то оставялем одно значение со значением 0
		if (paramsDelOne?.length > 0) {

			if (paramsDelOne[0] === 0) {

				const el4 = {
					data: "0",
					author: "COMMISSION",
					// reason: "/api/survey_parameter_accesses/" + l.idAccess,
					surveyParameter: "/api/survey_parameters/" + id,
					description: "",
					status: "FOR_CHECK",

				}
				saveParametersValues({parameter: el4}).then(data => {
					const result = data;
				}).catch()
			} else {
				const el3 = {
					data: "0",
					description: ""
				}
				updateParametersValues({parameter: el3, id: paramsDelOne[0]}).then(data => {
				}).catch()
			}
		}
		const sumExp = sumArr?.filter((el) => el.id == id)[0]["sumExpert"];
		const newARR = sumArr?.filter((el) => el.id !== id);
		newARR.push({id: id, sumComis: sum, sumExpert: sumExp})
		sumArrChanger(newARR)
		showChanger(null)
	}

	let styleForButton = ''
	let chapterTitleNow = '';

	console.log(description)
	return (
		<div>
			<div className="table-responsive-sm">
				<table className="table table-hover ">
					<thead className="table-secondary">
					<tr>
						<th scope="col">Вид</th>
						<th scope="col">№</th>
						<th scope="col">Показатель</th>
						<th scope="col">Единица измерения</th>
						<th scope="col">Эксперты</th>
						<th scope="col">Комиссия</th>
					</tr>
					</thead>
					<tbody>

					{/*//{paramListValue?.filter((item, index) => index < 4 * page && index >= 4 * (page - 1)).map((l, index) => (*/}
					{paramListValue?.data?.map((l, index) => {

						styleForButton = {"display": 'block'}
						return (<ParamCommission
							key={l.id}
							l={l}
							handlerChangerEdit={handlerChangerEdit}
							elFind={elFind}
							styleForButton={styleForButton}
							userTN={userTN}
							sum={sumArr?.filter((el) => el.id == l.id)[0]["sumExpert"]}
							sumCommis={sumArr?.filter((el) => el.id == l.id)[0]["sumComis"]}
							chapterAll={activeChapterSt}
						/>)
					})
					}
					</tbody>
				</table>


				<ModalDialog isOpen={show} title={''} handleClose={() => onClose()}
							 handleSave={() => paramsRef?.current?.save()} flag = {flag}>
					<ParamsValueCommission l={description}
										   id={show?.id}
										   title={show?.title}
										   idEl={show?.idEl}
										   ref={paramsRef}
										   handleSave={onSave}
										   flag = {flag}
						// onEditableData={(e) => onEditableData(e, show?.id, null, null, null)}
					/>
				</ModalDialog>

			</div>
		</div>
	);
}







