import ApiSecurity from "./ApiSecurity";

async function saveGroup({group}){
    return await (await fetch(
        `api/survey_types.json`,
        {
            method: 'POST',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(group)
        })).json()
}
async function updateGroup({group}){
    return await (await fetch(
        `api/survey_types/${group.id}.json`,
        {
            method: 'PUT',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(group)
        })).json()
}
async function deleteGroup({idGroup}){
    await fetch(
        `/api/survey_types/${idGroup}`,
        {
            method: 'DELETE',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
        });
}

async function AllGroup(){
    const result = await (await fetch(`/api/parameter_groups`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return result['hydra:member'];
}

async function idGroups({searchTitle}){
    const results = await (await fetch(`api/parameter_groups?searchTag=${searchTitle}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return results['hydra:member'];
}

export {
    saveGroup,
    AllGroup,
    idGroups,
    updateGroup,
    deleteGroup
}