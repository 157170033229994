//добавление доступа для параметра
import ApiSecurity from "./ApiSecurity";

async function saveAccess({data}){
    return await (await fetch(
        `/api/survey_parameter_accesses.json`,
        {
            method: 'POST',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(data)
        })).json()
}
//редактирование доступа к параметру
async function editAccess({idAccess}){
    return await (await fetch(
        `/api/survey_parameter_accesses/${idAccess.id}.json`,
        {
            method: 'PUT',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(idAccess)
        })).json()
}
// удаление доступа у параметра
async function deleteAccess({idAccess}){
    await fetch(
        `/api/survey_parameter_accesses/${idAccess}`,
        {
            method: 'DELETE',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
        });
}
//получение данных о параметре и предоставленном доступе
// idParameter = "/api/survey_parameters/id"
async function searchPosition({idParameter})
{
    const position = await (await fetch(`${idParameter}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return position;
}
async function searchAccess({idAccess})
{
    const access = await (await fetch(`${idAccess}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    const unit = access.fillingUnit;

    let nameUnit = [];
    if(unit !== null){
        nameUnit = await (await fetch(`${unit}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    }
    return nameUnit;
}
async function loadPosition({page= 1}){
    return await (await fetch(`/api/positions?page=${page}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllPosition() {
    let page=1;
    const firstPage = await loadPosition({page:page++});
    const positionCount = firstPage['hydra:totalItems'];
    let position = firstPage['hydra:member'];

    while(position.count < positionCount){
        position = [...position, (await loadPosition({page: page++}))['hydra:member']]
    }
    return position;

}
//сохранение доступа для сотрудника
async function saveAccessUser({data}){

   return await (await fetch(
        `/api/user_accesses.json`,
        {
            method: 'POST',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(data)
        })).json()
}

async function deleteUserAccess({idUserPosition}){
    await fetch(
        `/api/user_accesses/${idUserPosition}`,
        {
            method: 'DELETE',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
        });
}

async function loadUserAccessPage({page}){
    return await (await fetch(`/api/user_accesses?page=${page}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllUserAccess() {
    let page = 1;
    const firstPage = await loadUserAccessPage({page:page++});
    const userAccessCount = firstPage['hydra:totalItems'];
    let userAccess = firstPage['hydra:member'];

    while(userAccess.count < userAccessCount){
        userAccess = [...userAccess, (await loadUserAccessPage({page: page++}))['hydra:member']]
    }

    return userAccess;
}
async function loadUserAccessRole({role}){
    return (await (await fetch(`api/user_accesses?role=${role}`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member'];
}
//Получение данных для редактирования прав доступа сотрудника по конкретному userPosition
async function infoUserAccessID({idAccess}){
    return await (await fetch(`/api/user_accesses/${idAccess}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}
async function infoUserAccessTN({user}){
    return (await (await fetch(`/api/user_accesses?user=${user}`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member'];
}


export {
    saveAccess,
    editAccess,
    deleteAccess,
    searchPosition,
    searchAccess,
    loadAllPosition,
    loadPosition,
    saveAccessUser,
    deleteUserAccess,
    loadAllUserAccess,
    infoUserAccessID,
    infoUserAccessTN,
    loadUserAccessRole

}
