import {createSlice} from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',

    initialState: {
        user: null,
		access:''
    },

    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;

        },
		setAccess:(state,action) =>{
			state.access = action.payload;
		}
    }
});

export const {setUser,setAccess} = authSlice.actions

export default authSlice.reducer