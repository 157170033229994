import React, {useState} from "react";
import {get} from "../../API/api";
import debounce from 'lodash.debounce';
import AsyncSelect from "react-select/async";


const loadingResult = async debounceIrls =>{
    const userDebounce = await get({irl: `/api/users?fullName=${debounceIrls}`});
    const user = userDebounce['hydra:member'];

    const result = [];
    for (const res of user){
        const arr = res.userUnitPositions;
        if (arr !== []){
            const nD = arr[0].positionTitle;
            const unit = arr[0].unit.title;
            const fullName = res.fullName.toLowerCase().replace(/(^|\s)\S/g, function(a) {return a.toUpperCase()});
            const userRes = {
                label:`${fullName} (${nD}, ${unit})`,
                value:res.tnn,
                unitTnn:arr[0].unit.tnn,
                unitName:unit
            }
            result.push(userRes);
        }
    }
    return result;
}

const loadDebounced = (query, callback) =>{
    loadingResult(query).then(resp => {callback(resp);});
}
const loadDebounce = debounce(loadDebounced, 500);
export default function SearchUser({fio, onSave}){
    const [inputValue, setInputValue] = useState( '');

    const handleInputChange = (value) => {
        setInputValue(value);
        onSave(value.value, {id:value.unitTnn, name:value.unitName});
    }

return (
    <AsyncSelect
        value={inputValue}
        placeholder="Введите не менее 3-х символов ФИО"
        onChange={handleInputChange}
        loadOptions={loadDebounce}
    />
    )
}