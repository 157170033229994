import {createSlice} from "@reduxjs/toolkit";

export const sumCommission = createSlice({
	name: 'sumCommission',

	initialState: {
		sumCommis: 0,
	},

	reducers: {
		putSum: (state, action) => {
			state.sumCommis = action.payload;
		},
	}
})
export const {putSum} = sumCommission.actions
export default sumCommission.reducer