import React, {useEffect, useId, useMemo, useRef, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {putParameters} from "../../Store/ParametrsSlicer";

import ParamsValue from "./ParamsValue";
import EditIco from "../Button/EditIco";
import ModalDialog from "../Common/Modal";
import {deleteParameterValue, ListChapter, saveParametersValues, updateParametersValues} from "../../API/parameterApi";
import Param from "./Param";
import Info from "../Button/Info";
import {checkUserAccess} from "../../API/userDataAPI";
import {setAccess} from "../../Store/AuthSlice";


export default function InterviewForRaiting({
												itemFaculty,
												nameFaculty,
												listParamets,
												page,
												total,
												handler,
												loadParameters
											}) {
	const [paramListValue, paramListValueChanger] = useState({data: [], update: false});
	const [show, showChanger] = useState(null);
//	const itemUnitID = useSelector(state => state.units);
	const [description, changeDescription] = useState({id: 1, val: '', name: ''});
	const [accessRight,accessRightChanger] =useState('')
	const {user} = useSelector(state => state.auth);

	const dispatch = useDispatch()

	const userTN = Number(user?.tnn)
	const paramsRef = useRef();

	let perStatus = false;
	//let result = 0;
	let elFind;

	const findAccessRight= () => {
		checkUserAccess({userTN}).then(data=>{
			const userAcs = data[0]?.accessRight;
			accessRightChanger(userAcs)
			dispatch(setAccess(userAcs))
		}).catch()
	}
	useEffect(() => {
		findAccessRight()
	}, [])
	useEffect(() => {
		//newArr()
		if (paramListValue.update) {
			paramListValueChanger(prev => ({
				...prev, update: false
			}))
			saveParam();
			dispatch(putParameters(paramListValue.data));
		}

	}, [paramListValue])

	useEffect(() => {
		ListChapter().then(data=>{
			const arrNew = data;
			newArr(arrNew);
		}).catch()
		dispatch(putParameters(listParamets));
	}, [listParamets])

	const newArr = (arr) => {
		let descr1 = [];
		const newListParameters = listParamets.map((item) => {
			descr1 = [];
			let idAccess = '';
			idAccess = item?.accesses?.find((item1) => Number(item1.responsibleUser?.tnn) === (userTN))?.id;
			const titleChapter = arr?.find(el => el.id===Number(item?.chapter.split('/')[3]))?.title;
			return {
				id: item?.id,
				title: item?.title,
				units: item?.units,
				maxDataValue: item?.maxDataValue,
				minDataValue: item?.minDataValue,
				idAccess: idAccess,
				surveyParameterValues: item?.surveyParameterValues,
				metodVerif: item?.description,
				chapterID:item?.chapter.split('/')[3],
				chapterTitle:titleChapter,
				order:item?.order
			}
		})
		const result =newListParameters.sort((x, y) => {
			x =String(x.order).split(".")
			y = String(y.order).split(".")
			return x[0] - y[0] || x[1] - y[1] || x[2]-y[2]
		})

		paramListValueChanger(prev => ({...prev, data: result}))
	}

	const addId = (result, id) => {
		const editlement = paramListValue.data.find(i => i.id === Number(id));

		if (editlement)

			paramListValueChanger(prev => {
				return ({
					update: false, data:
						prev.data.map((item) => {
							if (item.id === Number(id)) {

								const itemNew = {...editlement}
								itemNew.idForEditSave = result;
								return itemNew
							}
							return item
						})
				})
			})

	}

	const saveParam = () => {
		if (paramListValue?.data.length > 0) {
			paramListValue?.data.map((l) => {
				if (l?.dataNow !== undefined) {
					const el = {
						data: l.dataNow.toString(),
						author: "EXPERT",
						reason: "/api/survey_parameter_accesses/" + l.idAccess,
						surveyParameter: "/api/survey_parameters/" + l.id,
						description: l.descriptionNew,
						status: "FOR_CHECK",
					}
					const el2 = {
						author: "EXPERT",
						data: l.dataNow.toString(),
						description: l.descriptionNew,
					}
					if (l?.idForEditSave) {
						const idItem = l?.idForEditSave;
						updateParametersValues({parameter: el2, id: l?.idForEditSave}).then(data => {
						})

					} else if (l?.idForEdit === null) {
						saveParametersValues({parameter: el}).then(data => {
								const result = data;
								addId(result, l.id)
							}
						)
					} else {
						const idItem = l?.idForEdit;
						updateParametersValues({parameter: el2, id: idItem}).then(data => {
						})
					}
				}
			})
		}
	}
//	const onEditableData = (idEl, e, edit, descVal, idForDescr, sum) => {
	const onEditableData = (params, idForDescr, sum, idEl) => {
		let editableElement = null;
		let idParam = null;
		idParam = idForDescr;
		editableElement = paramListValue?.data.find(i => i.id === Number(idEl));
		if (editableElement)
			paramListValueChanger(prev => {
				return ({
					update: true, data: prev.data.map((item) => {
						if (item.id === Number(idEl)) {
							const itemNew = {...editableElement}
							if (itemNew?.surveyParameterValues?.find(p => p.id === Number(idParam))) {
								itemNew.dataNow = sum;
								itemNew.idForEdit = idForDescr;
								itemNew.descriptionNew = JSON.stringify(params);
								return itemNew
							} else {
								itemNew.dataNow = sum;
								itemNew.idForEdit = null;
								itemNew.descriptionNew = JSON.stringify(params);
								return itemNew
							}
						}
						return item
					})
				})

			})
		dispatch(putParameters(paramListValue.data));
	}

	const handlerChangerEdit = (item, title, l) => {
		const arr = []
		let idSurveyParameterValue = '';
		changeDescription(arr)

		if (item?.descriptionNew) {
			changeDescription(JSON.parse(item?.descriptionNew))
		} else {
			if (item !== undefined) {
				if (item["@type"] === "SurveyParameterValue") {
					if (item?.description !== '') {
						changeDescription(JSON.parse(item?.description))

					} else {
						changeDescription(prevState => (
							// eslint-disable-next-line react-hooks/rules-of-hooks
							[...prevState, {id: (new Date()).getTime(), val: '', name: ''}]
						));
					}
				} else {
					changeDescription(prevState => (
						// eslint-disable-next-line react-hooks/rules-of-hooks
						[...prevState, {id: (new Date()).getTime(), val: '', name: ''}]
					));
				}
			}
		}
		if (item["@type"] === "SurveyParameterValue") {
			idSurveyParameterValue = item.id
		}else{
			const el = item.surveyParameterValues?.find((item1) => Number(item1.reason?.responsibleUser?.tnn) === userTN)
			if (el!==undefined){
				idSurveyParameterValue = el.id
			}

		}
		showChanger({id: idSurveyParameterValue, title: title, idEl: l})
	}

	const handlerChangerDelete = (idItem) => {
		const result = deleteParameterValue(idItem);
		if (result) {
			loadParameters();
		}
	}

	const onClose = () => {
		if (window.confirm("Вы хотите закрыть окно?") === true) {
			showChanger(null)
		}
	}
	const onSave = (params, id, sum, idEl) => {
		const newParams = params?.map((item, index) => {
			item.id = index + 1
			return item
		})
		onEditableData(newParams, id, sum, idEl)
		showChanger(null)
	}

	let styleForButton = ''
	let chapterTitleNow = '';
	console.log(paramListValue)
	return (
		<div>
			<div className="table-responsive-sm">
				<table className="table table-hover ">
					<thead className="table-secondary">
					<tr>
						<th scope="col">Вид</th>
						<th scope="col">№</th>
						<th scope="col">Показатель</th>
						<th scope="col">Единица измерения</th>
						<th scope="col">{nameFaculty}</th>
					</tr>
					</thead>
					<tbody>

					{paramListValue?.data?.map((l, index) => {
						perStatus = false
					//	debugger
						if (userTN) {
							elFind = l.surveyParameterValues?.find((item) => Number(item.reason?.responsibleUser?.tnn) === userTN)
						}
						const idFind = elFind?.id;

						const st = elFind?.status;

						if (l.surveyParameterValues?.length === 0) {
							perStatus = true;
						} else if (idFind) {
							if (st === "FOR_CHECK") {
								perStatus = true;
							} else perStatus = false;
						} else {
							perStatus = true;
						}

						if (!perStatus)
							styleForButton = {"display": "none"}
						else
							styleForButton = {"display": "block"}

						return(<Param key = {l.id}
									  l= {l}
									  handlerChangerEdit = {handlerChangerEdit}
									  elFind = {elFind}
									  styleForButton = {styleForButton} 
									  userTN = {userTN}
									  accessRight = {accessRight}/>)
					})
					}
					</tbody>
				</table>

				<ModalDialog isOpen={show} title={''} handleClose={() => onClose()}
							 handleSave={() => paramsRef?.current?.save()}>

					<ParamsValue l={description}
								 id={show?.id}
								 title={show?.title}
								 idEl={show?.idEl}
								 ref={paramsRef}
								 handleSave={onSave}
					/>
				</ModalDialog>

			</div>
		</div>
	);
}







