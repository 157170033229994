import React from "react";
import {Container} from "react-bootstrap";
import ListViewForDepartment from "../Components/ProcessingResultsForSurvey/ListViewForDepartment";

export default function ViewDepartment() {

    return (
        <Container className="content container-fluid ">
            <ListViewForDepartment/>
        </Container>
    )
}