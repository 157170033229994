import React, {useState} from "react";
import {Container, Nav, Tab, Row, Col, TabContent, TabPane} from "react-bootstrap";
import ParameterTab from "../Components/ParameterTab/Parameter";
import Header from "../Components/Header";
import NewSurvey from "../Components/SurveyPage/NewSurvey";
import ArhivePage from "../Components/ArhiveSurvey/ArhivePage";
import ProcessingResults from "../Components/ProcessingResultsForSurvey/ProcessingResults";
import AccessUnit from "../Components/ProvidingAccess/AccessUnit";
import AccessUsers from "../Components/ProvidingAccess/AccessUsers";
import SelectParameter from "../Components/ParameterTab/SelectParameter";

export default function AdminPage() {

  const [key, setKey] = useState('Parameter');

  const listPage = (key) => {
    switch (key) {
      case 'Parameter':
        return <SelectParameter/>;
      case 'NewSurvey':
        return <NewSurvey/>;
      case 'LookSurvey':
        return <ProcessingResults/>;
      case 'HistorySurvey':
        return <ArhivePage/>;
      case 'ServiceAccess':
        return <AccessUnit/>;
      case 'UserAccess':
        return <AccessUsers/>;

      default:
        return <ParameterTab/>;
    }
  };

  return (
    <Container className="content container-fluid ">
      <Tab.Container id="controlled-tab-example"
                     activeKey={key}
                     onSelect={(k) => setKey(k)}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column mt-10 ">
              <Nav.Item>
                <Nav.Link eventKey='Parameter'>Показатели для опроса</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='NewSurvey'>Создание/редактирование опросов</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='LookSurvey'>Опубликованные опросы</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='HistorySurvey'>Архив опросов</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='ServiceAccess'>Предоставление доступа к заполнению
                  показателей</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='UserAccess'>Назначение ролей пользователю</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <TabContent className="mt-1">
              <TabPane eventKey={key}>
                {listPage(key)}
              </TabPane>

            </TabContent>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  )
}