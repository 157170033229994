import ApiSecurity from "./ApiSecurity";

async function loadFaculty({page=1,isFaculty=true,idSurvey}){
	const result = await (await fetch(`api/units?page=1&type=faculty`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return result['hydra:member'];
}

async function loadCafedry({page = 1, idFaculty}) {
	const result = await (await fetch(`/api/units?page=${page}&owner=api/units/${idFaculty}&type=department`,{headers: await ApiSecurity.getAuthHeaders()})).json();
	return result['hydra:member'];
}

 async function resultParameter(id){
	const res = await (await fetch(`api/surveys/${id}`,{headers: await ApiSecurity.getAuthHeaders()})).json()
 }

async function checkFaculty({page = 1, idSurvey}) {
	const result = await (await fetch(`api/surveys?page=${page}&status=active&parent=api/surveys/${idSurvey}`,{headers: await ApiSecurity.getAuthHeaders()})).json();
	const arrResult = result['hydra:member'];
	const newArrResult = arrResult.map((i) => {
		return {id:i.id,Unit:i.subjectUnit.tnn,subjectUnit:i.subjectUnit.title}
	})

	// if (newArrResult?.length>0){
	// 	const i = await resultParameter(newArrResult);
	// }

	return newArrResult
}

export {
	loadFaculty,
	loadCafedry,
	checkFaculty,
	resultParameter,
}