import React, {useState} from "react";

export default function Period({type, period, onCheckPeriod}) {
    let listPeriod = [];
    if (type === 'm') {
        listPeriod = [
            {id: 1, title: "Январь"},
            {id: 2, title: "Февраль"},
            {id: 3, title: "Март"},
            {id: 4, title: "Апрель"},
            {id: 5, title: "Май"},
            {id: 6, title: "Июнь"},
            {id: 7, title: "Июль"},
            {id: 8, title: "Август"},
            {id: 9, title: "Сентябрь"},
            {id: 10, title: "Октябрь"},
            {id: 11, title: "Ноябрь"},
            {id: 12, title: "Декабрь"}
        ]
    } else if (type === 'q') {
        listPeriod = [
            {id: 1, title: "1 квартал"},
            {id: 2, title: "2 квартал"},
            {id: 3, title: "3 квартал"},
            {id: 4, title: "4 квартал"}
        ];

    } else if (type === 'a') {
        listPeriod = [
            {id: 1, title: "1 полугодие"},
            {id: 2, title: "2 полугодие"},

        ];
    }
    const selectList = listPeriod?.map((f, key) => {
            return <option key={key} value={f.id}>{f.title}</option>
        }
    )
    return (
        <select style={{width:120, height:30}} onChange={e => onCheckPeriod((e.target.value))} value={period}>
            {selectList}
        </select>

    )
}