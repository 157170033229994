import React from "react";
import {
    Box,
    FooterContainer,
    Heading,
} from "./FooterStyle";

const Footer = () => {
    return (
        <Box>
            <h5
                style={{
                    fontSize: "1rem",
                    color: "#504f4f",
                    textAlign: "center",
                    marginTop: "5px",
                }}
            >
               @Рейтинг кафедр 2023
            </h5>
            <FooterContainer>
                <Heading><u>Информационная и техническая поддержка:</u>
                    <br/> Информационно-аналитический центр
                    <br/> +375 (152) 73-19-72
                    <br/>г. Гродно, ул. Ожешко, 22, ауд.120
                </Heading>
            </FooterContainer>
        </Box>
    );
};
export default Footer;