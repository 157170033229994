class Storage {
  constructor(identity) {
    // console.log(`Create storage for ${identity}`)
    this._identity = identity;
  };

  get() {
    // console.log(`Get from storage ${this._identity} => ${localStorage.getItem(this._identity, null)}`)
    return localStorage.getItem(this._identity, null);
  }

  set(value) {
    // console.log(`Set in storage ${this._identity} => ${value}`)
    localStorage.setItem(this._identity, value);
  }

  clear() {
    localStorage.removeItem(this._identity);
  }
}

export default Storage;