import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Button from "react-bootstrap/Button";
import {loadActiveSurvey} from "../../API/surveyApi";
import {get} from "../../API/api";
import spinerLoading from "../spiner";
import  "../../CSS/TableStyle.css";

// import {DataGrid} from '@mui/x-data-grid';
// const columns = [
//   {field: 'id', headerName: 'ID', width: 70},
//   {field: 'firstName', headerName: 'First name', width: 130},
//   {field: 'lastName', headerName: 'Last name', width: 130},
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];
//
// const rows = [
//   {id: 1, lastName: 'Snow', firstName: 'Jon', age: 35},
//   {id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42},
//   {id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45},
//   {id: 4, lastName: 'Stark', firstName: 'Arya', age: 16},
//   {id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null},
//   {id: 6, lastName: 'Melisandre', firstName: null, age: 150},
//   {id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44},
//   {id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36},
//   {id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65},
// ];

const loadSurveys = async surveyIrls => {
  const surveys = [];
  for (const irl of surveyIrls) {
    let survey = get({irl});
    surveys.push(survey);
  }
  return await Promise.all(surveys);
  // return surveys;
}

const loadUnits = async unitsIrls => {
  const ids = [];

  for (const irl of unitsIrls) {
    const id = irl['@id'].replace('/api/units/', 'tnn[]=');
    ids.push(id);
  }
  const url = '/api/units?' + ids.join('&');

  return (await get({irl: url}))['hydra:member'];
}

export default function ModalReadFile({isOpen, title, id, onCloseFile}) {
  const sendClose = () => {
    setChildSurveys(null);
    setUnits(null);
    onCloseFile()
  };

  const [childSurveys, setChildSurveys] = useState(null);
  const [units, setUnits] = useState(null);
  const [loading, setLoading] = useState(null);
  const [table, setTable] = useState(null);

  useEffect(() => {
    if (isOpen === false || units !== null) {
      return;
    }

    loadActiveSurvey({idSurvey: id})
      .then((dataSurveys) => {
        let irls = [];
        let unitsIrls = [];
        for (let survey of dataSurveys) {
          if (childSurveys === null) {
            irls.push(survey['@id']);
          }
          unitsIrls.push(survey.subjectUnit);
        }

        if (irls) {
          loadSurveys(irls).then(setChildSurveys);
        }
        loadUnits(unitsIrls).then(setUnits);
      })


    setLoading(true);
  }, [isOpen]);

  // useEffect(() => {
  //   if (units === null || childSurveys === null) {
  //     return;
  //   }
  //
  //
  //
  // }, [childSurveys, units]);

  useEffect(() => {
    if (units === null|| childSurveys === null) {
      return;
    }

    const parameters = new Set();
    const columns = [];

    const expertColumn = {
      title: "Ответственные",
      values: [],
    };

    const setParameters = (unit, params) => {
      const values = [];

      for (const param of params) {
       // console.log(param);
        const rowTitle = param.order;
        const arrExpert = param?.surveyParameterValues?.filter((el) => el?.author === "EXPERT" || el?.author === "API" );
        const vals = arrExpert?.map(v => {
            return v.data
        });
        //добавить вывод показателя (в const value) в зависимости от указанной опции у показателя которое хранится
        // в param.resultCalculationType
        const calcul = param.resultCalculationType;
        let valueUnits = '0';
        if (calcul === "sum"){
          valueUnits = vals.reduce((a,b)=> Number(a) + Number(b), 0);
        }
        else if(calcul === "max"){
          valueUnits = Math.max(...vals);
        }
        const value = vals.length > 0 ? valueUnits : '';
        const stat = param.surveyParameterValues.map(v => v.status);
        const status = stat.length > 0 ? stat[0] : '';
        values.push({rowTitle, value, status});
        parameters.add(rowTitle);

        const { accesses: surveyParameterPositions } = param;
        const experts = [];

        if (surveyParameterPositions) {
          for (const spp of surveyParameterPositions) {
            const sppUnit = spp.fillingUnit;
            const sppUnitTitle = sppUnit.title;
            experts.push(sppUnitTitle);
          }
        }

        const expertsValue = (
          <div>
            {
              experts.map((expert, expertKey) => <div className="mb-3" key={expertKey}>{expert}</div>)
            }
          </div>
        );

        const isExpertExists = expertColumn.values
          .map(v => v.rowTitle)
          .includes(rowTitle);


        if (!isExpertExists) {
          expertColumn.values.push({rowTitle, value: expertsValue});
        }

      }
      //console.log(values);
      const column = {
        title: unit?.title,
        values: values,
      }

      columns.push(column);
    }

    for (const survey of childSurveys) {
      const surveysParameters = survey.surveyParameters;
      const unit = survey?.subjectUnit
      setParameters(unit, surveysParameters)
    }

    const table = {
      parameters,
      columns,

      //getRows: () => Array.from(parameters),
    //  getColumns: () => table.columns.map(c => c.title),
       getRows: () =>table.columns.map(c => c.title),
       getColumns: () => Array.from(parameters),
          getValue: (row, column) => {
            for(const c of table.columns){
              if(c.title === row){
                for(const i of c.values){
                  if(i.rowTitle === column){
                    return i.value;
                  }
                }
              }
            }
        // for (const c of table.columns) {
        //  // console.log(c.values)
        //   if (c.values.rowTitle === column) {
        //
        //     for (const i of c.values) {
        //       if (row === i.rowTitle) {
        //         //console.log(i);
        //         return i.value;
        //       }
        //     }
        //   }
        // }
        return null;
      }
    }
    setTable(table);
  }, [childSurveys, units]);

  const tableElement = (table != null)
    ? (
      <table className='tableModal'>
        <thead>
        <tr>
          {/*{[*/}
          {/*    'Кафедры',*/}
          {/*    ...table.getColumns(),*/}
          {/*].map()}*/}
          {
            [
              'Показатели',
              ...table.getColumns(),
            ].map((c, id) => <th key={id}>{c}</th>)
          }
        </tr>
        </thead>
        <tbody>
        {
          table.getRows()
            .map((row, rid) => {
              const columns = table.getColumns();
              const items = columns
                .map(c => table.getValue(row, c))
                .map((tableItem, vid) =>

                  //вывод значений
                    <td key={`${rid}:${vid}`}>{tableItem}</td>);

              return [
                <td key={`start-${rid}`}>{row}</td>,
                ...items,
              ]

            })
            .map((r, rid) => <tr key={rid}>{r}</tr>)
        }
        </tbody>
      </table>

    )
    : null;

  return (
    <Modal dialogClassName="modal-90w"
           aria-labelledby="contained-modal-title-vcenter"
           centered show={isOpen} onHide={sendClose}
          >
      <Modal.Header closeButton><HeadModal title={title}/></Modal.Header>
      <Modal.Body>
        <div role="region" aria-labelledby="caption" tabIndex="0">
        {
            tableElement ?? spinerLoading()
        }
      </div>
      {/*<div style={{height: 400, width: '100%'}}>*/}
      {/*  <DataGrid*/}
        {/*    rows={rows}*/}
        {/*    columns={columns}*/}
        {/*    initialState={{*/}
        {/*      pagination: {*/}
        {/*        paginationModel: {page: 0, pageSize: 5},*/}
        {/*      },*/}
        {/*    }}*/}
        {/*    pageSizeOptions={[5, 10]}*/}
        {/*    checkboxSelection*/}
        {/*  />*/}
        {/*</div>*/}
      </Modal.Body>
      <Modal.Footer>
        <a href="https://survey.grsu.by/api/surveys/report/base.csv?parent=32&period=y2023&format=csv&separator=;&type=base&charset=windows-1251">
        <Button variant="success" style={{position: "left"}}>Скачать CSV</Button>
        </a>
        <Button variant="secondary" onClick={sendClose}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
