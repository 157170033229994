import ApiSecurity from "./ApiSecurity";

async function listSurveyForCommission({unit}){
    console.log(unit);
    const infoSurvey = await (await fetch(`/api/surveys?subjectUnit=${unit}}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return infoSurvey['hydra:member'];
}

async function loadListSurvey({group}) {
    const firstPage = await (await fetch(`/api/surveys?status=hidden&subjectType=${group}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    const surveyCount = firstPage['hydra:totalItems'];
    let survey = firstPage['hydra:member'];

    while(survey.count < surveyCount){
        survey = [...survey, (await (await fetch(`/api/surveys?status=hidden&subjectType=${group}`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member']]
    }

    return survey;
}


async function loadListSurveyForAccess({group}){
    const firstPage = await (await (await fetch(`/api/surveys?subjectType=${group}&exists[parent]=false`, {headers: await ApiSecurity.getAuthHeaders()})).json());
    const surveyCount = firstPage['hydra:totalItems'];
    let survey = firstPage['hydra:member'];

    while(survey.count < surveyCount){
        survey = [...survey, (await (await (await fetch(`/api/surveys?subjectType=${group}&exists[parent]=false`, {headers: await ApiSecurity.getAuthHeaders()})).json()))['hydra:member']]
    }
    return survey;

}

async function loadSurveyByID({survey}) {
    return await (await fetch(`/api/surveys/${survey}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}
// сохранение опроса
async function saveSurvey({survey}){
  return await (await fetch(
        `/api/surveys.json`,
        {
            method: 'POST',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(survey)
        })).json()
}
//редактирование данных в шаблоне родителе
async function updateSurvey({survey}) {
    return await (await fetch(
        `/api/surveys/${survey.id}.json`,
        {
            method: 'PUT',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
            body: JSON.stringify(survey)
        })).json()
}

async function deleteSurvey({idSurvey}){
    return await fetch(
        `/api/surveys/${idSurvey}`,
        {
            method: 'DELETE',
            headers: Object.assign(await ApiSecurity.getAuthHeaders(), {"Content-Type": "application/json"}),
        });
}

async function loadArhiveSurveyAll ({page}){
    return await (await fetch(`/api/surveys?page=${page}&status=fully`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}
async function loadArhiveSurvey(){
    let page=1;
    const firstPage = await loadArhiveSurveyAll({page:page++});
    const arhiveCount = firstPage['hydra:totalItems'];
    let history = firstPage['hydra:member'];

    while(history.count < arhiveCount){
        history = [...history, (await loadArhiveSurveyAll({page: page++}))['hydra:member']]
    }
    return history;
}
// todo Veranika  const result = await(await fetch(`/api/surveys?page=${page}&status=hidden`, {headers: await ApiSecurity.getAuthHeaders()})).json();
async function loadActiveSurveyAll(page= 1){
	//const result = await(await fetch(`/api/syrvesy?page=${page}&status=hidden&exists%5Bunit%5D=false`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	//const result = await(await fetch(`/api/surveys?page=${page}&status=hidden`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	const result = await(await fetch(`/api/surveys?page=1&status=active&exists%5Bparent%5D=false`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return result['hydra:member'];
}
//вывод активных опросников зная id шаблона от которого они были созданы
async function loadActiveSurvey({idSurvey}){
    const listSurvey = (await (await fetch(`/api/surveys?parent=${idSurvey}&status=active`, {headers: await ApiSecurity.getAuthHeaders()})).json())['hydra:member'];
    return listSurvey;
}

//вывод шаблонов-опросников которые находятся на подтверждении
async function loadFullySurvey(page= 1){
    const result = await(await fetch(`/api/surveys?status=fully`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return result['hydra:member'];
}

async function loadAccessSurveyAll({page}){
    const accessSurvey = await(await fetch(`/api/surveys`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return accessSurvey;
}
async function loadAccessSurvey(){
    let page= 1;
    const firstPage = await loadAccessSurveyAll({page:page++});
    const accessCount = firstPage['hydra:totalItems'];
    let accessSurveyParameter = firstPage['hydra:member'];

    while(accessSurveyParameter.count < accessCount){
        accessSurveyParameter = [...accessSurveyParameter, (await loadAccessSurveyAll({page: page++}))['hydra:member']]
    }
    return accessSurveyParameter;
}



async function checkParametersOfSurvey({id}){
	const result = loadSurveyByID({id})
	return result;
}


async function checkListSurvey({parameter,idSurvey}){
	//const listSurvey = await(await fetch(`api/surveys?page=1&subject=/api/users/${parameter}&status=active`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	const listSurvey = await(await fetch(`api/surveys?page=1&subject=/api/users/${parameter}&status=active&parent=/api/surveys/${idSurvey}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return listSurvey['hydra:member'];
}

export {
    listSurveyForCommission,
    loadListSurvey,
    saveSurvey,
    updateSurvey,
    deleteSurvey,
    loadSurveyByID,
    loadArhiveSurveyAll,
    loadArhiveSurvey,
	loadActiveSurveyAll,
    loadAccessSurvey,
    loadListSurveyForAccess,
    loadActiveSurvey,
	checkParametersOfSurvey,
    loadFullySurvey,
	checkListSurvey

}