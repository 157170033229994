import React, {useEffect, useState} from "react";
import spinerLoading from "../spiner";
import {deleteSurvey, loadActiveSurvey, loadActiveSurveyAll, loadListSurvey, updateSurvey} from "../../API/surveyApi";
import SurveyTitle from "../SurveyPage/SurveyTitle";
import dateFormat from "dateformat";
import ReadingIco from "../Button/ReadingIco";
import ModalReadFile from "../Modal/ModalReadFile";
import DeleteIco from "../Button/DeleteIco";

export default function ProcessingResults() {
  const [resSurvey, setResSurvey] = useState();
  const [loading, setLoading] = useState(false);
  const [showRead, setShowRead] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [idRead, setIdRead] = useState(0);

  useEffect(() => {
    loadActiveSurveyAll()
      .then(setSurvey);

  }, []);
  const closeReadForm = () => {
    setIdRead(0);
    setShowRead(false);
  }

  const openReadForm = (idData) => {
    setIdRead(idData);
    setShowRead(true);
  }
  const canselSurvey = async (idSurvey) => {
    const deleteSurveys = [];
    const id = '/api/surveys/' + idSurvey;
    let r = await loadActiveSurvey({idSurvey: id})
    for (const survey of r) {
      let item = deleteSurvey({idSurvey: survey.id})
      deleteSurveys.push(item);
    }
    Promise.all(deleteSurveys);

    const dataInfo = {
      id: idSurvey,
      status: 'hidden'
    }
    await updateSurvey({survey: dataInfo});
    let res =  await loadActiveSurveyAll();
    setSurvey(res);

  }

  return (
    <div className='d-flex'>
      <table className='table table-hover table-bordered border-light'>
        <thead style={{background: "#eaecf59e", textAlign: "center"}}>
        <tr>
          <td scope="col">Наименование опроса</td>
          <td scope="col">Действия</td>
        </tr>
        </thead>
        <tbody>
        {
          survey === null ?
            <tr>
              <td colSpan='3' align='center'>{spinerLoading()}</td>
            </tr>
            :
            survey.length === 0 ?
              <tr>
                <td colSpan='3' align='center'>{'Нет данных для отображения'}</td>
              </tr>
              : survey.length > 0 ?
                survey?.map((surveys, key) => {
                    const group = {value: surveys.subjectType.split('/')[3]};
                    ;
                    let title = <SurveyTitle typeSurvey={surveys.period}
                                             datePeriod={surveys.periodSize}
                                             department={group}
                                             ndate={dateFormat(surveys.bonusPeriodStartAt, "yyyy-mm-dd")}
                                             kdate={dateFormat(surveys.bonusPeriodEndAt, "yyyy-mm-dd")}/>;

                    return (
                      <tr key={key}>
                        <td>{title}</td>
                        <td>
                          <div>
                            {
                              showRead === true ?
                                <ModalReadFile
                                  isOpen={showRead}
                                  title={title}
                                  id={idRead}
                                  onCloseFile={closeReadForm}/>
                                : null
                            }
                            <button className="btn" title={'Просмотр опросника'}
                                    onClick={() => openReadForm(surveys.id)}>
                              <ReadingIco/>
                            </button>
                            <button className="btn" title={'Отменить проведение опроса'}
                                    onClick={() => canselSurvey(surveys.id)}>
                              <DeleteIco/>
                            </button>
                          </div>

                        </td>
                      </tr>
                    )

                  }
                )
                : null
        }
        </tbody>
      </table>
    </div>
  );
}