import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import {Row} from "react-bootstrap";
import PlusDoc from "../Button/PlusDoc";
import PlusIco from "../Button/PlusIco";
import DeleteIco from "../Button/DeleteIco";
import {idGroups} from "../../API/groupApi";
import {loadGroupInfo} from "../../API/parameterApi";

const divStyle = {
    height: '100px',
    resize: 'none'
}

export default function ModalNewGroup({isOpen, id, title, onCloseGroup, onSaveGroup}){
    const [nameGroup, setNameGroup] = useState('');
    const [validated, setValidated] = useState(false);
    const [checked, setChecked] = useState(false);
    const [chapter, setChapter] = useState('');
    let dataGroup = null;

    const onSave = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
            if(nameGroup.length >0){

                if(id !== 0){
                    dataGroup = {
                        id: id,
                        title:nameGroup,
                        isDividedIntoChapters:checked

                    }
                }
                else{
                    dataGroup = {
                        title:nameGroup,
                        isDividedIntoChapters:checked

                    }
                }
                onSaveGroup(dataGroup);
                setValidated(false);
                setChecked(false);
                setNameGroup('');
            }
        }
    }
    const onClose = () =>{
        setNameGroup('');
        setValidated(false);
        setChecked(false);
        onCloseGroup();
    }
    useEffect(() => {
        if(isOpen === false){
            return;
        }
        if(id !== 0){
            loadGroupInfo({idGroup:id}).then(
                (groupInfo)=>{
                    setNameGroup(groupInfo.title);
                    setChecked(groupInfo.dividedIntoChapters);
                }
            )
        }
    }, [isOpen]);


    return(
        <Modal show={isOpen}>
            <Modal.Header><HeadModal title={title}/></Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    <Form.Group as={Col} controlId='validationCustom01'>
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Наименование группы показателей"
                            className="mb-3"
                        >
                            <Form.Control required
                                          as="textarea"
                                          style={divStyle}
                                          placeholder="Введите наименование группы"
                                          value={nameGroup}
                                          onChange={e => setNameGroup(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">
                                Введите наименование группы!
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="m-2">
                        <Form.Check
                            label="Группировка показателей по разделам"
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" variant="primary" onClick={onSave}>
                    Сохранить
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )

}