import facultySlice from "./FacultySlicer";
import authSlice from "./AuthSlice";
import {configureStore} from "@reduxjs/toolkit";
import parametersSlice from "./ParametrsSlicer";
import unitSlicer from "./UnitSlicer";
import reloadSlice from "./Reload";
import confirmSlice from "./Confirm";
import reloadSliceCommission from "./ReloadCommission";
import parametersSliceCommission from "./ParametrsSlicerCommission";
import sumCommission from "./SumCommission";


export default configureStore({
	reducer: {
		auth: authSlice,
		faculty: facultySlice,
		parameters: parametersSlice,
		units:unitSlicer,
		reload:reloadSlice,
		confirm:confirmSlice,
		reloadCommission:reloadSliceCommission,
		parametersCommission:parametersSliceCommission,
		sumCommission:sumCommission,
	},
})