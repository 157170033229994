import React, {useEffect, useState} from "react";
import {useDebounce} from "use-debounce";
import {infoChapter} from "../../API/parameterApi";

export default function CheckedParameter({num, parameter, onNumber}) {
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue] = useDebounce(inputValue, 1000);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
    };
    const [chapter, setChapter] = useState('');
    useEffect(() => {
        if(parameter.chapter){
            infoChapter({url: parameter.chapter}).then(p=>setChapter(p.title));
        }
        else {
            return;
        }
    }, [num]);

    useEffect(() => {
        onNumber({id: parameter, value: debouncedValue});
    }, [debouncedValue]);

    return (
        <tr key={parameter.id}>
            <td>
                <input type="text" style={{width: 50}}
                       value={inputValue}
                       onChange={handleInputChange}></input>
            </td>
            <td>{parameter.title}</td>
            <td style={{textAlign: "center"}}>
                {parameter.maxDataValue.toString().split('.')[0]}
            </td>
        </tr>


    )
}