import {createSlice} from "@reduxjs/toolkit";

export const unitSlicer = createSlice({
	name:'units',

	initialState:{
		itemUnit:{},
	},

	reducers:{
		putUnit:(state,action) => {
			state.itemUnit = action.payload;
		}
	}
})
 export const {putUnit} = unitSlicer.actions
 export default unitSlicer.reducer