import React from 'react';
import Form from "react-bootstrap/Form";
import DeleteIco from "../Button/DeleteIco";
import PlusIco from "../Button/PlusIco";
import Info from "../Button/Info";
import EditIco from "../Button/EditIco";

export default function Param({l, elFind, handlerChangerEdit, styleForButton, userTN,accessRight}) {
	let unitsTitle = "";
	if (l.units === 'percent'){
		unitsTitle = '%'
	}else if(l.units === 'point'){
		unitsTitle = 'балл'
	}else if(l.units === 'units'){
		unitsTitle = 'ед'
	}else if(l.units === 'rub'){
		unitsTitle = 'руб'
	}else if(l.units === 'usd'){
		unitsTitle = 'долл.США'
	}
	console.log(styleForButton)
	console.log(accessRight)
	return (<tr>
		<td>{l.chapterTitle}</td>
		<td scope="row"><b>{l.order}</b></td>

		<td>
			<button className='btn' title={l.metodVerif ? l.metodVerif : "Нет данных!"} value={l.id}>
				<Info/>
			</button>
			{l.title}
		</td>

		<td>{unitsTitle}</td>
		<td>
			<div className="d-inline-flex">

				<div className="p-2" disabled>{l?.dataNow >= 0 ?
					l?.dataNow?.toString() :
					elFind?.data?.toString() ? elFind?.data?.toString() : "0.00"} </div>

				<button style={accessRight.includes("write")?{styleForButton}:{display:"none"}} className='btn' title={'Изменить'}

						value={l.id}
						onClick={(e) => handlerChangerEdit(l?.descriptionNew
								?
								l
								:
								(l.surveyParameterValues?.find((item) => Number(item.reason?.responsibleUser?.tnn) === userTN))
									?
									l.surveyParameterValues?.find((item) => Number(item.reason?.responsibleUser?.tnn) === userTN)
									:
									l
							,
							l.title, l.id)}>
					< EditIco/>
				</button>

			</div>
		</td>
	</tr>)
}