import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import React from "react";
import Button from "react-bootstrap/Button";

export default function ModalDescription({show, parameter, onCloseModal}) {
    //console.log(parameter)
    const titleParameter = parameter?.order + ' ' + parameter?.title;
    let unitsTitle = '';
    if (parameter?.units === 'percent') {
        unitsTitle = '%';
    } else if (parameter?.units === 'point') {
        unitsTitle = 'балл';
    } else if (parameter?.units === 'units') {
        unitsTitle = 'ед';
    } else if (parameter?.units === 'rub') {
        unitsTitle = 'руб';
    } else if (parameter?.units === 'usd') {
        unitsTitle = 'долл.США';
    }
    const arrExpert = parameter?.surveyParameterValues?.filter((el) => el?.author === "EXPERT" || el?.author === "API" );
    let nameUnit = [];
    let nameUnitFull = '';
    const res = arrExpert?.map((k)=>{
        try {
            if (k?.description) {
                nameUnit = JSON.parse(k?.description)
                if (typeof (nameUnit) === NaN) {
                    nameUnitFull = k?.description
                }
            }
            for (let i = 0; i < nameUnit?.length; i++) {
                if (nameUnit[i].name !== "") {
                    nameUnitFull = nameUnit[i].val + nameUnit[i].name
                }
            }
        } catch {
            if (k?.description) {
                nameUnitFull = k?.description
            }
        }
    });

    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered show={show} scrollable={true} onHide={onCloseModal}>
            <Modal.Header closeButton><HeadModal title={titleParameter}/></Modal.Header>
            <Modal.Body>
                <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th>Значение</th>
                        <th>Ед.изм.</th>
                        <th>Пояснение</th>
                    </tr>
                    </thead>
                    <tbody>
                    {nameUnit.length >0 ?
                        nameUnit?.map((info)=> {
                            return (<tr key={info.id}>
                                <td>{info.val}</td>
                                <td>{unitsTitle}</td>
                                <td>{info.name}</td>
                            </tr>)
                        })
                        : nameUnitFull !== '' ?
                           <tr>
                               <td>{arrExpert[0].data}</td>
                               <td>{unitsTitle}</td>
                               <td>{nameUnitFull}</td>
                           </tr>
                            : <tr>
                                <td colSpan={3} style={{textAlign:"center"}}>Нет данных для отображения</td>
                            </tr>}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCloseModal}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}