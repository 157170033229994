import ApiSecurity from "./ApiSecurity";

async function loadFaculty({page = 1}) {
	return await (await fetch(`/api/units?page=${page}&type=faculty`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllFaculty() {
	let page = 1;
	const firstPage = await loadFaculty({page: page++});
	const facultyCount = firstPage['hydra:totalItems'];
	let faculty = firstPage['hydra:member'];

	while (faculty.count < facultyCount) {
		faculty = [...faculty, (await loadFaculty({page: page++}))['hydra:member']]
	}
	return faculty;

}

async function loadDepartment({page = 1}) {
	return await (await fetch(`/api/units?type=department`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllDepartment() {
	let page = 1;
	const firstPage = await loadDepartment({page: page++});
	const departmentCount = firstPage['hydra:totalItems'];
	let department = firstPage['hydra:member'];

	while (department.count < departmentCount) {
		department = [...department, (await loadDepartment({page: page++}))['hydra:member']]
	}
	return department;

}

async function loadUnit({page = 1}) {
	return await (await fetch(`/api/units?type=unit&kp=1`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadAllUnit() {
	let page = 1;
	const firstPage = await loadUnit({page: page++});
	const unitCount = firstPage['hydra:totalItems'];
	let unit = firstPage['hydra:member'];

	while (unit.count < unitCount) {
		unit = [...unit, (await loadUnit({page: page++}))['hydra:member']]
	}
	return unit;
}


async function loadUnitA({page}) {
	return await (await fetch(`/api/units?page=${page}&type=unit&kp=1`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadUnitForAccess() {
	let page = 1;
	const firstPage = await loadUnitA({page: page++});
	const unitCountA = firstPage['hydra:totalItems'];
	let unitAccess = firstPage['hydra:member'];
	while (unitAccess.count < unitCountA) {
		unitAccess = [...unitAccess, (await loadUnitA({page: page++}))['hydra:member']]
	}
	return unitAccess;
}


async function userTN() {
	const result = await (await fetch(`/api/users/me`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return result['tnn'];
}

async function loadUnitsTitle(arr) {
	async function getResult(id) {
		const request = await fetch(`${id}`, {headers: await ApiSecurity.getAuthHeaders()});
		const response = await request.json();
		//console.log(response);
		return response;
	}

	async function getData(arr) {
		return await arr['hydra:member']?.map(async (l) => {
			const idUnit = l.unit;
			let result1 = {};

			getResult(idUnit).then(idResult => {
				return {id: idResult?.tnn, name: idResult?.title};
			});
		})
	}

	const result = await getData(arr);
	return result;
}

async function getNameUnit(id) {
	const request = await fetch(`/api/units/${id}`, {headers: await ApiSecurity.getAuthHeaders()});
	const response = await request.json();
	return response;
}

/* загружаем подразделения для экспертов*/
async function loadUnitsForSurvey() {
	const tnn = await userTN();
	const result = await (await fetch(`/api/user_accesses?page=1&user=/api/users/${tnn}&role=expert`, {headers: await ApiSecurity.getAuthHeaders()})).json();

	const accesses = result['hydra:member'];
	const unitsRefs = accesses.filter(access => access?.subject.match(/^\/api\/units\/[0-9]+/i) ?? false)
	const unitsTnns = unitsRefs.map(({subject}) => subject.replace(/^\/api\/units\//i, ''))

	const load = async ({page, unitsTnns}) => {
		const urlTnnsParams = unitsTnns.map(tnn => `tnn[]=${tnn}`).join('&');
		const response = await fetch(`/api/units?page=${page}&${urlTnnsParams}`, {headers: await ApiSecurity.getAuthHeaders()})
		return await response.json();
	}

	const loadAll = async ({unitsTnns, page = 1}) => {
		const hydra = await load({page, unitsTnns});
		const members = hydra['hydra:member'];

		if (members.length === 0) {
			return [];
		}

		const next = await loadAll({unitsTnns, page: page + 1})
		return members.concat(next);
	}

	//const res = loadAll({unitsTnns}).then()



	const result2 = result['hydra:member'].map(item => {
		return {id: item.subject.split('/')[3]}
	});

	// const res = [
	// 	{id:5213,name:"Информационно-аналитический центр"},
	// 	{id:4647,name:"Управление воспитательной работы с молодежью"}
	// ]
	//получаю все id, потом забираю наименования

	async function getInf(arr) {
		return Promise.all(await arr?.map(async (l) => {
				const idUnit = l.id;
				return getNameUnit(idUnit).then(idResult => {
					return idResult;
				});
			})
		)
	}

	 const res = await getInf(result2);
	 //console.log(res)

	return res;
}

async function infoUnit({idUnit}) {
	return await (await fetch(`${idUnit}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function infoUnits({apiUnits}) {
	return await (await fetch(`api/units?${apiUnits}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
}

async function loadNameUnit({id}) {
	const request = await fetch(`/api/units/${id}`, {headers: await ApiSecurity.getAuthHeaders()});
	const response = await request.json();
	return response;
}

export {
	loadNameUnit,
	loadFaculty,
	loadAllFaculty,
	loadDepartment,
	loadAllDepartment,
	loadAllUnit,
	loadUnit,
	loadUnitA,
	loadUnitsTitle,
	loadUnitForAccess,
	infoUnit,
	infoUnits,
	loadUnitsForSurvey
}