import React, {useState, useEffect} from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HeadModal from "./ModalHead";
import ModalBody from 'react-bootstrap/ModalBody';
import {infoChapter, ListChapter, loadAllGroup, loadSurveyType} from "../../API/parameterApi";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";


const divStyle = {
  //  width: '465px',
    height: '120px',
    resize: 'none'
}

export default function ModalPage({
                                      isOpen, title = 'title', onCancelHandler, onOkHandler,
                                      idParam, nameText, metodText, group, chapterUrl, maxVal, units,selFunction
                                  }) {

    const [nameVal, setNameVal] = useState(nameText);
    const [metodVal, setMetodVal] = useState(metodText);
    const [maxRes, setMaxRes] = useState(maxVal);
    const [validated, setValidated] = useState(false);
    const [idGroup, setIdGroup] = useState([]);
    const [listGroup, setListGroup] = useState(null);
    const [selectFunction, setSelectFunction] = useState(selFunction);
    const [selectUnits, setSelectUnits] = useState(units);
    const [chapter,setChapter] = useState(null);
    const [selectChapter, setSelectChapter] = useState(null);
    let dataNew = null;

    useEffect(() => {
        if (null === listGroup) {
            loadAllGroup().then(
                setListGroup
            )
        }
        if (group !== undefined) {
            if(group !== 0){
                loadSurveyType({urlType: group}).then(r => {
                        setIdGroup([{id: r.id, title: r.title}]);
                    }
                )
            }
        }
        if(null === chapter){
            ListChapter().then(setChapter);
        }
        if(chapterUrl){
            infoChapter({url: chapterUrl}).then((r)=>{
                setSelectChapter({value:r.id, label: r.title});
            })
        }
        else{

            setIdGroup([]);
        }
    }, [isOpen]);

    const spisChapter = chapter?.map((ch)=>{
        return {
            value: ch.id,
            label: ch.title
        }
    })

    const listGroupAll = listGroup?.map((g) => {
        return {
            id: g.id,
            title: g.title
        }
    })

    let selectionLimit;
    if (idParam >= 0) {
        selectionLimit = 1;
    }
    else{
        selectionLimit = - 1;
    }

    const onSelectParam = (selectedList, selectedItem) => {
        setIdGroup([...idGroup, selectedItem]);
    }
    const onRemoveParam = (selectedList, removedItem) => {
        setIdGroup(idGroup.filter(v => v.id !== removedItem.id));
    }


    const handleOk = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setValidated(true);
            if(idGroup[0].id !== 2) {
                if (nameVal.length > 0 && metodVal.length > 0 && maxRes > -6 && selectFunction !== '' && selectUnits !== '') {
                    if (maxRes >= -5 && maxRes <= 100) {
                        if (idParam > 0) {
                            dataNew = {
                                id: idParam,
                                title: nameVal,
                                description: metodVal,
                                maxDataValue: maxRes.toString(),
                                surveyType: '/api/survey_types/' + idGroup[0].id,
                                units: selectUnits,
                                resultCalculationType: selectFunction
                            }
                        } else {
                            dataNew = idGroup?.map((k) => {
                                    return {
                                        title: nameVal,
                                        description: metodVal,
                                        maxDataValue: maxRes.toString(),
                                        surveyType: '/api/survey_types/' + k.id,
                                        units: selectUnits,
                                        resultCalculationType: selectFunction
                                    };
                                }
                            )
                        }
                    }
                }
            }
            else{
                if (idParam > 0) {
                    dataNew = {
                        id: idParam,
                        title: nameVal,
                        description: metodVal,
                        surveyType: '/api/survey_types/' + idGroup[0].id,
                        units: selectUnits,
                        resultCalculationType: selectFunction,
                        chapter: '/api/chapters/' + selectChapter.value
                    }
                } else {
                    dataNew = idGroup?.map((k) => {
                            return {
                                title: nameVal,
                                description: metodVal,
                                surveyType: '/api/survey_types/' + k.id,
                                units: selectUnits,
                                resultCalculationType: selectFunction,
                                chapter: '/api/chapters/' + selectChapter.value
                            };
                        }
                    )
                }
            }
            onOkHandler(dataNew);
            setValidated(false);
            if (idParam === 0) {
                setNameVal('');
                setMetodVal('');
                setMaxRes('');
                setValidated(false);
                setIdGroup([]);
                setListGroup(null);
                setSelectUnits('');
                setSelectFunction('');
                setSelectChapter(null);
            }
        }
    }

    const handleCancel = () => {
            setNameVal('');
            setMetodVal('');
            setMaxRes('');
            setValidated(false);
            setIdGroup([]);
            setListGroup(null);
            setSelectUnits('');
            setSelectFunction('');
            setSelectChapter(null);
        onCancelHandler();
    };

    return (
        <Modal size="lg" show={isOpen} fullscreen={"sm-down"}>
            <Modal.Header><HeadModal title={title}/></Modal.Header>
            <ModalBody>
                <Form noValidate validated={validated} id={idParam}>
                    <Form.Group as={Col} controlId='validationCustom01'>
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Наименование показателя"
                            className="mb-3"
                        >
                            <Form.Control required
                                          as="textarea"
                                          placeholder="Введите наименование показателя"
                                          style={divStyle}
                                          value={nameVal}
                                          onChange={e => setNameVal(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">
                                Введите наименование показателя!
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} controlId='validationCustom02'>
                        <FloatingLabel controlId="floatingTextarea2" label="Методика верификации показателя">
                            <Form.Control required
                                          as="textarea"
                                          placeholder="Введите описание методики"
                                          style={divStyle}
                                          value={metodVal}
                                          onChange={e => setMetodVal(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите методику!
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} controlId='validationCustom03'>
                        <InputGroup style={{marginTop: 8}} hasValidation>
                            <InputGroup.Text>Максимальный размер</InputGroup.Text>
                            <Form.Control required
                                          type="number"
                                          min={-5}
                                          max={100}
                                          style={{width: 60}}
                                          value={maxRes}
                                          onChange={e => setMaxRes(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите размер надбавки!
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} controlId='validationCustom04'>
                        <Form.Text id="unitsBlock" muted>Выберите единицу измерения</Form.Text>
                        <Form.Control required
                                      as="select"
                                      type="select"
                                      defaultValue={selectUnits}
                                      aria-describedby="unitsBlock"
                                      placeholder='единица измерения'
                                      onChange={e=> setSelectUnits(e.target.value)}>
                            <option value=''>единица измерения</option>
                            <option value='percent'>%</option>
                            <option value='point'>балл</option>
                            <option value='units'>ед</option>
                            <option value='rub'>руб</option>
                            <option value='usd'>долл.США</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Выберите единицу измерения!
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId='validationCustom05'>
                        <Form.Text id="functionBlock" muted>Выберите какую функцию использовать при выводе экспертных оценок по данному показателю</Form.Text>
                        <Form.Control required
                                      as="select"
                                      type="select"
                                      defaultValue={selectFunction}
                                      aria-describedby="functionBlock"
                                      placeholder='Название функций'
                                      onChange={e=> setSelectFunction(e.target.value)}>
                            <option value=''>Название функций</option>
                            <option value='max'>MAX</option>
                            <option value='sum'>SUM</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Выберите функцию!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <div>Использовать показатель для верификации деятельности:</div>
                <Multiselect options={listGroupAll} placeholder={''}
                             onSelect={onSelectParam} onRemove={onRemoveParam} hidePlaceholder={true}
                             displayValue="title" selectedValues={idGroup} selectionLimit={selectionLimit}/>
                {
                   group === '/api/survey_types/2' ?
                        <div>
                            Раздел документа
                            <Select options={spisChapter} value={selectChapter} onChange={setSelectChapter}
                                    placeholder="Выберите раздел в который включается данный показатель"></Select>
                        </div>
                    : null
                }

            </ModalBody>
            <Modal.Footer>
                <Button type="submit" variant="primary" onClick={handleOk}>
                    Сохранить
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                Закрыть
            </Button>
            </Modal.Footer>
        </Modal>
    );
}




