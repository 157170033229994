import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Button from "react-bootstrap/Button";
import {loadUnitForAccess} from "../../API/listUnitAPI";
import Multiselect from 'multiselect-react-dropdown';
import {deleteAccess, searchPosition} from "../../API/accessAPI";
import SearchUser from "../ProvidingAccess/SearchUser";
import DeleteIco from "../Button/DeleteIco";

export default function ModalAddAccess({isOpen, id, otdel, type, titleParam, onAccessSave, onAccessClose}){
    const [unit, setUnit] = useState(null);
    const [selectUnit, setSelectUnit] = useState('');
    const [arrSelected,setArrSelected] = useState([]);
    const [selectUser, setSelectUser] = useState([]);
    const [idAccess, setIdAccess] = useState(0);
    const [userAccess, setUserAccess] = useState([]);
   const accessSave = () => {
       let dataAccess;
       let operation = 'add';
       if(otdel === true){
           operation = 'edit';
       }

       if (type === '/api/survey_types/2'){
           // if(operation === 'edit'){
           //     if(selectUser.length === 1){
           //         dataAccess = selectUser?.map((k)=>{
           //             return {
           //                 id: idAccess,
           //                 surveyParameter: '/api/survey_parameters/'+ id,
           //                 fillingUnit: '/api/units/'+ arrSelected[0].value,
           //                 responsibleUser:'/api/users/'+ k
           //             }
           //         })
           //     }
           //     else{
           //        deleteAccess({idAccess: idAccess}).then() ;
           //        dataAccess = selectUser?.map((k)=>{
           //                 return {
           //                     surveyParameter: '/api/survey_parameters/'+ id,
           //                     fillingUnit: '/api/units/'+ arrSelected[0].value,
           //                     responsibleUser:'/api/users/'+ k
           //                 }
           //             })
           //     }
           // }
           //  else {
               if(selectUser.length > 0){
                   dataAccess = selectUser?.map((k)=>{
                       return {
                           surveyParameter: '/api/survey_parameters/'+ id,
                           fillingUnit: '/api/units/'+ arrSelected[0].value,
                           responsibleUser:'/api/users/'+ k
                       }
                   })
               }
               else{
                   dataAccess = arrSelected?.map((k)=>{
                       return {
                           surveyParameter: '/api/survey_parameters/'+ id,
                           fillingUnit: '/api/units/'+ k.value,
                       }
                   })
               }
           // }
        }
       else {
           if(operation === 'edit'){
               dataAccess = arrSelected?.map((k)=>{
                   return {
                       id:idAccess,
                       surveyParameter: '/api/survey_parameters/'+ id,
                       fillingUnit: '/api/units/'+ k.value,
                   }
               })
           }else{
               dataAccess = arrSelected?.map((k)=>{
                   return {
                       surveyParameter: '/api/survey_parameters/'+ id,
                       fillingUnit: '/api/units/'+ k.value,
                   }
               })
           }
       }

       const name = arrSelected?.map((p)=>{
           return {id: id, nameUnit: p.name }
       })

       onAccessSave(dataAccess, name, operation);

       setUnit(null);
       setArrSelected([]);
       setSelectUser([]);
   }

   const accessClose = () =>{
       setUnit(null);
       setArrSelected([]);
       setSelectUser([]);
       setSelectUnit('');
       onAccessClose();
   }
    //получение списка названия СП
    useEffect(()=>{
      if(null === unit) {
          loadUnitForAccess()
              .then(setUnit);
      }
    }, [isOpen]);

    const unitSurvey = unit?.map((unitAll,keys)=>{
        return  {
            value: unitAll.tnn,
            name: unitAll.title
        }
    })

    // загрузка прикрепленных отделов
    useEffect(()=>{
        if(otdel !== false) {
            searchPosition({idParameter:'/api/survey_parameters/'+ id})
                .then((idPosition)=>{
                    if(idPosition.accesses.length === 1){
                        setIdAccess(idPosition.accesses[0].id);
                        if(idPosition.accesses[0].fillingUnit){
                            setArrSelected([{value: idPosition.accesses[0].fillingUnit.tnn, name: idPosition.accesses[0].fillingUnit.title}])
                        }
                        if(idPosition.accesses[0].responsibleUser){
                            setUserAccess({tn:idPosition.accesses[0].responsibleUser.tnn, fio:idPosition.accesses[0].responsibleUser.fullName})
                            setSelectUser([idPosition.accesses[0].responsibleUser.tnn])
                        }

                    }
                    else{
                        const listAcc = [];
                        const userAcc = [];
                        const listUser = [];
                        const listUnit = idPosition.accesses?.map((acc)=>{
                            if(acc.fillingUnit){
                                listAcc.push({value: acc.fillingUnit.tnn, name:acc.fillingUnit.title});
                            }
                            if(acc.responsibleUser){
                                userAcc.push({tn:acc.responsibleUser.tnn, fio:acc.responsibleUser.fullName});
                                listUser.push(acc.responsibleUser.tnn);
                            }
                        })
                        setArrSelected(listAcc);
                        setUserAccess(userAcc);
                        setSelectUser(listUser);
                    }
                })
        }
        else{
            return;
        }
    }, [isOpen]);

console.log(selectUser);
    let dis = false;
    const onSelectParam = (selectedList, selectedItem) => {
        setArrSelected([...arrSelected, selectedItem]);
    }

    const onRemoveParam = (selectedList, removedItem) => {
        if(id !== 0){
            searchPosition({idParameter:'/api/survey_parameters/'+ id})
                .then((idPosition) => {
                   const position = idPosition.accesses?.reduce((acc, positions)=>{
                       if(positions.fillingUnit['@id'] === '/api/units/'+ removedItem.value ){
                            return [
                                ...acc,
                                {id:positions.id}
                            ];
                        }
                        return acc;
                    }, [])
                    // position?.map((access)=>{
                    //     deleteAccess({idAccess: access.id}).then() ;
                    // })
                });
        }
        setArrSelected(arrSelected.filter(v => v.value !== removedItem.value));
    }

    const onSelectUser = (value)=> {
        if (selectUser === null) {
            setSelectUser([value]);
        }
        else{
            setSelectUser([...selectUser, value]);
        }
    };
    const [newUser,setNewUser] = useState(false);
    const addUser = () => {
        setNewUser(true);
    };
    const deleteUser = (data) =>{
        if(id !== 0){
            searchPosition({idParameter:'/api/survey_parameters/'+ id})
                .then((idPosition) => {
                    const position = idPosition.accesses?.reduce((acc, positions)=>{
                        if(positions.responsibleUser['@id'] === '/api/users/'+ data){
                            return [
                                ...acc,
                                {id:positions.id}
                            ];
                        }
                        return acc;
                    }, [])
                    position?.map((access)=>{
                        deleteAccess({idAccess: access.id}).then() ;
                    })
                });
        }
        setSelectUser(selectUser.filter(v => v !== data));
        setUserAccess(userAccess.filter(v => v.tn !== data));
    }
    let content;
    if(userAccess.length>1){
        content = userAccess?.map((p, key)=> {
            return (
                <div>{key + 1}.{p.fio}
                    <button className={"btn"} onClick={() => deleteUser(p.tn)}
                            title={'Удалить эксперта'}>
                        <DeleteIco/>
                    </button>
                </div>
            )
        })
    }
    else{
      content = (
          <div>{userAccess.fio}
            <button className={"btn"} onClick={() => deleteUser(userAccess.tn)}
                    title={'Удалить эксперта'}>
                <DeleteIco/>
            </button>
        </div>
      )
    }


    return (
        <Modal size={"xl"} style={{height: 600}} aria-labelledby="contained-modal-title-vcenter"
               centered show={isOpen} onHide={accessClose}>
            <Modal.Header closeButton><HeadModal
                title={'Назначение ответственного структурного подразделения за заполнение данного показателя '}/></Modal.Header>
            <Modal.Body>
                <div className="mt-2"><i>Наименование показателя</i></div>
                <div><b><u>{titleParam}</u></b></div>
                <div className="mt-2"><i>Выберите подразделение для которого необходимо предоставить доступ:</i>
                    <b style={{color:"red"}}>*</b></div>
                <div>
                    <Multiselect displayValue="name"
                                  options={unitSurvey}
                                  selectedValues={arrSelected}
                                  onSelect={onSelectParam}
                                  onRemove={onRemoveParam}
                                  placeholder="Наименование СП"
                                  hidePlaceholder={true}
                                  showCheckbox={true}
                                  closeOnSelect={true}
                    />
                </div>
                <div className="mt-2"><i>Выберите ФИО сотрудника, который ответственный за заполнение данного показателя</i>
                    <b style={{color:"red"}}>*</b></div>
                <div className="mb-1"><SearchUser fio={selectUser} onSave={onSelectUser} /></div>
                { newUser === true ?
                    <div><SearchUser fio={selectUser} onSave={onSelectUser}/></div>
                    : null
                }
                {userAccess.length > 0  ?
                    <div><b>Эксперты:</b>  {content} </div>
                    : null
                }
                <div><a href='#' onClick={addUser}>Добавить сотрудника</a></div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={accessSave} disabled = {dis}>
                    Сохранить
                </Button>
                <Button variant="secondary" onClick={accessClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}