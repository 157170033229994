import {createSlice} from "@reduxjs/toolkit";

export const facultySlice = createSlice({
	name: 'faculty',

	initialState: {
		isLoad: false,
		list: []
	},

	reducers: {
		putFaculty: (state, action) => {
			state.isLoad = true;
			state.list = action.payload;
		},
	}

})
export const {putFaculty} = facultySlice.actions
export default facultySlice.reducer