import React, {useEffect, useState} from "react";
import ModalAddUserAccess from "../Modal/ModalAddUserAccess";
import {deleteUserAccess, loadAllUserAccess, loadUserAccessRole, saveAccessUser} from "../../API/accessAPI";
import ListUserAccess from "./ListUserAccess";
import spinerLoading from "../spiner";
import Select from "react-select";


export default function AccessUsers() {
    const [show, setShow] = useState(false);
    const [listUser, setListUser] = useState([]);
    const [selectRole, setSelectRole] = useState(null);
    const openShow = () => {
        setShow(true);
    }
    const handleAccessUserClose = () => {
        setShow(false);
    }
    const handleAccessUserSave = (dataAccess) => {
        const saveAccessData = dataAccess?.map((data) => {
            saveAccessUser({data}).then(r => {
                setListUser([...listUser, r]);
                setShow(false);
            })
        });
    }

    const handleDeleteAccess = (idAccess) => {
         deleteUserAccess({idUserPosition: idAccess.id})
           .then(() => {
                setListUser([...listUser].filter(r => r.id !== idAccess.id))
             });
    }

    useEffect(() => {
        if(selectRole !== null){
            loadUserAccessRole({role: selectRole.value}).then(setListUser);
        }
        else{
            return;
        }
    }, [selectRole]);

    const listRole = [
        {value: "ADMIN", label: "Администратор"},
        {value: "EXPERT", label:"Эксперт"},
        {value: "COMMISSION", label: "Комиссия"},
        {value: "DEPARTMENT", label: "Заведующий кафедры"},
        {value: "DEAN", label: "Декан"}
    ];


    return (
        <div>
            <div> <Select options={listRole} value={selectRole} placeholder="Выберите роль доступа"
                          onChange={setSelectRole}/>
            </div>
            <div style={{margin: 15, textAlign: "right"}}>
                <a href='#' onClick={openShow}>Назначить ответственного</a>
                <ModalAddUserAccess
                    isOpen={show}
                    id={'0'}
                    title="Назначить ответственного от структурного подразделения за заполнение опроса"
                    onAccessUserSave={handleAccessUserSave}
                    onAccessUserClose={handleAccessUserClose}
                />
            </div>
            {
                selectRole !== null ?
                <div className="table-responsive rounded-3">
                    <table className="table table-hover table-bordered border-light">
                        <thead style={{background: "#eaecf59e", textAlign: "center"}}>
                        <tr>
                            <th scope="col">ФИО</th>
                            <th scope="col" style={{width: 260}}>Структурное подразделение/Группа показателей</th>
                            <th scope="col" style={{width: 160}}>Роль</th>
                            <th scope="col" style={{width: 160}}>Права доступа</th>
                            <th scope="col" style={{width: 160}}>Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listUser.length === 0 ?
                                <tr>
                                    <td colSpan='4' align='center'>{'Нет данных для отображения'}</td>
                                </tr>
                                :
                                listUser.length > 0 ?
                                    listUser?.map((dataUser) => (
                                        <ListUserAccess key={dataUser.id} id={dataUser.id} tn={dataUser.user}
                                                        unit={dataUser.subject}
                                                        position={dataUser.role}
                                                        accesses = {dataUser.accessRight}
                                                        onDeleteAccessUser={handleDeleteAccess}/>
                                    ))
                                    : null
                        }
                        </tbody>
                    </table>
                </div>
                :null
            }
        </div>
    );
}