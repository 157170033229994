import React, {useEffect, useState} from "react";
import {loadUnitsForSurvey} from "../../API/listUnitAPI";
import SelectNew from "../Common/SelectNew";

import Loader from "../Common/Loader";
import {useDispatch, useSelector} from "react-redux";
import {putUnit} from "../../Store/UnitSlicer";
import InterviewAdd from "./InterviewAdd";
import InterviewADDForRaiting from "../InterviewRaiting/ADDInterviewForRaiting";



export default function UnitAdd({idSurvey,typeOfSurvey}) {

	const [listUnitLoader, listUnitLoaderChanger] = useState(false);
	const [listUnits, listUnitsChanger] = useState(null);
	const [loading, loadingChanger] = useState(false);
	const itemUnitID = useSelector(state => state.units);
	const dispatch = useDispatch();

	let interviewAdd = null;
	const compareName = (a, b) => a.name < b.name ? -1 : 1;
	let result = {id: 0, name: "Выберите структурное подразделение"}

	const newListUnit = (list) => {
		result = list?.map((el) =>
			 ({id: Number(el?.tnn), name: el?.title}));
	    const resutByName = result?.sort(compareName);
		//console.log('подразделения '+result)
		return resutByName;
	}

	useEffect(() => {
		if (!listUnitLoader) {
			loadUnitsForSurvey()
				.then((data) => {
					const arr = newListUnit(data)
					listUnitsChanger(arr);
					listUnitLoaderChanger(true);
					loadingChanger(true);
				//	dispatch(putUnit(newListUnit(data)));
				})
				.catch(
					listUnitLoaderChanger(false)
				)
		}

	}, [listUnitLoader, itemUnitID.itemUnit])

	const IDitem = itemUnitID.itemUnit;

	if (typeOfSurvey == 1) {
		interviewAdd = <InterviewAdd idSurvey={idSurvey} unit={IDitem}/>
	} else {
		interviewAdd = <InterviewADDForRaiting idSurvey={idSurvey} unit={IDitem}/>
	}

	const onSelectChangerUnits = (e) => {
		dispatch(putUnit(e.id));
	}

	return (
		<div>
			{loading ? (
					<div>
						<h4>Выберите структурное подразделение</h4>
						<div style={{padding: 10}}>
							<SelectNew list={listUnits} selectChanger={(e) => onSelectChangerUnits(e)}></SelectNew>
						</div>
					</div>
				)
				:
				<Loader/>
			}
			{interviewAdd}
		</div>
	)

}