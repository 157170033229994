import React, {useEffect, useState} from "react";
import {loadArhiveSurvey} from "../../API/surveyApi";
import SurveyTitle from "../SurveyPage/SurveyTitle";
import dateFormat from "dateformat";
import PreviewIco from "../Button/PreviewIco";
import ModalReadFile from "../Modal/ModalReadFile";
import spinerLoading from "../spiner";


export default function ArhivePage(){
    const [spisSurvey, setSpisSurvey] = useState(null);
    const [show,setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const openFile = () => {
        setShow(true);
    }
    const closeFile = () => {
        setShow(false);
    }
    useEffect(() => {
        if (null === spisSurvey) {
            loadArhiveSurvey(1)
                .then(setSpisSurvey);
            setLoading(true);
        }
    }, [spisSurvey]);

    let listSurvey = [];

    if(spisSurvey === null || loading === 'true'){
        listSurvey =  <tr><td colSpan='3' align='center'>{spinerLoading()}</td></tr>;
    }
    else if(spisSurvey.length === 0){
        listSurvey = <tr><td colSpan='3' align='center'>Нет данных для отображения</td></tr>;
    }
    else{
        listSurvey = spisSurvey?.map((r) => {
            let title = <SurveyTitle typeSurvey={r.type}
                                     datePeriod={r.period}
                                     idDepartment = {r.personType}
                                     ndate = {dateFormat(r.startAt,"yyyy-mm-dd")}
                                     kdate = {dateFormat(r.endAt, "yyyy-mm-dd")}   />;
            let typeSurvey = '';
            if(r.type === 'quarter') {
                typeSurvey = "Квартальный";
            }
            else if(r.type === 'monthly') {
                typeSurvey = "Ежемесячный";
            }
            else if(r.type === 'annual') {
                typeSurvey = "Полугодовой";
            }
            return  <tr key={r.id}>
                <td>{title}</td>
                <td>{typeSurvey}</td>
                <td>
                    <button className='btn' title={'Просмотреть'} onClick={() => openFile(spisSurvey.id)}><PreviewIco/></button>

                </td>
            </tr>
        });
    }


    return(
        <div>
            <table className="table table-hover table-bordered border-light">
                <thead style={{background: "#eaecf59e", textAlign: "center"}}>
                <tr>
                    <th scope="col">Название опроса</th>
                    <th scope="col"style={{width: 160}}>Тип опроса</th>
                    <th scope="col" style={{width: 80}}>Действия</th>
                </tr>
                </thead>
                <tbody>
                     {listSurvey}
                </tbody>
            </table>
        </div>
    );
}