import {createSlice} from "@reduxjs/toolkit";

export const reloadSlice = createSlice({
	name: 'reload',

	initialState: {
		reload:false

	},
	reducers: {
		putReloadParametr:(state,action)=>{
			state.reload = action.payload
		}
	}
})

export const {putReloadParametr} = reloadSlice.actions
export default reloadSlice.reducer