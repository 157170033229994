import React, {useState} from "react";
import ModalViewAccessParameter from "../Modal/ModalViewAccessParameter";
import ReadingIco from "../Button/ReadingIco";
import SurveyTitle from "../SurveyPage/SurveyTitle";
import dateFormat from "dateformat";
export default function ListAccessUnit({survey, group, status}) {
    console.log(status)
    const [show,setShow] =useState(false);
    const [spisShow, setSpisShow] = useState(null);

    const handleEditParameterAccess = (idSurvey) =>{
        setSpisShow(idSurvey);
        setShow(true);
    }
    const editClose = () => {
        setShow(false);
    }

    let titleSurvey = <SurveyTitle typeSurvey={survey.period}
                                   datePeriod={survey.periodSize}
                                   department = {group}
                                   ndate = {dateFormat(survey.bonusPeriodStartAt,"yyyy-mm-dd")}
                                   kdate = {dateFormat(survey.bonusPeriodEndAt, "yyyy-mm-dd")}   />;

    let nameStatus = '';

    return(
        <tr key={survey.id}>
            { show === true ?
                <ModalViewAccessParameter
                    isOpen={show}
                    id={survey.id}
                    title={titleSurvey}
                    onEditClose={editClose}
                />
                :null
            }
            <td>{titleSurvey}</td>
            <td>{status === 'active' ? nameStatus="Опубликованный" : status === 'hidden' ? nameStatus="Разрабатывается" :null}</td>
            <td>
                {
                    <div>
                        <button className="btn" onClick={() => handleEditParameterAccess(survey.id)} title={'Редактирование прав для структурных подразделений'}>
                            <ReadingIco/>
                        </button>
                    </div>
                }
            </td>
        </tr>
    )
}