import {createSlice} from "@reduxjs/toolkit";

export const confirmSlice = createSlice({
	name: 'confirm',

	initialState: {
		confirm:false,


	},
	reducers: {
		putConfirm:(state,action)=>{
			state.confirm = action.payload
		}
	}
})

export const {putConfirm} = confirmSlice.actions
export default confirmSlice.reducer