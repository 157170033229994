import React, {forwardRef, useEffect, useId, useImperativeHandle, useState} from "react";
import Form from "react-bootstrap/Form";
import PlusIco from "../Button/PlusIco";
import DeleteIco from "../Button/DeleteIco";
import {useDispatch, useSelector} from "react-redux";
import {putSum} from "../../Store/SumCommission";


const ParamsValueCommission = forwardRef(({l, id, idEl, handlerChangerDelete, handleSave, onEditableData, title,flag}, ref) => {
	let val = "";
	let name = "";

	const [params, changerParams] = useState(l)
	const [paramsForDelete,paramsForDeleteChanger] = useState([])
	const [paramsDelOne,paramsDelOneChanger] = useState([])
	const [sum, changerSum] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (l?.length !== 0) {
			countSum()
		}
	}, [params])


	useImperativeHandle(ref, () => ({

		save() {
			handleSave(params, id, sum, idEl,paramsForDelete,paramsDelOne)
		}
	}), [params,sum])

	const addValue = () => {
		//sconst arr = params;
		const numberOfId = params?.length + 1;
		//	arr.push({id: numberOfId, val: '', name: ''})
		changerParams(prevState => (
			// eslint-disable-next-line react-hooks/rules-of-hooks
			[...prevState, {id: (new Date()).getTime(), val: '', name: '',idParamValue:0,author:''}]
		));

		//dispatch(putDescriptions(params))
	}

	const handlerDelete = (index,idParam) => {
		if (params?.length == 1) {
			changerParams(prev => prev.filter((el) =>
				el.id !== index
			))
				changerParams(prevState => (
					// eslint-disable-next-line react-hooks/rules-of-hooks
					[...prevState, {id: (new Date()).getTime(), val: '', name: '',idParamValue:''}]
				))
			paramsDelOneChanger(prevState => [...prevState,idParam])
		} else {
			changerParams(prev => prev.filter((el) =>
				el.id !== index
			))
			paramsForDeleteChanger(prevState => [...prevState,idParam])
		}
		countSum()
	}

	const countSum = () => {

		if (params?.length !== 0) {
			let resulSum = params?.reduce((sum, item) => sum + (!isNaN(parseFloat(item.val)) ? parseFloat(item.val) : 0), 0);
			changerSum(resulSum)
			dispatch(putSum(resulSum))
		}else {
			changerSum(0)
			dispatch(putSum(0))
		}

	}

	const onEditableDataParamsVal = (e, index) => {
		const valParam = e.target.value
		changerParams(prevState => prevState.map((item, key) => {
			if (key === index) {
				item.val = valParam
			}
			return item
		}))
		countSum()
	}

	const onEditableDataParamsDescr = (e, index) => {
		const valParam = e.target.value
		changerParams(prevState => prevState.map((item, key) => {
			if (key === index) {
				item.name = valParam
			}
			return item
		}))
	}

	return (
		<div>
			<h5>{title}</h5>
			<div className="d-inline">
				{(params?.length > 0) ?
					<div className="d-inline">
						{params?.map((l, index) => {
							if (l !== null) {
								val = l?.val;
								name = l?.name;
							}
							// return	<Param l={l} onEditableData={(e)=>onEditableDataParams(e)}
							// 		   handlerChangerDelete={() => handlerDelete(index)}/>

							return <div className="d-inline-flex" key={`${index}-params-${l.id}`}>
								<Form.Control
									type="number"
									required
									style={{width: 130}}
									placeholder={'Значение'}
									//	defaultValue={parseInt(l.component?.find((item) => item.unit?.tnn === itemUnitID.itemUnit)?.dataDecimal?.toString())}
									// defaultValue={val}
									value={val}
									onChange={(e) => onEditableDataParamsVal(e, index)}
								/>
								<Form.Control
									type="text"
									required

									style={{width: 300}}
									placeholder={'Описание'}

									// defaultValue={name}
									value={name}
									onChange={e => onEditableDataParamsDescr(e, index)}
								/>

								<button className='btn' title={'Удалить'} style={flag===1?{display:"none"}:{display:"block"}}
										onClick={() => handlerDelete(l.id,l.idParamValue)} key={index}>< DeleteIco/>
								</button>

							</div>

						})}
						<p></p>
						<button className='btn' title={'Добавить'} style={flag===1?{display:"none"}:{display:"block"}} value={l.id} key={l.id} onClick={() => addValue()}>
							< PlusIco/>
						</button>
						<div className="input-group mb-3">
							<span className="input-group-text" id="inputGroup-sizing-default">Сумма</span>
							{/*<div className = "" key={l.id}>{parseFloat(sum)}</div>*/}
							<input className="form-control" type="text" placeholder="Disabled input"
								   aria-label="Disabled input example" key={l.id} value={sum} disabled>
							</input>

						</div>
					</div>
					: < button className='btn' title={'Добавить'} value={''} key={l.id} onClick={() => addValue()}>
						< PlusIco/>
					</button>
				}
			</div>
		</div>
	)
})
export default ParamsValueCommission
