import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkFaculty, chekFaculty, loadFaculty} from "../../API/facultyAPI";
import {putFaculty} from "../../Store/FacultySlicer";
import SelectNew from "../Common/SelectNew";
import Interview from "./Interview";
import InterviewRaiting from "../InterviewRaiting/InterviewForRaiting";
import {loadParametersBySurveyID, loadParametrsBySurveyID} from "../../API/parameterApi";
import Loader from "../Common/Loader";
import {loadUnitsForSurvey} from "../../API/listUnitAPI";
import {loadSurveyByIDForFaculty} from "../../API/surveyApi";
import UnitAdd from "./UnitAdd";
import ModalDialog from "../Common/Modal";
import InterviewForRaiting from "../InterviewRaiting/InterviewForRaiting";


export default function InterviewAdd({idSurvey, unit}) {


	const [itemFaculty, itemFacultyChanger] = useState(0);
	const [isFaculty, facultyChanger] = useState(true);
	const [selectInterview, selectInterviewChanger] = useState(true);
	const [listParamets, listParametsChanger] = useState([]);
	const [loader, loaderChanger] = useState(false)
	const [show, showChanger] = useState(false);

	const [page1, pageChanger] = useState(1);
	const [page, handlerPageChanger] = useState(1);
	const [total, totalChanger] = useState(0);
	const facultySlice = useSelector(state => state.faculty);
	const dispatch = useDispatch();

	useEffect(() => {
		loadFaculty({page1, isFaculty, idSurvey})
			.then(data => {
				const arr = newList(data);
				dispatch(putFaculty(arr));
			})
			.catch()

	}, [facultySlice.isLoad])

	useEffect(()=>{
		checkFaculty({page,idSurvey})
			.then(data =>{

		}).catch()
	},[facultySlice])

	useEffect(() => {
			itemFacultyChanger(0)
		}, [unit]
	)

	const loadParameters=()=>{
		listParametsChanger([])
		const parameter = itemFaculty.id;
		loadParametersBySurveyID({parameter,unit,idSurvey})
			.then(data => {

				if (data?.length ==1){
					totalChanger(1)
				}else{
					totalChanger(Math.round(data?.length / 4))
				}
				if (data!==0) {
					listParametsChanger(data.sort(compareTitle));
				}
				loaderChanger(true)

			})
			.catch(listParametsChanger([]),
				loaderChanger(false))
	}

	useMemo(() => {
		loadParameters()
	}, [itemFaculty])

	const onSelectChangerFaculty = (e) => {
		itemFacultyChanger(e);
		handlerPageChanger(1)
		//	selectInterviewChanger(true);
	}

	const compareName = (a, b) => a.name < b.name ? -1 : 1;
	const compareTitle = (a, b) => a.title < b.title ? -1 : 1;

	const newList = (list) => {
		const result = list.map((el) => ({id: Number(el.tnn), name: el.title}));
		const resutByName = result.sort(compareName);
		return resutByName;
	}

	const onClose = () => {
		if (window.confirm("Вы хотите закрыть окно?") === true) {
			showChanger(false)
		}
	}

	const interview1 = (itemFaculty !== -1 && listParamets?.length !== 0) ?
		<InterviewForRaiting
			itemFaculty={itemFaculty.id}
			nameFaculty={itemFaculty.name}
			listParamets={listParamets}
			page={page}
			total={total}
			handler={handlerPageChanger}
			loadParameters = {loadParameters}
		/>
		: <div>Нет данных!</div>;

	return (

		<div style={{padding: 10}}>

			<div>
				<h4>Выберите факультет</h4>
				<div style={{padding: 10}}>
					<SelectNew list={facultySlice.list} selectChanger={onSelectChangerFaculty}/>
				</div>
				{loader ?
					interview1 :
					<Loader/>
				}
			</div>
		</div>
	)
}