import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useEffect, useRef, useState} from "react";
import ButtonUp from "../Button/ButtonUp";
import {useSelector} from "react-redux";

export default function ModalDialogBig({
										   title,
										   children,
										   isOpen,
										   handleClose,
										   handleSave,
										   size,
										   handleConfirm,
										   handleCancelConfirm,
										   handleConfirmProps,
										   handleCancelConfirmProps,
										   refModal: ref

									   }) {
	const ref2 = useRef(null);
	const [scroll, changerScroll] = useState(false);
	const [checkStatus, checkStatusChanger] = useState(false)
	const listParams = useSelector(state => state.parameters.list);
	const [styleConfirm,styleConfirmChanger] = useState(handleConfirmProps);
	const [styleUnconfirm,styleUnconfirmChanger] = useState(handleCancelConfirmProps)
	const {user} = useSelector(state => state.auth);
	const accessRight = useSelector(state=>state.auth.access)
	const userTN =  Number(user?.tnn)
	const currentUrl = window.location.href;
console.log(accessRight)
	// let styleUnconfirm;
	// let styleConfirm;
//console.log(handleConfirmProps)
	// useEffect(() => {
	// 	function onScroll(e) {
	// 		//console.log(e)
	// 		//if (!ref?.current) return null;
	// 		if (!scroll) {
	// 			changerScroll(true)
	// 		}
	// 	}
	//
	// 	if (ref?.current) ref?.current.addEventListener("scroll", onScroll)
	// 	return () => {
	// 		if (ref?.current) ref?.current.removeEventListener("scroll", onScroll)
	// 	}
	// }, [ref])


	const scrollDiv = () => ref2?.current.scrollTo({
		top: 0, behavior: "smooth"
	})

	useEffect(() => {
	let currentEl =""
		if (listParams?.length > 0) {
			listParams?.map((l) => {
				currentEl = l?.surveyParameterValues?.find((item) => Number(item.reason?.responsibleUser?.tnn) === userTN)
				// if (currentUrl.includes("/commission")) {
				// 	 currentEl = l?.surveyParameterValues?.find((item) => Number(item.reason?.responsibleUser?.tnn) === userTN)
				// }else{
				// 	 currentEl = l?.find((item) => item.author.includes("COMMISSION"));
				// 	 console.log(currentEl)
				// }
				if (currentEl?.status === "VALID") {
					styleConfirmChanger(true)
					styleUnconfirmChanger(false)
					return
				} else {
					styleConfirmChanger(false)
					styleUnconfirmChanger(true)
					return
				}
			})

		}


	}, [listParams])
// console.log(ref)



	return (
		<Modal scrollable={true} show={isOpen} onHide={handleClose} animation={true} size={size}
			   aria-labelledby="contained-modal-title-vcenter"
			   centered>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>

				<Modal.Body ref={ref2}>
					<div>{children}</div>
				</Modal.Body>

			<Modal.Footer>
				{/*<Button {...(!scroll && {disabled:true})} variant="primary" onClick={scrollDiv}><ButtonUp/></Button>*/}
				{(listParams?.length > 0) ?
					(accessRight==="write")?
					<React.Fragment>
						<Button style={styleConfirm === true ? {display: 'none'} : {display: 'block'}}
								variant="secondary" onClick={handleConfirm}>
							Подтвердить
						</Button>

						<Button style={styleUnconfirm === true ? {display: 'none'} : {display: 'block'}}
								variant="secondary" onClick={handleCancelConfirm}>
							Отменить подтверждение
						</Button>

						<Button variant="primary" aria-placeholder="Перейти к след. кафедре" onClick={scrollDiv}>
							<ButtonUp/>
						</Button>
					</React.Fragment>
						:
						<Button variant="primary" aria-placeholder="Перейти к след. кафедре" onClick={scrollDiv}>
							<ButtonUp/>
						</Button>
					: null
					// <Button variant="primary" aria-placeholder="Перейти к след. кафедре"  onClick={scrollDiv}>
					// 	<ButtonUp/>
					// </Button>
				}


			</Modal.Footer>

		</Modal>
	);
}