import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";

const divStyle = {
    height: '100px',
    resize: 'none'
}
export default function ModalAddChapter({isOpen, saveChapter, closeChapter}){
    const [nameChapter, setNameChapter] = useState('');
    const [validated, setValidated] = useState(false);

    let dataChapter = null;
    const onSave = (event) =>{
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
            if(nameChapter.length >0){
                dataChapter = {
                    title: nameChapter
                }
            }
            saveChapter(dataChapter);
            setNameChapter('');
        }
    }
    const onClose = () =>{
        closeChapter();
    }
    return(
        <Modal size={"l"} style={{height: 600}} aria-labelledby="contained-modal-title-vcenter"
               centered show={isOpen}>
            <Modal.Header>
                <HeadModal title={'Добавить раздел'}/>
            </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Group as={Col} controlId='validationCustom01'>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Наименование раздела"
                                className="mb-3"
                            >
                                <Form.Control required
                                              as="textarea"
                                              style={divStyle}
                                              placeholder="Введите наименование раздела"
                                              onChange={e => setNameChapter(e.target.value)}/>
                                <Form.Control.Feedback type="invalid">
                                    Введите наименование группы!
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" onClick={onSave}>
                        Сохранить
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}