import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {deleteGroup} from "../API/groupApi";

export default function AlertMessage ({isOpen, id, titleHead, titleBody, closeAlert, confirmAlert}){
    const closeMessage = () =>{
        closeAlert();
    }
    const confirmMessage = () => {
        confirmAlert(id);
    }

    return (
        <Alert show={isOpen} variant="danger">
            <Alert.Heading>{titleHead}</Alert.Heading>
            <p>
                {titleBody}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={confirmMessage} variant="outline-danger" className='m-sm-1'>Удалить</Button>
                <Button onClick={closeMessage} variant="outline-secondary" className='m-sm-1'>Отмена</Button>
            </div>
        </Alert>
    );
}