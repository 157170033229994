import React, {useEffect, useState} from "react";
import {loadAllFaculty} from "../../API/listUnitAPI";
import FacultySpisCheck from "./FacultySpisCheck";
import {loadActiveSurvey} from "../../API/surveyApi";

export default function FacultySpis({id, onSaveListUnit}) {
    const [faculty, setFaculty] = useState(null);
    const [listCheck,setListCheck] = useState([]);

    useEffect(() => {
        if (faculty) {
            return;
        }
        loadAllFaculty()
            .then(setFaculty);
    }, []);

    useEffect(() => {
        if(id !== undefined && faculty !== null) {
            loadActiveSurvey({idSurvey:id}).then((selectUnit) => {
                const result = selectUnit?.reduce((acc, survey)=>{
                   if(faculty.findIndex(listFaculty => listFaculty['@id'] === survey.unit) !== -1) {
                       const unit =  survey.unit.split('/');
                        return [
                            ...acc,
                           Number(unit[3])
                        ];
                    }
                       return acc;
                   }, [])
                setListCheck(result);
            });
        }
    },[faculty]);

   const changeListCheck = (value, id) => {
       if (value) {
           setListCheck([...listCheck, id]);

       } else {
           setListCheck(listCheck.filter(v => v !== id));
       }
   }

    useEffect(() => {
        onSaveListUnit(listCheck);
    }, [listCheck]);

    const facultyList = faculty?.map((facultys) => {
            return <FacultySpisCheck key={facultys.tnn}
                                     isChecked={listCheck.indexOf(facultys.tnn) !== -1}
                                     onCheckChange={value => changeListCheck(value, facultys.tnn)}
                                     faculty={facultys}/>
        }
    )

    return (
        <div className="mt-2"><i>Выберите факультеты, по которым необходимо провести опрос:</i>
        <table className='table table-bordered'>
            <thead>
            <tr>
                <th scope="col" style={{width: 10}}>
                </th>
                <th scope="col" style={{textAlign: "center"}}>Факультет</th>
            </tr>
            </thead>
            <tbody>
                {facultyList}
            </tbody>
        </table>
    </div>
    );


}