import React, {useEffect, useState} from "react";
import {loadUnitID, loadUserID} from "../../API/userDataAPI";
import DeleteIco from "../Button/DeleteIco";


export default function ListUserAccess({id, tn, unit, position, accesses, onDeleteAccessUser}) {
    const [unitName, setUnitName] = useState(null);
    const [fullName, setFullName] = useState(null);

    const handleDeleteAccess = (accessData) => {
        onDeleteAccessUser(accessData);
    }

    useEffect(() => {
        if (!fullName) {
            loadUserID({tn})
                .then(setFullName)
        }
    }, [fullName, tn])

    useEffect(() => {
        if (!unitName) {
            loadUnitID({unit})
                .then(setUnitName)
        }
    }, [unitName, unit])

    let accessName = '';
    if (position === "EXPERT") {
        accessName = 'Эксперт';
    } else if (position === "ADMIN") {
        accessName = 'Администратор';
    } else if (position === "COMMISSION") {
        accessName = 'Комиссия';
    } else if (position === "DEPARTMENT") {
        accessName = 'Заведующий кафедры';
    } else if (position === "DEAN") {
        accessName = 'Декан';
    }

    let access = '';
    if(accesses === 'read'){
        access = 'Чтение';
    }
    else if(accesses === 'write'){
        access = 'Заполнение';
    }

    return (
        <tr>
            <td>{fullName?.fullName.toLowerCase().replace(/(^|\s)\S/g, function (a) {
                return a.toUpperCase()
            }) ?? 'Loading...'}</td>
            <td>{unitName?.title ?? 'Loading...'}</td>
            <td>{accessName ?? 'Loading...'}</td>
            <td>{access ?? 'Loading...'}</td>
            <td>
                <button className="btn" onClick={() => handleDeleteAccess({id})} title={'Удалить права доступа'}>
                    <DeleteIco/></button>
            </td>
        </tr>
    )
}