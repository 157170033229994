import dateFormat from "dateformat";

export default function SurveyTitle({typeSurvey, datePeriod, department, ndate, kdate}) {

    let title = '';
    let period = '';
    let Month = [
        {id: 1, title: "Январь"},
        {id: 2, title: "Февраль"},
        {id: 3, title: "Март"},
        {id: 4, title: "Апрель"},
        {id: 5, title: "Май"},
        {id: 6, title: "Июнь"},
        {id: 7, title: "Июль"},
        {id: 8, title: "Август"},
        {id: 9, title: "Сентябрь"},
        {id: 10, title: "Октябрь"},
        {id: 11, title: "Ноябрь"},
        {id: 12, title: "Декабрь"}
    ];

    let year = (typeSurvey.replace(/[^0-9, ]/g,", ")).split(', ')[1];
    let type = typeSurvey.split('')[0];

    if (year === undefined){
        year = '';
    }
    else {year = year + ' года';}
   if (type === 'q') {
        period = datePeriod + " квартал";
    }
    else if(type === 'm')
         {
             let nameMonth = '';
               Month?.map(r =>{
                 if (datePeriod === r.id){
                     nameMonth = r.title;
                 }
             })
            period = nameMonth;
        }
    else if(type === 'a')
    {
        period = datePeriod + " полугодие";
    }
    else if (type === 'y'){
        period = '';
   }

    if(department.value === '2' || department.value === 2){
        return (
            title = "Критерии оценки и методика учета деятельности кафедр при проведении рейтинга по итогам " + year + ""
        )
    }
    else {
        return (
            title = "Верификация показателей деятельности за " + period + " " + year + "                                                                                                                                                                                           " +
                "для установления размера ежемесячного премирования на период c "+ dateFormat(ndate,"dd.mm.yyyy") + " по " + dateFormat(kdate,"dd.mm.yyyy")
        )
    }

}

