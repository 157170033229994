import {createSlice} from "@reduxjs/toolkit";

export const parametersSlice = createSlice({
	name: 'parameters',

	initialState: {
		isLoad: false,
		list: [],
	},

	reducers: {
		putParameters: (state, action) => {
			state.isLoad = true;
			state.list = action.payload;
		},

	}
})
export const {putParameters} = parametersSlice.actions
export default parametersSlice.reducer