import React, {disabled, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import DeleteIco from "../Button/DeleteIco";
import PlusIco from "../Button/PlusIco";
import Info from "../Button/Info";
import EditIco from "../Button/EditIco";
import {Button} from "react-bootstrap";
import {loadIDSurveyParameterValue, loadParametersBySurveyID} from "../../API/parameterApi";
import {useSelector} from "react-redux";

export default function ParamCommission({
											l,
											handlerChangerEdit,
											sum,
											sumCommis,
											chapterAll
										}) {

	const [elSum, elSumChanger] = useState(sum);
	const [idParam, idParamChanger] = useState(l.id)


	let unitsTitle = "";
	if (l.units === 'percent') {
		unitsTitle = '%'
	} else if (l.units === 'point') {
		unitsTitle = 'балл'
	} else if (l.units === 'units') {
		unitsTitle = 'ед'
	} else if (l.units === 'rub') {
		unitsTitle = 'руб'
	} else if (l.units === 'usd') {
		unitsTitle = 'долл.США'
	}


	return (<tr>
		<td>{l.chapterTitle}</td>
		<td scope="row"><b>{l.order}</b></td>
		<td>
			<button className='btn' title={l.metodVerif ? l.metodVerif : "Нет данных!"} value={l.id}>
				<Info/>
			</button>
			{l.title}
		</td>
		<td>{unitsTitle}</td>
		<td>
			<div className="d-inline-flex">
				<div className="p-2" disabled>{sum} </div>
				<button style={(chapterAll.includes(l.chapterID)) ?
					{display: 'block'}
					: {display: 'none'}} className='btn' title={'Изменить'}
						onClick={() => handlerChangerEdit(l,1)}>
					< EditIco/>
				</button>
			</div>
		</td>
		<td>
			<div className="d-inline-flex">
				{sumCommis >= 0 ?
					<div className="p-2" disabled>{sumCommis} </div>
					:
					<div className="p-2" disabled>{sum} </div>
				}
				<button style={(chapterAll.includes(l.chapterID)) ?
					{display: 'block'}
					: {display: 'none'}} className='btn' title={'Изменить'}
						onClick={() => handlerChangerEdit(l,2)}>
					< EditIco/>
				</button>
			</div>
		</td>
	</tr>)
}