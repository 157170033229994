import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import HeadModal from "./ModalHead";
import Button from "react-bootstrap/Button";
import {loadSurveyByID} from "../../API/surveyApi";
import PlusIco from "../Button/PlusIco";
import ModalAddAccess from "./ModalAddAccess";
import {deleteAccess, editAccess, saveAccess} from "../../API/accessAPI";
import EditIco from "../Button/EditIco";


export default function ModalViewAccessParameter({isOpen, id, title, onEditClose}) {

    const [surveyParameter, setSurveyParameter] = useState(null);
    const [survey, setSurvey] = useState(id);
    const [show, setShow] = useState(false);
    const [unitAccess, setUnitAccess] = useState([]);
    const [idParam, setIdParam] = useState(0);
    const [nameParam, setNameParam] = useState('');
    const [idOtdel, setIdOtdel] = useState(false);
    const accessInf = '';

    useEffect(() => {
        if (surveyParameter === null) {
            loadSurveyByID({survey})
                .then(setSurveyParameter)
        }
    }, [isOpen]);

    useEffect(() => {
        if (unitAccess.length > 0) {
            loadSurveyByID({survey})
                .then(setSurveyParameter)
        }
    }, [unitAccess]);

    const modalClose = () => {
        onEditClose();
    }

    const handleAddUnit = (id, title) => {
        setShow(true);
        setIdOtdel(false);
        setIdParam(id);
        setNameParam(title);
    }
    const handleEditUnit = (id, title) => {
        setShow(true);
        setIdOtdel(true);
        setIdParam(id);
        setNameParam(title);
    }
    const closeForm = () => {
        setShow(false);
        setIdOtdel(false);
    }
    let surveyType = surveyParameter?.subjectType;
    let arrParameter = surveyParameter?.surveyParameters;
    let accessInfo = null;
    const saveForm = (data, name, operation) => {
    console.log(data)
       // if (operation === 'add') {
            data?.map((p) => saveAccess({data: p})
                .then(r => {
                    setUnitAccess(name);
                    setShow(false);
                }))
       /* } else {
            if(data.length > 1){

                 data?.map((p) => saveAccess({data: p})
                        .then(r => {
                            setUnitAccess(name);
                            setShow(false);
                        }));
            }
            else{
                data?.map((p) =>
                editAccess({idAccess: p})
                    .then(r => {
                         setUnitAccess(name);
                        setShow(false);
                    })
                )
            }
        }*/
    }

    let otdel = null;
    let user = null;

    let arrAccess = arrParameter?.map((p, key) => {
        otdel = '';
        user = '';
        if (p.accesses.length === 0) {
            accessInfo = <button className="btn" onClick={() => {
                handleAddUnit(p.id, p.title)
            }}>
                <PlusIco/>
            </button>
            otdel = '';
            user = '';
        } else {
            if (p.accesses.length === 1) {
                if(p.accesses[0].fillingUnit){
                    otdel = p.accesses[0].fillingUnit.tnn;
                    accessInfo = p.accesses[0].fillingUnit.title;
                }
                if (p.accesses[0].responsibleUser){
                        user = p.accesses[0].responsibleUser.fullName.toLowerCase().replace(/(^|\s)\S/g, function (a) {
                        return a.toUpperCase()
                    });
                }
            }
            else {
                const accessA = [];
                const idOtd = [];
                const userTn = [];
                p.accesses?.map((access, key) => {
                    if (access?.responsibleUser) {
                        userTn.push(access.responsibleUser.fullName.toLowerCase().replace(/(^|\s)\S/g, function (a) {
                            return a.toUpperCase()
                        }));
                    }
                    if (access?.fillingUnit) {
                        idOtd.push(access.fillingUnit.tnn);
                        accessA.push(access.fillingUnit.title);
                    }
                });

                function onlyUnique(value, index, array) {
                    return array.indexOf(value) === index;
                }

                otdel = idOtd.filter(onlyUnique).join(', ');
                accessInfo = accessA.filter(onlyUnique).join(', ');
                user = userTn.join(', ');
            }
        }

        return <tr key={key} id={p.id}>
            <td>{p.order}</td>
            <td>{p.title}</td>
            <td>{accessInfo}
                {p.accesses.length > 0 ?
                    <button className="btn" onClick={() => {
                        handleEditUnit(p.id, p.title)
                    }}>
                        <EditIco/>
                    </button>
                    : null
                }
            </td>
            <td>{user}</td>
        </tr>
    })

    return (
        <Modal size={"xl"} aria-labelledby="contained-modal-title-vcenter"
               centered show={isOpen} onHide={modalClose}>
            <Modal.Header closeButton><HeadModal title={title}/></Modal.Header>
            <Modal.Body>
                <div>
                    <table className='table table-bordered'>
                        <thead>
                        <tr>
                            <th scope="col" style={{textAlign: "center"}}>№</th>
                            <th scope="col" style={{textAlign: "center"}}>Название показателя используемого для
                                верификации
                            </th>
                            <th scope="col" style={{textAlign: "center"}}>Наименование структурного подразделения</th>
                            <th scope="col" style={{textAlign: "center"}}>ФИО Эксперта</th>
                        </tr>
                        </thead>
                        <tbody>{arrAccess}
                        {
                            show === true ?
                                <ModalAddAccess
                                    isOpen={show}
                                    id={idParam}
                                    otdel={idOtdel}
                                    type={surveyType}
                                    titleParam={nameParam}
                                    onAccessClose={closeForm}
                                    onAccessSave={saveForm}
                                />
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={modalClose}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>

    );
}