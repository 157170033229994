import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadCafedry, loadFaculty} from "../../API/facultyAPI";
import {putFaculty} from "../../Store/FacultySlicer";
import SelectNew from "../Common/SelectNew";

import InterviewRaiting from "../InterviewRaiting/InterviewForRaiting";
import {
	loadParametersBySurveyID,
	loadParametersBySurveyIDForCommisssion,
	loadParametrsBySurveyID
} from "../../API/parameterApi";
import Loader from "../Common/Loader";
import {loadUnitsForSurvey} from "../../API/listUnitAPI";
import {checkListSurvey, loadSurveyByIDForFaculty} from "../../API/surveyApi";
import ModalDialog from "../Common/Modal";
import InterviewForRaiting from "../InterviewRaiting/InterviewForRaiting";
import {putParameters} from "../../Store/ParametrsSlicer";
import {putReloadParametr} from "../../Store/Reload";
import InterviewForCommission from "../InterviewCommission/InterviewForCommission";
import {putReloadParametrCommission} from "../../Store/ReloadCommission";


export default function InterviewADDForRaiting({idSurvey, unit}) {


	const [itemFaculty, itemFacultyChanger] = useState(0);
	const [itemCafedry, itemCafedryChanger] = useState(0);
	const [isFaculty, facultyChanger] = useState(true);
	const [selectInterview, selectInterviewChanger] = useState(true);
	const [listParamets, listParametsChanger] = useState([]);
	const [listCafedry, listCafedryChanger] = useState([])
	const [listFaculty, listFacultyChanger] = useState([])
	const [loader, loaderChanger] = useState(false)
	const [show, showChanger] = useState(false);

	const [page1, pageChanger] = useState(1);
	const [page, handlerPageChanger] = useState(1);
	const [total, totalChanger] = useState(0);
	const facultySlice = useSelector(state => state.faculty);

	const [listOfFullSurvey, listOfFullSurveyChanger] = useState([])
	const itemUnitID = useSelector(state => state.units);
	const {user} = useSelector(state => state.auth);
	const userTN = Number(user.tnn)
	const reloadDate = useSelector(state => state.reload);
	const reloadDateCommission = useSelector(state => state.reloadCommission);

	const currentUrl = window.location.href;
	const dispatch = useDispatch();


	const checkFullList = () => {

		const itemUnit = itemUnitID?.itemUnit
		checkListSurvey({parameter: userTN, idSurvey: idSurvey})
			.then(data => {

				const newArr = data.map(el => {
					return Number(el?.subjectUnit?.tnn)
				});
				const newArr2 = newArr.filter(l => l !== undefined)

				const arrListCafedry = listCafedry;
				const newARR = arrListCafedry?.map((el) => {
					const i = {...el};
					if (newArr2.includes(el.id) === true) {
						i.full = true
						i.id = el.id
						i.name = el.name
						return i
					} else
						i.full = false
					i.id = el.id
					i.name = el.name
					return i;
				})

				listCafedryChanger(newARR.sort(compareName))

			})
			.catch()
	}

	useEffect(() => {
		loadFaculty({page1, isFaculty, idSurvey})
			.then(data => {
				const arr = newList2(data);
				//	dispatch(putFaculty(arr));
				listFacultyChanger(arr)
			})
			.catch()

	}, [])

	useEffect(() => {
		itemFacultyChanger(0)
	}, [unit])

	// useEffect(() => {
	// 	checkFullList()
	// }, [reloadDate])

	const loadParameters = () => {
		listParametsChanger([])
		const parameter = itemCafedry;
		loadParametersBySurveyID({parameter, userTN, idSurvey})
			.then(data => {
				if (data?.length == 1) {
					totalChanger(1)
				} else {
					totalChanger(Math.round(data?.length / 4))
				}

				if (data !== 0) {
					//listParametsChanger(data.sort(compareTitle));
					listParametsChanger(data.sort(compareTitle));
				}

				loaderChanger(true)

			})
			.catch(listParametsChanger([]),
				loaderChanger(false))
	}
	const loadParametersForCommission = () => {
		listParametsChanger([])
		const parameter = itemCafedry;
		loadParametersBySurveyIDForCommisssion({parameter,  idSurvey})
			.then(data => {

				if (data !== 0) {
					//listParametsChanger(data.sort(compareTitle));
					listParametsChanger(data.sort(compareTitle));
				}

				loaderChanger(true)

			})
			.catch(listParametsChanger([]),
				loaderChanger(false))
	}

	useEffect(() => {
		if (currentUrl.includes("/commission")) {
			loadParametersForCommission()
		}else{
			loadParameters()
		}
		//checkFullList()
	}, [itemCafedry])

	useEffect(() => {
		if (currentUrl.includes("/commission")) {
			loadParametersForCommission()
			checkFullList()
			dispatch(putReloadParametrCommission(false))
		}else {
			loadParameters()
			checkFullList()
			dispatch(putReloadParametr(false))
		}
	}, [reloadDate])

	useEffect(() => {
		loadParameters()
		checkFullList()
		dispatch(putReloadParametr(false))
	}, [reloadDateCommission])

	const onLoadCafedry = (idFaculty) => {
		listCafedryChanger([])
		const page = 1;
		loadCafedry({page, idFaculty})
			.then(data => {
				const arrCafedry = newList(data).sort(compareTitle)
				if (data !== 0) {
					checkListSurvey({parameter: userTN, idSurvey: idSurvey})
						.then(data => {

							const newArr = data.map(el => {
								return Number(el?.subjectUnit?.tnn)
							});
							const newArr2 = newArr.filter(l => l !== undefined)

							const arrListCafedry = arrCafedry;
							const newARR = arrListCafedry?.map((el) => {
								const i = {...el};
								if (newArr2.includes(el.id) === true) {
									i.full = true
									i.id = el.id
									i.name = el.name
									return i
								} else return el;
							})

							listCafedryChanger(newARR.sort(compareName))

						})
						.catch()
				}
			})
			.catch(
				listCafedryChanger([])
			)
	}

	const onSelectChangerFaculty = (e) => {
		itemFacultyChanger(e);
		onLoadCafedry(e.id);
		listParametsChanger([])
		itemCafedryChanger(0)
		dispatch(putParameters([]));
	}

	const onSelectChangerCafedry = (e) => {
		//	checkFullList()
		itemCafedryChanger(e.id)
		dispatch(putParameters([]));
	}

	const compareName = (a, b) => a.name < b.name ? -1 : 1;
	const compareTitle = (a, b) => a.title < b.title ? -1 : 1;

	const newList = (list) => {
		const result = list.map((el) => ({id: Number(el.tnn), name: el.title, full: false}));
		const resutByName = result.sort(compareName);
		return resutByName;
	}
	const newList2 = (list) => {
		const result = list.map((el) => ({id: Number(el.tnn), name: el.title}));
		const resutByName = result.sort(compareName);
		return resutByName;
	}
	const onClose = () => {
		if (window.confirm("Вы хотите закрыть окно?") === true) {
			showChanger(false)
		}
	}

	const interview1 = (listParamets?.length !== 0) ?
		currentUrl.includes("/commission") ?
			<InterviewForCommission
				itemFaculty={itemCafedry.id}
				nameFaculty={itemCafedry.name}
				listParamets={listParamets}
				page={page}
				total={total}
				handler={handlerPageChanger}
				loadParameters={loadParametersForCommission}
			/>:
		<InterviewForRaiting
			itemFaculty={itemCafedry.id}
			nameFaculty={itemCafedry.name}
			listParamets={listParamets}
			page={page}
			total={total}
			handler={handlerPageChanger}
			loadParameters={loadParameters}
		/>

		:
		(itemCafedry !== 0) ?
			<div style={{color:"red"}}>Нет показателей для заполнения! Выберите другую кафедру!</div>
			: <></>
	const cafedry = (<div>
		<h4>Выберите кафедру по которой необходимо заполнить данные</h4>
		<div style={{padding: 10}}>
			<SelectNew list={listCafedry} selectChanger={onSelectChangerCafedry}/>
		</div>
	</div>)

	return (

		<div style={{padding: 10}}>
			<div>
				<h4>Выберите факультет</h4>
				<div style={{padding: 10}}>
					<SelectNew list={listFaculty} selectChanger={onSelectChangerFaculty}/>
				</div>
				{listCafedry.length > 0 ?
					cafedry :
					null
				}

				{(loader || listParamets.length !== 0) ?
					interview1
					:
					<Loader/>
				}

				{/*{(!loader || listParamets.length == 0)*/}
				{/*	?*/}
				{/*	<div>Нет данных</div>*/}
				{/*	: null*/}
				{/*}*/}

			</div>
		</div>
	)
}