import {get} from "./api";
import ApiSecurity from "./ApiSecurity";

async function loadUserID({tn}){

    const fullName = await (await fetch(`${tn}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return fullName;
}

async function loadUnitID({unit}){
    const unitName = await (await fetch(`${unit}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return unitName;
}
async function loadPositionID({position}){
    const unitName = await (await fetch(`${position}`, {headers: await ApiSecurity.getAuthHeaders()})).json();
    return unitName;
}

async function searchByFullName({claim, page = 1}){
   const ld_json = await (await fetch(`/api/users?page=${page}&fullName=${claim}&userGroups=%2Fapi%2Fuser_groups%2F1`, {headers: await ApiSecurity.getAuthHeaders()}))
            .json();

        return {
            members: ld_json['hydra:member'],
            total: ld_json['hydra:totalItems'],
            page
        };
}

async function loadUserPosition({userIrls}){
    const user = [];

    for (const irl of userIrls) {
        const userPosition = await get({irl});
        user.push(userPosition.position);
    }

    return user;
}

async function checkUserAccess({userTN}){
	const userAcs = await (await fetch(`/api/user_accesses?page=1&user=/api/users/${userTN}&role=EXPERT`, {headers: await ApiSecurity.getAuthHeaders()})).json();
	return userAcs["hydra:member"];
}

async function checkAuth() {
    const apiData = await fetch('/api/users/me', {headers: await ApiSecurity.getAuthHeaders()});
    const user = await (apiData).json();

    if (user) {
        const role = [];
      /*  const userPositions = [];
        for (const position of user.userPositions) {
            userPositions.push(position);
        }
        const userAccess = await loadUserPosition({userIrls: userPositions});
        const roles = [];
        for (const role of userAccess) {
            let access = 'ROLE_EXPERT';
            if (role === '/api/positions/5') {
                access = 'ROLE_ADMIN';
            }
            roles.push(access);
        }*/
        if(user.roles.findIndex(access => access === 'ROLE_ADMIN') !== -1){
            user.isAdmin = true;
        }
        else {
            user.isAdmin = false;
        }

    }

    return user;
}

export {
    loadUserID,
    loadUnitID,
    loadPositionID,
    searchByFullName,
    checkAuth,
    loadUserPosition,
	checkUserAccess
}