import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HeadModal from "./ModalHead";
import CheckedParameter from "../SurveyPage/CheckedParameter";
import {ListChapter, loadAllGroup, loadAllParameterGroup, loadSurveyType} from "../../API/parameterApi";
import Period from "../SurveyPage/Period";
import {loadSurveyByID} from "../../API/surveyApi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";


export default function ModalAddSurvey({
                                           isOpen,
                                           title = 'title',
                                           id,
                                           type,
                                           idPeriod,
                                           idDepartment,
                                           ndate,
                                           kdate,
                                           onSaveShow,
                                           onCloseShow
                                       }) {

    const [spisParam, setSpisParam] = useState(null);
    const [arrCheck, setArrCheck] = useState([]);

    let arrYear = (type.replace(/[^0-9, ]/g, ", ")).split(', ')[1];

    if (arrYear === undefined) {
        let today = new Date();
        arrYear = '' + today + '';
    }
    const [spisDepartment, setSpisDepartment] = useState([]); //список всех групп показателей
    const [typeSurvey, setTypeSurvey] = useState(type.split('')[0]);
    const [department, setDepartment] = useState(idDepartment);
    const [selectDepartment, setSelectDepartment] = useState(null);
    const [startDate, setStartDate] = useState(ndate);
    const [endDate, setEndDate] = useState(kdate);
    const [period, setPeriod] = useState(idPeriod);
    const [startDate1, setStartDate1] = useState(new Date(arrYear));
    const [urlChapter, setUrlChapter] = useState('');
    const [numDown, setNumDown] = useState('');
    const [numberParam, setNumberParam] = useState('');

    let dataVal = null;

    useEffect(() => {
        //загрузка доступных групп при открытии формы
        if (isOpen === false) {
            return;
        }
        else {
            if(department !== null) {
                setSpisDepartment(department);
                setSelectDepartment(department);
            }
            //получение перечня показателей для конкретной группы
            if(id === '0'){
                loadAllParameterGroup({group: 'api/survey_types/' + department.value})
                    .then(setSpisParam);
            }
            else{
                loadSurveyByID({survey: id})
                    .then((dataSurvey) => {
                        if(dataSurvey.surveyParameters.length > 0){
                            const result = dataSurvey.surveyParameters;
                            setSpisParam(result);
                        }
                        else {
                            loadAllParameterGroup({group: 'api/survey_types/' + department.value})
                                .then(setSpisParam);
                        }
                    });
            }
        }
    }, [isOpen]);

    const [isChapter, setIsChapter] = useState(false);
    const [spisParamTable, setSpisParamTable] = useState(null);

    useEffect(() => {
        if (department.value === 2) {
            setIsChapter(true);
            ListChapter().then((chapter)=>{
               const result = chapter?.reduce((arr, newList) => {
                   const param = spisParam?.filter((parameter)=>{
                       if(parameter.chapter === newList['@id']){
                           return true;
                       }
                   });
                      if(param !== undefined){
                        return [
                            ...arr,
                            {   id: newList.id,
                                title: newList.title,
                                url: newList['@id'],
                                arrParam: param
                            }
                        ]
                      }
                  return arr;
                },[])
                setSpisParamTable(result);
            })
        }
        else{
            setSpisParamTable(spisParam);
        }
    }, [spisParam]);

    const handleSave = () => {
        const arrCheckForSave = arrCheck?.map((item, key) => {
            if(selectDepartment.value === 2){
                return {
                    id:item.id,
                    order: item.order,
                    title: item.title,
                    description: item.description,
                    units: item.units,
                    resultCalculationType: item.resultCalculationType,
                    chapter: item.chapter
                }
            }
            else {
                return {
                    order: key + 1,
                    title: item.title,
                    description: item.description,
                    units: item.units,
                    minDataValue: item.minDataValue,
                    maxDataValue: item.maxDataValue,
                    resultCalculationType: item.resultCalculationType
                }
            }

        })
        if (id === '0') {
            dataVal = {
                subjectUnit: null,
                subjectType: '/api/survey_types/' + selectDepartment.value,
                isDividedIntoChapters: isChapter,
                periodSize: Number(period),
                period: periodFormat,
                surveyParameters: arrCheckForSave,
                status: 'hidden',
                bonusPeriodStartAt: startDate,
                bonusPeriodEndAt: endDate
            };
        } else {
            dataVal = {
                id: id,
                subjectUnit: null,
                type: typeSurvey,
                isDividedIntoChapters: isChapter,
                periodSize: Number(period),
                period: periodFormat,
                surveyParameters: arrCheckForSave,
                status: 'hidden',
                bonusPeriodStartAt: startDate,
                bonusPeriodEndAt: endDate,
                subjectType: '/api/survey_types/' + selectDepartment.value,
            };
        }
        onSaveShow(dataVal)
        if (id === "0") {
            setTypeSurvey('');
            setDepartment('');
        }
    }

    const handleClose = () => {
        if (id === "0") {
            setTypeSurvey('');
            setDepartment(null);
            setSelectDepartment(null);
            setSpisParam(null);
            setArrCheck([]);
            setStartDate('');
            setPeriod('');
            setEndDate('');
        }
        else{
            setArrCheck([]);
        }
        onCloseShow()
    };

    const numValue = (data) => {
        const infoParam = {
            id: data.id.id,
            order: data.value,
            title: data.id.title,
            description: data.id.description,
            units: data.id.units,
            resultCalculationType: data.id.resultCalculationType,
            chapter: data.id.chapter
        }

            if(arrCheck === []){
                setArrCheck([...arrCheck, infoParam]);
            }
            else {
                if(arrCheck !== []){
                    setArrCheck([...arrCheck].filter(r =>
                        r.id !== data.id.id));
                }
                //setArrCheck([...arrCheck, infoParam]);
            }

        }

console.log(arrCheck);

    const checkPeriod = (valPeriod) => {
        setPeriod(valPeriod);
    }
    let dis = "block";
    let textPeriod = 'какой период';
    if (typeSurvey === 'm') {
        textPeriod = 'какой месяц';
    } else if (typeSurvey === 'q') {
        textPeriod = 'какой квартал';
    } else if (typeSurvey === 'a') {
        textPeriod = 'какое полугодие';
    } else if (typeSurvey === 'y') {
        textPeriod = 'какой год';
        dis = "none";
    }
    let year = startDate1.getFullYear();
    let periodFormat = (typeSurvey + year).toString();

    const [checkType, setCheckType] = useState(false);
   // console.log(spisParamTable)
    return (
        <Modal size="xl" aria-labelledby="contained-modal-title-vcenter"
               centered show={isOpen} scrollable={true}>
            <Modal.Header><HeadModal title={title}/></Modal.Header>
            <Modal.Body>
                <div className='md-2'>
                    <div className="mt-2"><b><i>Тип опроса</i></b>
                        <span className="m-4">
                            <input style={{marginLeft: 10, marginRight: 5}} type="radio" name="typeSurvey"
                                   value={'m'} checked={typeSurvey == 'm' ? true : false}
                                   onChange={e => (setTypeSurvey(e.target.value), setCheckType(true))}/>Ежемесячный
                            <input style={{marginLeft: 10, marginRight: 5}} type="radio" name="typeSurvey"
                                   value={'q'} checked={typeSurvey == 'q' ? true : false}
                                   onChange={e => (setTypeSurvey(e.target.value), setCheckType(true))}/>Квартальный
                            <input style={{marginLeft: 10, marginRight: 5}} type="radio" name="typeSurvey"
                                   value={'a'} checked={typeSurvey == 'a' ? true : false}
                                   onChange={e => (setTypeSurvey(e.target.value), setCheckType(true))}/>Полугодовой
                            <input style={{marginLeft: 10, marginRight: 5}} type="radio" name="typeSurvey"
                                   value={'y'} checked={typeSurvey == 'y' ? true : false}
                                   onChange={e => (setTypeSurvey(e.target.value), setCheckType(true))}/>Годовой
                        </span>
                    </div>
                    <div className='mt-1'>
                        <div className="d-flex bd-highlight ">
                            <div className="p-1 bd-highlight"><b><i>Выберите за {textPeriod} опрос?</i></b></div>
                            <div className="p-1 bd-highlight" style={{display: dis}}>
                                <Period type={typeSurvey}
                                        period={period}
                                        onCheckPeriod={checkPeriod}/>
                            </div>
                            <div className="p-1 bd-highlight">
                                <DatePicker
                                    selected={startDate1}
                                    onChange={(date) => setStartDate1(date)}
                                    showYearPicker
                                    dateFormat="yyyy"
                                /></div>
                        </div>
                    </div>
                    {/*<div className="mt-2"><b><i>Верификация показателей деятельности для: </i></b><br/>*/}
                    {/*    <Select value={selectDepartment} options={spisDepartment} isDisabled={true}*/}
                    {/*            placeholder={'По какой группе проводить опрос'}*/}
                    {/*            onChange={setSelectDepartment}/>*/}
                    {/*</div>*/}
                    {department.value !== 2 ?
                        <div className="mt-2"><b><i>Выберите период для ежемесячного премирования:</i></b>
                            <span> с <input type="date" id="DateN" value={startDate}
                                            onChange={e => setStartDate(e.target.value)}/>
                                 по <input type="date" id="DateK" value={endDate}
                                           onChange={e => setEndDate(e.target.value)}/></span>
                        </div>
                        :null
                    }
                    <div className="mt-2"><i>Перечень показателей, которые автоматически включаются в данный опрос:</i>
                        <table className='table table-bordered'>
                            <thead>
                            <tr>
                                <th scope="col" style={{width: 10}}>№</th>
                                <th scope="col" style={{textAlign: "center"}}>Название показателя</th>
                                <th scope="col" style={{textAlign: "center"}}>Max % ставки</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                spisParamTable === null ?
                                    <tr>
                                        <td colSpan='3' align='center'><i>Нет данных для отображения</i></td>
                                    </tr>
                                    :
                                    spisParamTable.length === 0 ?
                                        <tr>
                                            <td colSpan='3' align='center'><i>Нет данных для отображения</i></td>
                                        </tr>
                                        : spisParamTable.length > 0 ?
                                            spisParamTable?.map((parameter, key) => (
                                                    department.value === 2 ?
                                                        <>
                                                            <tr key={parameter.id}>
                                                                <td colSpan='3'><b>{parameter.title}</b></td>
                                                            </tr>
                                                            {
                                                                parameter.arrParam?.map((p, key) => (
                                                                    <CheckedParameter num={key} parameter={p} key={p.id}
                                                                                      onNumber={numValue}/>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        <CheckedParameter num={key} parameter={parameter} key={parameter.id}
                                                                          onNumber={numValue}/>
                                                    : null
                                                )
                                            )
                                            : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>

    );
}
