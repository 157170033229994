import React, {useEffect, useState} from "react";
import {loadAccessSurvey, loadAccessSurveyAll, loadListSurvey, loadListSurveyForAccess} from "../../API/surveyApi";
import spinerLoading from "../spiner";
import Select from "react-select";
import {loadAllGroup} from "../../API/parameterApi";
import ListAccessUnit from "./ListAccessUnit";
import {useSelector} from "react-redux";
import {infoUserAccessTN} from "../../API/accessAPI";



export default function AccessUnit() {

    const [spisSurvey, setSpisSurvey] = useState(null);
    const [group, setGroup] = useState(null);
    const [selectGroup, setSelectGroup] = useState(null);
    const {user} = useSelector(state => state.auth);
    const [userAccess, setUserAccess] = useState([]);
    const [userGroup, setUserGroup] = useState(null);

    useEffect(() => {
        if(user) {
            infoUserAccessTN({user: '/api/users/'+user.tnn})
                .then(setUserAccess);
        }
        if (group === null) {
            loadAllGroup().then(setGroup);
        }
    }, []);

    useEffect(() => {
        if(group !== null){
            const groupInfo = group.filter((access)=>{
                for(const user of userAccess){
                    if(user.subject === access['@id']){
                        return true;
                    }
                }
            })
            setUserGroup(groupInfo);
        }
        else{
            return;
        }
    }, [group]);

    useEffect(() => {
        if (selectGroup === null) {
            return;
        } else {
            loadListSurveyForAccess({group: '/api/survey_types/' + selectGroup.value})
                .then(setSpisSurvey);
        }
    }, [selectGroup]);

    const listGroup = userGroup?.map((r) => {
        return {
            value: r.id,
            label: r.title
        }
    });

    return (
        <div>
            <div>
                <Select options={listGroup} value={selectGroup} placeholder="Выберите тип опроса"
                        onChange={setSelectGroup}/>
            </div>
            <div className="mt-3">
                {
                selectGroup !== null ?
                    <div>
                        <div className="table-responsive rounded-3">
                            <table className="table table-hover table-bordered border-light">
                                <thead style={{background: "#eaecf59e", textAlign: "center"}}>
                                <tr>
                                    <th scope="col">Название опроса</th>
                                    <th scope="col">Статус</th>
                                    <th scope="col" style={{width: 160}}>Просмотреть доступ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {spisSurvey === null ?
                                    <tr>
                                        <td colSpan='2' align='center'>{spinerLoading()}</td>
                                    </tr>
                                    :
                                    spisSurvey.length === 0 ?
                                        <tr>
                                            <td colSpan='2' align='center'>Нет данных для отображения</td>
                                        </tr>
                                        :
                                        spisSurvey.length > 0 ?
                                            spisSurvey?.map((survey) => (
                                                <ListAccessUnit survey={survey} group={selectGroup} status={survey.status} key={survey.id}/>
                                            ))
                                            : null
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    );
}

