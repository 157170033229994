import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HeadModal from "./ModalHead";
import FacultySpis from "../Unit/FacultySpis";
import DepartmentList from "../Unit/DepartmentList";
import UnitList from "../Unit/UnitList";
import {loadActiveSurvey, loadSurveyByID} from "../../API/surveyApi";


export default function ModalSendForUnit({isOpen, title, idSurvey, department, onCloseSend, onSaveSend}) {
    const sendClose = () => {
        onCloseSend()
    };

    const [spisUnit, setSpisUnit] = useState([]);
    const checkList = (value) => {
        if (value) {
            setSpisUnit(value);
        }
    }
    const [ownerSurvey, setOwnerSurvey] = useState(null);

    useEffect(() => {
        if (idSurvey && null === ownerSurvey) {
            loadSurveyByID({survey: idSurvey})
                .then(response => {
                    const sps = response
                        .surveyParameters
                        .map(sp => {
                            const spps = sp.accesses;
                            sp.accesses = spps.map(spps => {
                                spps.fillingUnit = spps?.fillingUnit['@id'] ?? null;
                                if (spps.fillingUnit === null){
                                    delete spps.fillingUnit;
                                }
                                delete spps.id;
                                delete spps['@id'];

                                return spps;
                            });

                            delete sp.id;
                            delete sp['@id'];

                            return sp;
                        });

                    response.surveyParameters = sps;

                    delete response.id;
                    delete response['@id'];

                    setOwnerSurvey(response);
                });

        }
    }, [idSurvey, ownerSurvey]);


    let dataVal = null;
    const saveSend = () => {
        const saveUnit = spisUnit.map((item) => {

            const newSurvey = Object.assign({}, ownerSurvey)

            newSurvey.subjectUnit = `/api/units/${item}`;
            newSurvey.parent = `/api/surveys/${idSurvey}`;
            newSurvey.status = "active";

            return newSurvey;
        })
       onSaveSend(saveUnit, idSurvey);
    };

    let listUnit;
    if (department === '1') {
        listUnit = <FacultySpis id={idSurvey} onSaveListUnit={value => checkList(value)}/>;
    } else if (department === '2') {
        listUnit = <DepartmentList id={idSurvey} onSaveListUnit={value => checkList(value)}/>;
    } else if (department === '3') {
        listUnit = <DepartmentList id={idSurvey} onSaveListUnit={value => checkList(value)}/>;
    }

    return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={isOpen}>
            <Modal.Header><HeadModal title={title}/></Modal.Header>
            <Modal.Body>
                <div className='md-2'>
                    {listUnit}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={sendClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={saveSend}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}