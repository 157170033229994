import {createSlice} from "@reduxjs/toolkit";

export const reloadSliceCommission = createSlice({
	name: 'reloadCommission',

	initialState: {
		reload:false

	},
	reducers: {
		putReloadParametrCommission:(state,action)=>{
			state.reloadCommission = action.payload
		}
	}
})

export const {putReloadParametrCommission} = reloadSliceCommission.actions
export default reloadSliceCommission.reducer