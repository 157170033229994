import React, {useEffect, useMemo, useState} from "react";
import ModalPage from "../Modal/ModalPage";
import PlusIco from '../Button/PlusIco';
import {
    deleteParameter, ListChapter,
    loadAllParameterGroup, loadParametersBySurveyID,
    saveChapters,
    saveParameter, selectChapters
} from "../../API/parameterApi";
import CatalogParameter from "./CatalogParameter";
import ModalAddChapter from "../Modal/ModalAddChapter";
import Select from "react-select";
import Pagination from "../Common/Pagination";


export default function ParameterTab({idGroup, chapter}) {
    const [show, setShow] = useState(false);
    const [spisParameter, setSpisParameter] = useState([]);
    const [selectChapter, setSelectChapter] = useState(null);
    const [listChapter,setListChapter] = useState(null);
    const [dividedChapter, setDividedChapter] = useState(false);
    const [shows,setShows] = useState(false);
    const [page, handlerPageChanger] = useState(1);
    const [total, totalChanger] = useState(0);
    const handleAddShow = () => {
        setShow(true);
    }
    const handleOkModal = (parameter) => {
        const saveParam = parameter?.map((data)=>{
            saveParameter({parameter: data})
            .then(r => {
                setSpisParameter(prevState => [...prevState, r])
                setShow(false)
            })
        })
    };
    const handleCancelModal = () => {
        setShow(false)
    }

    const handleDeleteParameter = (parameter) => {
        deleteParameter({parameter})
            .then(() => {
                setSpisParameter([...spisParameter].filter(r => r.id !== parameter.id))
            });
    }

    useEffect(() => {
        if(chapter){
            ListChapter().then(setListChapter);
            setDividedChapter(chapter.dividedIntoChapters)
        }
    }, [chapter]);

    useEffect(() => {
        if (idGroup !== null) {
                setSelectChapter(null);
                loadAllParameterGroup({group: '/api/survey_types/'+ idGroup.value})
                    .then(setSpisParameter);
        }
        else{
            return;
        }
    }, [idGroup]);
//пагинация
    // useEffect(() => {
    //     if (spisParameter?.length === 1){
    //         totalChanger(1)
    //     }else{
    //         totalChanger(Math.round(spisParameter?.length / 30))
    //     }
    // }, [spisParameter]);

    useEffect(() => {
        if(selectChapter !== null){
            selectChapters({chapter:selectChapter.value}).then(setSpisParameter)
        }
        else{
            return;
        }
    }, [selectChapter]);

    const spisChapter = listChapter?.map((ch)=>{
        return {
            value: ch.id,
            label: ch.title
        }
    })

    const openForm =() =>{
        setShows(true);
    }
    const onSaveChapter = (data) =>{
        saveChapters({title: data.title})
            .then(setShows(false));
    }
    const onCloseChapter = () => {
        setShows(false);
    }

    return (
        <div className="table-responsive rounded-4">
            {dividedChapter === true ?
                <div>
                    <div className={'m-1'}>
                        <Select options={spisChapter} style={{width:200}} onChange={setSelectChapter} value={selectChapter} placeholder={'Раздел'}></Select>
                    </div>
                    <div style={{margin: 15, textAlign: "right"}}>
                        <a href="#" onClick={openForm}>Добавить раздел</a>
                        <ModalAddChapter
                            isOpen={shows}
                            saveChapter={onSaveChapter}
                            closeChapter={onCloseChapter}
                        />
                    </div>
                </div>
                :null}
            <table className="table table-hover table-bordered border-light">
                <thead style={{background: "#eaecf59e", textAlign: "center"}}>
                <tr>
                    <th scope="col" width="15px">№</th>
                    <th scope="col">Показатель</th>
                    <th scope="col">Методика</th>
                    <th scope="col" width="110px">
                        <button className="btn" onClick={handleAddShow}>
                            <PlusIco/>
                        </button>
                        <ModalPage
                            isOpen={show}
                            title={'Добавить новый показатель'}
                            idParam={0}
                            nameText={''}
                            metodText={''}
                            maxVal={''}
                            group={'/api/survey_types/'+idGroup.value}
                            onOkHandler={handleOkModal}
                            onCancelHandler={handleCancelModal}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    spisParameter.length === 0 ?
                    <tr>
                        <td colSpan='4' align='center'> Нет данных для отображения </td>
                    </tr>
                    :  spisParameter.length > 0 ?
                            spisParameter?.map((parameter,key) => (
                                 <CatalogParameter parameter={parameter}
                                                   key={parameter.id}
                                                   number={key+1}
                                                   onDeleteHandler={handleDeleteParameter}/>
                            ))
                    :null


                }
                </tbody>
            </table>
            {/*<div className='d-flex justify-content-center'>*/}
            {/*    <Pagination current={page} pages={total} handler={handlerPageChanger}/>*/}
            {/*</div>*/}
        </div>

    )
}
